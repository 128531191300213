import "../scss/DeskopModal.scss";

import Button from "./Button";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export default function DesktopModal() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const handleClick = () => {
    setOpen(!open)
  }
  const onClickOutsideListener = () => {
    closeModal();
    document.removeEventListener("click", onClickOutsideListener);
  };
  return (
      <div
          className="deskopModal"
      >
        <div className={open ? "modal active" : "modal"} onMouseLeave={() => {
          if (open == true) {
            document.addEventListener("click", onClickOutsideListener);
          }
        }}>
          <div className="closeModal" onClick={closeModal}>
            <div
                onClick={handleClick}
                className={`plusButton ${open ? "open" : null}`}
            >
              <span>Anuluj</span>
              <div className="plusButton__icon"></div>
            </div>
          </div>
          <div className="modalButtons">
            <Button
                onClick={() => {
                  history.push("/shipment");
                  closeModal();
                }}
                type={"white"}
            >
              Dodaj ręcznie
            </Button>
            <Button
                onClick={() => {
                  history.push("/multi-parcel");
                  closeModal();
                }}
                type={"white"}
            >
              Importuj wiele
            </Button>
          </div>
        </div>
        <div
            onClick={handleClick}
            className={`plusButton ${open ? "open" : null}`}
        >
          <Button type={"primary"}>Dodaj paczkę</Button>
        </div>
      </div>
  );
}
