import APIConnector from "../apiConnector";
import Button from "./Button";
import Checkbox from "./Checkbox";
import FormItem from "./FormItem";
import Input from "./Input";
import TextArea from "./TextArea";
import policy from "../docs/policy.pdf";
import statute from "../docs/statute.pdf";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { validationRules } from "../helpers/validationRules";

const label1 = "Imię i Nazwisko";
const label2 = "E-mail";
const label3 = "Wiadomość";
const btnText = "Wyślij";

interface IContactForm {
  setRegards: (arg0: boolean) => void;
}

export default function ContactForm({ setRegards }: IContactForm) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "all" });
  const [error, setError] = useState("");

  const connector = new APIConnector();

  const sendMessage = (data: any) => {
    connector.sendContactMessage(data).then((res) => {
      if (res.status < 300) {
        setRegards(true);
      } else if (res.status > 400) {
        setError(
          "Nie udało się wysłać wiadomości. Spróbuj ponownie za kilka minut."
        );
      }
    });
  };

  const onSubmit = (data: any) => {
    sendMessage(data);
  };

  return (
    <div className="contact__form">
      <h1>Napisz do nas</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem>
          <Input
            placeholder={label1}
            register={register}
            name="sender_name"
            error={errors.sender_name}
            validationRules={validationRules.name}
          />
        </FormItem>
        <FormItem>
          <Input
            placeholder={label2}
            register={register}
            name="sender_email"
            error={errors.sender_email}
            validationRules={validationRules.mail}
          />
        </FormItem>
        <FormItem>
          <TextArea
            placeholder={label3}
            type="text"
            name="message"
            register={register}
            error={errors.message}
            validationRules={validationRules.message}
            maxLength={513}
          />
        </FormItem>
        <FormItem>
          <Checkbox
            register={register}
            name="acceptPolicy"
            error={errors.acceptPolicy}
          >
            Akceptuję
            <a href={statute} target="_blank" rel="noreferrer">
              regulamin
            </a>
            oraz
            <a href={policy} target="_blank" rel="noreferrer">
              politykę prywatności
            </a>
          </Checkbox>
        </FormItem>
        {error && (
          <div className="send-failure">
            <p>{error}</p>
          </div>
        )}
        <FormItem>
          <Button onClick={handleSubmit(onSubmit)} type={"primary"}>
            {btnText}
          </Button>
        </FormItem>
      </form>
    </div>
  );
}
