interface IInputGroup {
  separator: string;
  children: any;
}

export default function InputGroup({ separator, children }: IInputGroup) {
  const separatorElement = (
    <div className="separator">
      <span>{separator}</span>
    </div>
  );
  return (
    <fieldset className={"inputGroup"}>
      {children.map((inputElement: any, index: number) => {
        if (index === children.length - 1) {
          return inputElement;
        } else {
          return [inputElement, separatorElement];
        }
      })}
    </fieldset>
  );
}
