import "../scss/login-base.scss";
import "../scss/ParcelDetails.scss";

import { useHistory, useRouteMatch } from "react-router-dom";

import Button from "../components/Button";
import DesktopModal from "../components/DesktopModal";
import LoginFooter from "../components/LoginFooter";
import MobileModal from "../components/MobileModal";
import ParcelCarrier from "../components/ParcelCarrier";
import ParcelReceiver from "../components/ParcelReceiver";
import ParcelSender from "../components/ParcelSender";
import { useEffect } from "react";
import { useState } from "react";
import warning from "../img-svg/warning1.svg";
import { useSelector } from "react-redux";
import { getAllParcels, selectParcel } from "../reducers/parcels";
import { successMessage } from "../helpers/notifications";
import ScrollToTop from "../hook/ScrollToTop";
import { IOrder, IParcel } from "../interfaces/interfaces";
import { useAppDispatch } from "../hook/reduxHooks";

type ParcelDataType = IOrder & { parcel: IParcel };

type MatchTypes = {
  id: string;
};

export default function ParcelDetails() {
  const history = useHistory();
  const match = useRouteMatch<MatchTypes>();
  const [parcelData, setParcelData] = useState<ParcelDataType | null>(null);
  const { orders, parcels } = useSelector(getAllParcels);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let orderData = orders.filter(
      (order: IOrder) => order.id == match.params.id
    )[0];
    let parcel = parcels.filter(
      (parcel: IParcel) => parcel.id == orderData.parcels[0].toString()
    )[0];
    setParcelData({ ...orderData, parcel: parcel });
  }, [orders]);

  const copyToCliboard = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
        e.preventDefault();
        var parcelNumberSpan = document.getElementById("parcelNumber");
        parcelNumberSpan &&
          navigator.clipboard.writeText(parcelNumberSpan.innerHTML);
        successMessage("Pomyślnie skopiowano numer przesyłki do schowka");
  };

  ScrollToTop();

  return (
    <div>
      <div id={"parcel-details"}>
        <div className={"sub-nav__wrapper"}>
          <header className="sub-nav">
            <h1>Szczegóły zamówienia</h1>
            <div className="btn-container">
              <DesktopModal />
            </div>
            <MobileModal />
          </header>
        </div>
        <main>
          <div className="parcel__container">
            <div className="parcel-number__container">
              <span>Numer przesyłki (do śledzenia)</span>
              <div className="parcel-number">
                <span id="parcelNumber">
                  {(parcelData &&
                    parcelData.parcel &&
                    parcelData.parcel.parcel_number) ||
                    "Numer będzie widoczny po nadaniu przesyłki."}
                </span>
                {parcelData &&
                  parcelData.parcel &&
                  parcelData.parcel.parcel_number && (
                    <a onClick={(e) => copyToCliboard(e)} href="copy">
                      Kopiuj
                    </a>
                  )}
              </div>
            </div>
            <div className="btn-help">
              <Button onClick={() => history.push("/help")} type={"white"}>
                Pomoc
              </Button>
            </div>
          </div>
          <div className="content">
            <div className="content__section">
              <div className="data-company">
                <div className="sender">
                  {parcelData && <ParcelSender {...parcelData.shipping_from} />}
                </div>
                <div className="receiver">
                  {parcelData && <ParcelReceiver {...parcelData.shipping_to} />}
                </div>
              </div>
              <div className="data-shipment">
                <div>
                  {parcelData && <ParcelCarrier {...parcelData.parcel} />}
                </div>
                <div className="content__docs">
                  <h4>Dokumenty przewozowe</h4>
                  <div className="documents__options">
                    Kurier przywiezie potrzebne etykiety. <br />
                    Zaoszczędzisz na drukowaniu!
                  </div>
                </div>
              </div>
            </div>
            <div className="content__aside">
              {parcelData && !parcelData.is_paid && (
                <div className="summary warning">
                  <div className="hidden warning">
                    <img src={warning} alt="warning sign" />
                    <p>
                      Zamówienie nie zostało opłacone. Kliknij w przycisk
                      poniżej, by zostać przeniesionym do ekranu płatności.
                    </p>
                  </div>
                  <Button
                    type={"primary"}
                    onClick={() => {
                      dispatch(selectParcel(parcelData.id));
                      history.push(`/payments/${parcelData.id}`);
                    }}
                  >
                    Zapłać{" "}
                    {parcelData && parcelData.parcel && parcelData.parcel.price}{" "}
                    zł (w tym VAT)
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="btn-help mobile-hidden">
            <Button onClick={() => history.push("/help")} type={"white"}>
              Pomoc
            </Button>
          </div>
        </main>

        {/* <div className="backgroundGradient" /> */}
      </div>
      <LoginFooter />
    </div>
  );
}
