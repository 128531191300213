import { useEffect, useState } from "react";
import APIConnector from "../apiConnector";
import { IInvoice, IInvoices } from "../interfaces/interfaces";
import "../scss/InvoiceTable.scss";
import InvoiceRow from "./InvoiceRow";

export default function InvoiceTable() {
  const [invoicesData, setInvoicesData] = useState<IInvoices[] | null>(null);
  const connector = new APIConnector();

  useEffect(() => {
    connector
      .getAllInvoices()
      .then((res) => res.json())
      .then((data) => {
        const regex = /\d{4}\/\d{1,2}\/\d+\/\S+.pdf/;
        let invoices: IInvoice[] = data.results
          .filter((item: IInvoice) => item.path)
          .filter(
            (item: IInvoice) =>
              item.order_ids.length > 0 && item.path.match(regex)
          );
        invoices = invoices.sort(function (a, b) {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        });
        let mappedInvoices: IInvoices[] = invoices.map((invoice) => {
          return {
            invoicePath: invoice.path,
            ammount: invoice.total_price,
            date: new Date(invoice.created).toLocaleDateString(),
            orders: invoice.order_ids,
          };
        });
        setInvoicesData(mappedInvoices);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <table>
      <tr>
        <th>ZAMÓWIENIA</th>
        <th>WARTOŚĆ</th>
        <th>DATA</th>
        <th></th>
      </tr>
      {invoicesData &&
        invoicesData.map((invoice) => {
          return <InvoiceRow {...invoice} />;
        })}
    </table>
  );
}
