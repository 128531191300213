import "../scss/AccountModal.scss";

import Button from "./Button";
import FormItem from "./FormItem";
import { logout } from "../reducers/profile";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch } from "../hook/reduxHooks";

export default function AccountModal() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const handleClick = () => setOpen(!open);

  const dispatch = useAppDispatch();
  const onClickOutsideListener = () => {
    closeModal();
    document.removeEventListener("click", onClickOutsideListener);
  };
  const onClickInsideListener = () => {
    document.removeEventListener("click", onClickInsideListener);
    handleClick();
  };
  return (
    <div
      className="accountModal"
      onMouseLeave={() => {
        document.addEventListener("click", onClickOutsideListener);
      }}
    >
      <div className={open ? "modal active" : "modal"}>
        <div className="closeModal" onClick={closeModal}>
          <div className={`plusButton ${open ? "open" : null}`}>
            <span>Anuluj</span>
            <div className="plusButton__icon"></div>
          </div>
        </div>
        <div className="modalButtons">
          <FormItem>
            <Button
              onClick={() => {
                history.push("/user-data");
                closeModal();
              }}
              type={"white"}
            >
              Twoje dane
            </Button>
          </FormItem>
          <FormItem>
            <Button
              onClick={() => {
                history.push("/invoice");
                closeModal();
              }}
              type={"white"}
            >
              Faktury
            </Button>
          </FormItem>
          <FormItem>
            <Button
              onClick={() => {
                history.push("/valuation");
                closeModal();
              }}
              type={"white"}
            >
              Cennik
            </Button>
          </FormItem>
          <FormItem>
            <Button
              onClick={() => {
                dispatch(logout());
                closeModal();
                history.push("/landing");
              }}
              type={"logout"}
            >
              Wyloguj
            </Button>
          </FormItem>
        </div>
      </div>
      <div
        className={`plusButton ${open ? "open" : null}`}
        onClick={() => {
          document.addEventListener("click", onClickInsideListener);
        }}
      >
        <a>Konto</a>
      </div>
    </div>
  );
}
