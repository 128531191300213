interface IButton {
  type: string;
  active?: boolean;
  onClick?: (e: any) => void;
  children?: any;
  disabled?: boolean;
}

export default function Button({
  type,
  active = false,
  onClick,
  children,
  disabled,
}: IButton) {
  return (
    <button
      className={`btn ${type} ${active ? active : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
