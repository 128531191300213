import glsLogo from "../img-svg/glsLogo.svg";
import { saveForm } from "../reducers/parcels";
import { IParcel } from "../interfaces/interfaces";
import { useAppDispatch } from "../hook/reduxHooks";
const carrierName = "GLS Parcel International";

export default function ParcelCarrier(parcel: IParcel) {
  const dispatch = useAppDispatch();
  return (
    <div>
      <h4>Szczegóły</h4>
      <img src={glsLogo} alt="" />
      <p>{carrierName}</p>
      <p>{parcel.weight} kg</p>
      <p>
        {parcel.height && parcel.width && parcel.depth
          ? [parcel.height, parcel.width, parcel.depth].join(" x ")
          : "Uzupełnij wymiary paczki"}
      </p>
      {/* <p>{insurance}</p> */}
      <p>{parcel.price} zł</p>
      <a onClick={(e) => {
          e.preventDefault();
          dispatch(saveForm(parcel))
      }} href="shipment">
        Stwórz podobną
      </a>
    </div>
  );
}
