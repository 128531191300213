import "../scss/PasswordRemind.scss";

import React, { useState } from "react";

import Button from "../components/Button";
import FormItem from "../components/FormItem";
import Input from "../components/Input";
import PasswordReminderCorrect from "../components/PasswordReminderCorrect";

import { useForm } from "react-hook-form";
import {
  validationRules,
  validationBasicRules,
} from "../helpers/validationRules";

import greenBall from "../img-svg/shapes/greenBall.png";
import greenRect from "../img-svg/shapes/greenRect.png";
import pinkCircle from "../img-svg/shapes/pinkCircle.png";
import pinkShape2 from "../img-svg/shapes/pinkShape2.png";
import whiteCube from "../img-svg/shapes/whiteBox2.png";
import whiteFig from "../img-svg/shapes/whiteBox1.png";
import APIConnector from "../apiConnector";

export default function PasswordReminder() {
  const [remindPassword, setRemindPassword] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const apiConnector = new APIConnector();

  const remindHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    email: string
  ) => {
    e.preventDefault();
    if (Object.keys(errors).length === 0) {
      apiConnector.requestResetPasswordMail(email);
      setRemindPassword(true);
    }
  };

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "all", reValidateMode: "onBlur" });

  const remindMyPassword = () => {
    if (remindPassword === false) {
      return (
        <div id={"passwordReminder__wrapper"}>
          <section id={"passwordReminder"}>
            <h1>Przypomnij hasło</h1>
            <form id="passwordReminderForm">
              <FormItem>
                <Input
                  updateHandler={(e) => setUserEmail(e.target.value)}
                  register={register}
                  error={errors.email}
                  name="email"
                  validationRules={{
                    ...validationRules.mail,
                    ...validationBasicRules.required,
                  }}
                  placeholder={"Email"}
                />
              </FormItem>
              <FormItem>
                <Button
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    remindHandler(e, userEmail)
                  }
                  type={"primary"}
                >
                  Przypomnij
                </Button>
              </FormItem>
            </form>
          </section>

          <div id={"passwordReminderBackground"}>
            <div className={"rect"} />
            <img src={greenRect} className={"greenRect"} alt="" />
            <img src={whiteFig} className={"whiteFig"} alt="" />
            <img src={pinkCircle} className={"pinkCircle"} alt="" />
            <img src={greenBall} className={"greenBall"} alt="" />
            <img src={whiteCube} className={"whiteCube"} alt="" />
            <img src={pinkShape2} className={"pinkShape2"} alt="" />
          </div>
          <div className="backgroundGradient" />
        </div>
      );
    } else {
      return <PasswordReminderCorrect email={userEmail} />;
    }
  };
  return remindMyPassword();
}
