interface IChip {
  id?: string;
  type?: string;
  active?: boolean;
  children: any;
  onClick?: () => void;
}

export default function Chip({
  id = "chip",
  type,
  active,
  children,
  onClick,
}: IChip) {
  return (
    <span
      id={id}
      className={"chip " + (active ? " active " : "") + type}
      onClick={onClick}
    >
      {children}
    </span>
  );
}
