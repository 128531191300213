import "../scss/ShipmentHeader.scss";
import { getCountryFlagIcon } from "../helpers/countries";

interface IShippingAddress {
  name: string;
  company_name: string;
  address: string;
  postal_code: string;
  city: string;
  country_code: string;
  email: string;
  phone: string;
  onClick: () => void;
  selected: boolean;
}

export default function ShippingAddress({
  name,
  company_name,
  address,
  postal_code,
  city,
  country_code,
  email,
  phone,
  onClick,
  selected,
}: IShippingAddress) {
  return (
    <div
      className={`shippingAddress ${selected ? "active" : ""}`}
      onClick={onClick}
    >
      <p>{name}</p>
      <p>{company_name}</p>
      <br />
      <p>{address}</p>
      <p>
        {postal_code} {city}
      </p>
      <div className="countryCode">
        <img src={getCountryFlagIcon(country_code)} alt="country flag icon" />
        <span>{country_code}</span>
      </div>
      <br />
      <p>{email}</p>
      <p>{phone}</p>
    </div>
  );
}
