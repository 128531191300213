import "../scss/RegisterCorrect.scss";
import Button from "./Button";
import bigHand from "../img-svg/bigHand.png";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import APIConnector from "../apiConnector";
import { activateUser, logout } from "../reducers/profile";
import { useAppDispatch } from "../hook/reduxHooks";
const headerMsg = "Gotowe! Twoje konto zostało aktywowane!";
const textMsg = "Uzupełnij dane swojej firmy i zacznij nadawać wysyłki.";

export default function RegisterCorrectActivated() {
  const history = useHistory();
  const connector = new APIConnector();
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  useEffect(() => {
    let token = new URLSearchParams(search).get("token");

    if (token) {
      connector.activateUser(token).then((res) => {
        if (res.status) {
          dispatch(activateUser());
        }
      });
    } else {
      dispatch(logout());
      history.push("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id={"registerCorrect__wrapper"}>
      <section id={"registerCorrect"}>
        <div className="header-container">
          <h1>{headerMsg}</h1>
          <p>{textMsg}</p>
        </div>
        <div className="btn-container">
          <Button onClick={() => history.push("/user-data")} type={"primary"}>
            Uzupełnij dane
          </Button>
        </div>
      </section>
      <div className="greenBackground fade">
        <img src={bigHand} className="animation" alt="" />
      </div>
    </div>
  );
}
