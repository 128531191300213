import "../scss/PasswordRemindCorrect.scss";

import Button from "./Button";
import greenBall from "../img-svg/shapes/greenBall.png";
import greenRect from "../img-svg/shapes/greenRect.png";
import pinkCircle from "../img-svg/shapes/pinkCircle.png";
import pinkShape2 from "../img-svg/shapes/pinkShape2.png";
import { useHistory } from "react-router-dom";
import whiteCube from "../img-svg/shapes/whiteBox2.png";
import whiteFig from "../img-svg/shapes/whiteBox1.png";

const resetMsgStart = "Wiadomość została wysłana. Zaloguj się na e-mail: ";
const resetMsgEnd =
  " i zresetuj swoje stare hasło. Jeśli wiadomość do Ciebie nie dotarła w ciągu kilku minut, " +
  "spróbuj ponowić proces zmiany hasła.";

interface IPasswordReminderCorrect {
  email: string;
}

export default function PasswordReminderCorrect({
  email,
}: IPasswordReminderCorrect) {
  const history = useHistory();
  return (
    <div id={"passwordReminderCorrect__wrapper"}>
      <section id={"passwordReminderCorrect"}>
        <h1>Gotowe!</h1>
        <p>
          {resetMsgStart}
          <a href={`mailto:${email}`}>{email}</a>
          {resetMsgEnd}
        </p>
        <Button onClick={() => history.push("/login")} type={"primary"}>
          Dalej
        </Button>
      </section>

      <div id={"passwordReminderBackground"}>
        <div className={"rect"} />
        <img src={greenRect} className={"greenRect"} alt="" />
        <img src={whiteFig} className={"whiteFig"} alt="" />
        <img src={pinkCircle} className={"pinkCircle"} alt="" />
        <img src={greenBall} className={"greenBall"} alt="" />
        <img src={whiteCube} className={"whiteCube"} alt="" />
        <img src={pinkShape2} className={"pinkShape2"} alt="" />
      </div>
      <div className="backgroundGradient" />
    </div>
  );
}
