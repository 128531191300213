import Holidays from "date-holidays";
import moment from "moment";
import "moment/locale/pl";

var hd = new Holidays("PL");

export const getNextBusinessDates = (count: number) => {
  const today = moment().locale("pl");
  let dates = [];
  let i = 0;
  let weekendWeekdays = [6, 5];
  while (dates.length < count || i > 10) {
    let nextDay = today.add(1, "d");
    let formattedNextDay = nextDay.format("YYYY-MM-DD");
    if (
      !weekendWeekdays.includes(nextDay.weekday()) &&
      !hd.isHoliday(formattedNextDay)
    ) {
      dates.push({
        date: formattedNextDay,
        day: nextDay.locale("fr").format("dddd"),
      });
    }
  }
  return dates;
};
