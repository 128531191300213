import "../scss/DashboardTable.scss";
import DashboardRow from "./DashboardRow";

import { IParcel } from "../interfaces/interfaces";

interface ITable {
  parcels: IParcel[];
}

export default function DashboardTable({ parcels }: ITable) {
  const sortedParcels = parcels.map((x) => x)
  sortedParcels.reverse()
  return (  
    <table>
      <thead>
        <tr>
          <th className="col">
            <span>Numer przesyłki</span>
          </th>
          <th className="col">
            <span>NR REF</span>
          </th>
          <th className="col">
            <span>Status</span>
          </th>
          <th className="col">
            <span>Imię i nazwisko</span>
          </th>
          <th className="col">
            <span>Waga</span>
          </th>
          <th className="col">
            <span>Doręczenie</span>
          </th>
          <th className="col">
            <span>KRAJ</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {parcels &&
          sortedParcels.map((cell) => {
            return <DashboardRow data={cell} key={cell.id} {...cell} />;
          })}
      </tbody>
    </table>
  );
}
