import "../scss/CarouselSlide.scss";

interface ISlide {
  index: number;
  intro: string;
  header: string;
  text: string;
  img: string;
}

export default function Slide({ index, intro, header, text, img }: ISlide) {
  return (
    <div className="carousel__slide fade" style={{ display: "block " }}>
      <div className="information-container">
        <p className="intro">{intro}</p>
        <h1>{header}</h1>
        <p>{text}</p>
      </div>
      <div className="background-container">
        <div className={`carousel-${index}-color`}></div>
        <div className={`carousel-${index}-image`}>
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
}
