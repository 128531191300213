import Button from "./Button";
import img from "../img-svg/msgSend.png";
import { useHistory } from "react-router-dom";
const header = "Wiadomość wysłana !";
const text = "Dziękujemy za wiadomość. Odpowiedzi możliwie jak najszybciej.";
const btnText = "OK";

export default function ContactsendMsg() {
  const history = useHistory();
  return (
    <div className="contact__msgSend">
      <img src={img} alt="" />
      <h1>{header}</h1>
      <p>{text}</p>
      <Button onClick={() => history.push("/dashboard")} type={"primary"}>
        {btnText}
      </Button>
    </div>
  );
}
