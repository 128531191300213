import "../scss/RegisterCorrect.scss";
import bigHand from "../img-svg/bigHand.png";

const headerMsg = "Gotowe! Twoje konto zostało założone!";
const textMsg = "Wysłaliśmy do Ciebie mail - prosze potwierdź rejestrację";

export default function RegisterCorrect() {
  return (
    <div id={"registerCorrect__wrapper"}>
      <section id={"registerCorrect"}>
        <div className="header-container">
          <h1>{headerMsg}</h1>
          <p>{textMsg}</p>
        </div>
      </section>
      <div className="greenBackground fade">
        <img src={bigHand} className="animation" alt="" />
      </div>
    </div>
  );
}
