import "../../scss/KnowledgeMainArticle.scss";

import { Link } from "react-router-dom";
import { ISingleArticle } from "./Interfaces";

export default function KnowledgeMainArticle({
                                                 articleImage,
                                                 readingTime,
                                                 articleTitle,
                                                 articleContent,
                                                 articleAuthor,
                                                 path,
                                             }: ISingleArticle) {
    return (
        <article className="main-article">
            <Link to={`/baza-wiedzy/${path}`} className="article-picture">
                <img src={articleImage} alt=""></img>
            </Link>
            <div className="article-data">
                <p className="article-time">{readingTime}</p>
                <Link to={`/baza-wiedzy/${path}`}>
                    <h1 className="article-title">{articleTitle}</h1>
                </Link>
                <p className="article-content" dangerouslySetInnerHTML={{__html: articleContent}}></p>
                <p className="article-author">autor {articleAuthor}</p>
            </div>
        </article>
    );
}
