import "../scss/ShipmentDetails.scss";

import ShipmentForm from "./ShipmentForm";
import ShipmentHeader from "./ShipmentHeader";

export default function ShipmentDetails() {
  return (
    <div id="shipmentDetails">
      <div className="shipment__wrapper">
        <ShipmentHeader />
        <ShipmentForm />
        {/* <ShipmentCollectionDate /> */}
        <div className="shipmentBackground"></div>
      </div>
    </div>
  );
}
