import { IShippingDetails } from "../interfaces/interfaces";

export default function ParcelReceiver({
  address,
  city,
  company_name,
  email,
  phone,
  postal_code,
}: IShippingDetails) {
  let receiverAddress = `${address}, ${postal_code} ${city}`;
  return (
    <div>
      <h4>Odbiorca</h4>
      {/* <img src={receiverFlag} alt="" /> */}
      <p className="delivery">{receiverAddress}</p>
      <p>{company_name}</p>
      <p>{phone}</p>
      <p>{email}</p>
    </div>
  );
}
