import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FieldValues } from "react-hook-form";

import APIConnector from "../apiConnector";
import { RootState } from "../hook/reduxHooks";
import { IOrder, IParcel, IShippingDetails } from "../interfaces/interfaces";
import { logout } from "./profile";

type initialStateParcelsType = {
  parcels: IParcel[];
  parcelsToSend: IParcel[];
  orders: IOrder[];
  loading: boolean;
  selectedParcels: string[];
  selectedPaymentGate: number | null;
  filteredOrders: IOrder[];
  form: any;
  ordersLoading: boolean;
};

const connector = new APIConnector();
const initialState: initialStateParcelsType = {
  parcels: [],
  parcelsToSend: [],
  orders: [],
  loading: false,
  selectedParcels: [],
  selectedPaymentGate: null,
  filteredOrders: [],
  form: JSON.parse(window.localStorage.getItem("form")!) || undefined,
  ordersLoading: true,
};

export const fetchParcels = createAsyncThunk(
  "parcels/fetchParcels",
  async () => {
    const response: any = await connector.getParcels(false);
    return response.json();
  }
);

export const fetchParcelsToSend = createAsyncThunk(
  "parcels/fetchParcelsToSend",
  async () => {
    const response: any = await connector.getParcels(true);
    return response.json();
  }
);

export const sendParcels = createAsyncThunk(
  "parcels/send",
  async (parcelIds: string[]) => {
    const response = await connector.createOrder(parcelIds);
    return response.json();
  }
);

export const deleteParcel = createAsyncThunk(
  "parcels/delete",
  async (parcelId: string) => {
    const response = await connector.deleteParcel(parcelId);
    return response;
  }
);

export const fetchOrders = createAsyncThunk("orders/fetchOrders", async () => {
  const response: any = await connector.getOrders();
  return response.json();
});

export const deleteOrder = createAsyncThunk(
  "orders/delete",
  async (orderId: string) => {
    const response = await connector.deleteOrder(orderId);
    return response;
  }
);

export const addParcel = createAsyncThunk(
  "parcels/add",
  async (formData: any) => {
    const response: any = await connector.sendParcel(formData);
    return response.json();
  }
);

export const updateParcel = createAsyncThunk(
  "parcels/update",
  async ({ data, parcelId }: { data: any; parcelId: string }) => {
    const response = await connector.updateParcel(data, parcelId);
    return response.json();
  }
);

export const parcels = createSlice({
  name: "parcels",
  initialState: {
    ...initialState,
  },
  reducers: {
    selectParcel: (state, { payload }) => {
      if (state.selectedParcels?.includes(payload)) {
        state.selectedParcels = state.selectedParcels.filter(
          (el) => el !== payload
        );
      } else {
        state.selectedParcels?.push(payload);
      }
    },
    setFilteredOrders: (state, { payload }) => {
      state.filteredOrders = payload;
    },
    selectAllParcels: (state) => {
      if (state.selectedParcels?.length === state.parcelsToSend.length) {
        state.selectedParcels = [];
      } else {
        state.selectedParcels = state.parcelsToSend.map((el) => el.id);
      }
    },
    clearSelectedParcels: (state) => {
      state.selectedParcels = [];
    },
    selectAllOrders: (state, { payload }) => {
      if (
        state.selectedParcels &&
        state.selectedParcels.length >= 0 &&
        state.selectedParcels.length < payload.length
      ) {
        state.selectedParcels = payload;
      } else if (state.selectedParcels?.length === payload.length) {
        state.selectedParcels = [];
      }
    },
    selectPaymentGate: (state, { payload }) => {
      state.selectedPaymentGate = payload;
    },
    saveForm: (state, { payload }) => {
      localStorage.setItem("form", JSON.stringify(payload));
      state.form = payload;
    },
    clearForm: (state) => {
      localStorage.removeItem("form");
      state.form = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParcels.fulfilled, (state, action) => {
        state.loading = false;
        state.parcels = action.payload.results;
      })
      .addCase(fetchParcels.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchParcels.rejected, (state) => {
        state.loading = false;
        state.parcels = [];
      })
      .addCase(fetchParcelsToSend.fulfilled, (state, action) => {
        state.loading = false;
        state.parcelsToSend = action.payload.results;
      })
      .addCase(fetchParcelsToSend.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchParcelsToSend.rejected, (state, action) => {
        state.loading = false;
        state.parcelsToSend = [];
      })
      .addCase(sendParcels.fulfilled, (state) => {
        state.loading = false;

        state.parcelsToSend = state.parcelsToSend.filter(
          (el) => !state.selectedParcels?.includes(el.id)
        );
        state.selectedParcels = [];
      })
      .addCase(sendParcels.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendParcels.rejected, (state) => {
        state.loading = false;
        state.selectedParcels = [];
      })
      .addCase(deleteParcel.fulfilled, (state, action) => {
        state.loading = false;
        state.parcelsToSend = state.parcelsToSend.filter(
          (parcel) => parcel.id !== action.meta.arg
        );
        state.parcels = state.parcels.filter(
          (parcel) => parcel.id !== action.meta.arg
        );
        state.selectedParcels = [];
      })
      .addCase(deleteParcel.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteParcel.rejected, (state, action) => {
        state.loading = false;
        state.selectedParcels = [];
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        let tmpOrders: IOrder[] = [];
        action.payload.results.forEach((order: IOrder) => {
          let parcel = state.parcels.find(
            (parcel) => parcel.id == order.parcels[0].toString()
          );
          tmpOrders.push({
            ...order,
            shipping_to:
              (parcel && parcel.shipping_to) ||
              ([] as unknown as IShippingDetails),
            shipping_from:
              (parcel && parcel.shipping_from) ||
              ([] as unknown as IShippingDetails),
          });
        });
        state.orders = tmpOrders;
        state.loading = false;
        state.ordersLoading = false;
      })
      .addCase(fetchOrders.pending, (state) => {
        state.ordersLoading = true;
        state.loading = true;
      })
      .addCase(fetchOrders.rejected, (state) => {
        state.ordersLoading = false;
        state.loading = false;
        state.orders = [];
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.loading = false;
        let orderToDelete = state.orders.find(
          (order) => order.id === action.meta.arg
        );
        let parcel = state.parcels.find(
          (p) => p.id == orderToDelete?.parcels[0].toString()
        );
        state.parcelsToSend.push(parcel!);
        state.orders = state.orders.filter(
          (order) => order.id !== action.meta.arg
        );
        state.selectedParcels = [];
      })
      .addCase(deleteOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOrder.rejected, (state) => {
        state.loading = false;
        state.selectedParcels = [];
      })
      .addCase(addParcel.fulfilled, (state, action) => {
        state.parcels.push(action.payload);
        state.parcelsToSend.push(action.payload);
        state.loading = false;
      })
      .addCase(addParcel.pending, (state) => {
        state.loading = true;
      })
      .addCase(addParcel.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateParcel.fulfilled, (state, action) => {
        state.loading = false;
        state.parcels = state.parcels.map((el) =>
          el.id == action.meta.arg.parcelId ? { ...el, ...action.payload } : el
        );
        state.parcelsToSend = state.parcelsToSend.map((el) =>
          el.id == action.meta.arg.parcelId ? { ...el, ...action.payload } : el
        );
      })
      .addCase(updateParcel.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateParcel.rejected, (state) => {
        state.loading = false;
      })
      .addCase(logout, (state) => {
        state.parcels = initialState.parcels;
        state.orders = initialState.orders;
        state.parcelsToSend = initialState.parcelsToSend;
        state.selectedParcels = initialState.selectedParcels;
        state.selectedPaymentGate = initialState.selectedPaymentGate;
        state.filteredOrders = initialState.filteredOrders;
      });
  },
});
export const {
  selectAllParcels,
  selectParcel,
  clearSelectedParcels,
  selectAllOrders,
  selectPaymentGate,
  saveForm,
  setFilteredOrders,
  clearForm,
} = parcels.actions;

export const getAllParcels = (state: RootState) => state.parcels;
export const getParcelsToSend = (state: RootState) =>
  state.parcels.parcelsToSend;
export const ordersSelector = (state: RootState) => state.parcels.orders;
export default parcels.reducer;
