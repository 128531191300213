import "../scss/ParcelTable.scss";
import ParcelRow from "./ParcelRow";
import { getAllParcels, selectAllParcels } from "../reducers/parcels";
import { useSelector } from "react-redux";
import Checkbox from "./Checkbox";
import Skeleton from "react-loading-skeleton";
import { useAppDispatch } from "../hook/reduxHooks";
import { IParcel } from "../interfaces/interfaces";

interface IParcelTable {
  parcels: IParcel[];
  loading: boolean;
}

export default function ParcelTable(parcels: IParcelTable) {
  const dispatch = useAppDispatch();
  const { selectedParcels, parcelsToSend } = useSelector(getAllParcels);
  return (
    <div className="table-container flex column">
      <div className="header-container">
        <div className="col-1 mobile-label">
          <Checkbox
            name=""
            onClick={() => dispatch(selectAllParcels())}
            selected={
              selectedParcels.length === parcelsToSend.length &&
              parcelsToSend.length > 0
            }
          />
          <span>Wybierz wszystkie paczki</span>
        </div>
        <div className="col-2">
          <span>ID</span>
        </div>
        <div className="col-3">
          <span>Odbiór</span>
        </div>
        <div className="col-4">
          <span>Imię i nazwisko</span>
        </div>
        <div className="col-5">
          <span>Adres</span>
        </div>
        <div className="col-6">
          <span>Kraj</span>
        </div>
        <div className="col-7">
          <span>Opis/SKU</span>
        </div>
        <div className="col-8">
          <span>Cena</span>
        </div>
        <div className="col-9">
          <span>Waga</span>
        </div>
        <div className="col-10">
          <span></span>
        </div>
      </div>
      {parcels.parcels.length > 0 ? (
        parcels.parcels.map((parcel, id) => {
          return <ParcelRow {...parcel} key={id} />;
        })
      ) : parcels.loading === true ? (
        <Skeleton
          count={5}
          style={{ marginBottom: 10, marginTop: 10 }}
          height={"50px"}
        />
      ) : (
        ""
      )}
    </div>
  );
}
