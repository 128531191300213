import "../scss/OrderTable.scss";

import {
  clearSelectedParcels,
  getAllParcels,
  selectAllOrders,
} from "../reducers/parcels";
import { useSelector } from "react-redux";

import Checkbox from "./Checkbox";
import OrdersRow from "./OrdersRow";
import { useEffect } from "react";
import { useAppDispatch } from "../hook/reduxHooks";
import { IOrder } from "../interfaces/interfaces";

interface IOrdersTable {
  orders: IOrder[];
}

export default function OrdersTable({ orders }: IOrdersTable) {
  const dispatch = useAppDispatch();
  const { selectedParcels } = useSelector(getAllParcels);

  useEffect(() => {
    dispatch(clearSelectedParcels());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <table>
      <thead>
        <tr>
          <th>
            <Checkbox
              name=""
              onClick={() =>
                dispatch(selectAllOrders(orders.map((order) => order.id)))
              }
              selected={
                selectedParcels.length === orders.length && orders.length > 0
              }
            />
          </th>
          <th className="col">
            <span>ID</span>
          </th>
          <th className="col">
            <span>NR Przesyłki</span>
          </th>
          <th className="col">
            <span>status</span>
          </th>
          <th className="col">
            <span>Imię i nazwisko</span>
          </th>
          <th className="col">
            <span>Adres</span>
          </th>
          <th className="col">
            <span>Nr ref</span>
          </th>
          <th className="col">
            <span>Nr telefonu</span>
          </th>
          <th className="col">
            <span>Kraj</span>
          </th>
          <th className="col">
            <span>Opis/SKU</span>
          </th>
          <th className="col">
            <span>Cena</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {orders &&
          orders.map((order, id) => {
            return (
              <OrdersRow
                {...order}
                parcelNumber={order.parcels[0].toString()}
                key={id}
              />
            );
          })}
      </tbody>
    </table>
  );
}
