import parcels from "./reducers/parcels";
import profile from "./reducers/profile";
import errors from "./reducers/errors";
import shops from "./reducers/shops";

import { configureStore } from "@reduxjs/toolkit";

export default configureStore({
  reducer: {
    parcels: parcels,
    profile: profile,
    errors: errors,
    shops: shops,
  },
});
