import "../scss/ShipmentFinalization.scss";

import ShipmentCollectionDate from "./ShipmentCollectionDate";
import ShipmentHeader from "./ShipmentHeader";

export default function ShipmentFinalization() {
  return (
    <div id="shipmentFinalization">
      <div className="shipment__wrapper">
        <ShipmentHeader />
        <ShipmentCollectionDate />
        <div className="shipmentBackground"></div>
      </div>
    </div>
  );
}
