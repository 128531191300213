import "../../scss/SingleArticle.scss";

import { Link } from "react-router-dom";
import { ISingleArticle } from "./Interfaces";

export default function SingleArticle({
                                        articleImage,
                                        readingTime,
                                        articleTitle,
                                        articleContent,
                                        articleAuthor,
                                        path,
                                        articleExcerpt,
                                      }: ISingleArticle) {
  return (
      <article className="single-article">
        <Link to={path}>
          <div className="article-picture">
            <img src={articleImage} alt="article miniature" />
          </div>
        </Link>
        <div className="article-data">
          <p className="article-time">{readingTime}</p>
          <Link to={path}>
            <h1>{articleTitle}</h1>
            <p dangerouslySetInnerHTML={{ __html: articleExcerpt }}></p>
          </Link>
          <p className="article-author">autor {articleAuthor}</p>
        </div>
      </article>
  );
}