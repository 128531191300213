import { Link } from "react-router-dom";
import { scrollHandler } from "../hook/scrollHandler";
import smoothscroll from "smoothscroll-polyfill";
import { useLocation } from "react-router-dom";

interface IMenuItem {
  linkTo?: string;
  className: string;
  children: any;
  onClick: () => void;
  target?: React.HTMLAttributeAnchorTarget | undefined;
  routerLink?: boolean;
  href?: string;
}

export default function MenuItem({
  linkTo,
  className,
  children,
  onClick,
  target,
  routerLink = true,
}: IMenuItem) {
  smoothscroll.polyfill();
  const location = useLocation();
  const currentLocation = location.pathname.split("/");

  const smoothScrollHandler = () => {
    scrollHandler(currentLocation[1], linkTo);
  };

  return (
    <li
      className={
        currentLocation[1] === `${linkTo}` ? `menuItem active` : " menuItem"
      }
      onClick={smoothScrollHandler}
    >
      {routerLink ? (
        <Link
          to={`/${linkTo}`}
          className={className}
          onClick={onClick}
          target={target}
        >
          {children}
        </Link>
      ) : (
        <a
          href={`${linkTo}`}
          className={className}
          onClick={onClick}
          target={target}
        >
          {children}
        </a>
      )}
    </li>
  );
}
