import download from "downloadjs";
import APIConnector from "../apiConnector";

interface IParcelRow {
  invoicePath: string;
  ammount: string;
  date: string;
  orders: string[];
}

export default function ParcelRow({
  invoicePath,
  ammount,
  date,
  orders,
}: IParcelRow) {
  const connector = new APIConnector();

  const downloadFile = () => {
    connector
      .downloadInvoice(invoicePath)
      .then((data) => data.blob())
      .then((pdf) => {
        download(pdf, invoicePath);
      });
  };

  return (
    <tr>
      <td>{orders.join(",")}</td>
      <td>{ammount} PLN</td>
      <td className="invoiceData">{date}</td>
      <td>
        {invoicePath && (
          <a className="link" onClick={(e) => {
              e.preventDefault();
              downloadFile();
          }} href="download">
            Pobierz
          </a>
        )}
      </td>
    </tr>
  );
}
