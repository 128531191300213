import "../scss/RadioBox.scss";

import React, { useRef } from "react";

interface IRadioBox {
  index: string;
  name: string;
  onClick?: any;
  text?: string;
  checked?: boolean;
}

export default function RadioBox({
  index,
  name,
  text,
  onClick,
  checked,
}: IRadioBox) {
  const isChecked = useRef(null);
  return (
    <div className="radioBox">
      <input
        ref={isChecked}
        type="radio"
        id={index}
        name={name}
        onClick={onClick}
        checked={checked}
      />
      <label htmlFor={index}>{text}</label>
    </div>
  );
}
