import { IShop } from "../interfaces/interfaces";

export type ShopsActions = SetSelectedShop | ClearSelectedShop;

export enum ActionType {
  SetSelectedShop,
  ClearSelectedShop,
}

type SetSelectedShop = {
  type: ActionType.SetSelectedShop;
  payload: IShop;
};

type ClearSelectedShop = {
  type: ActionType.ClearSelectedShop;
};

type initialStateShopsType = {
  selectedShop: IShop | null;
};

const initialState: initialStateShopsType = { selectedShop: null };

const shops = (state = initialState, action: ShopsActions) => {
  switch (action.type) {
    case ActionType.SetSelectedShop:
      return { ...state, selectedShop: action.payload };
    case ActionType.ClearSelectedShop:
      return { ...state, selectedShop: null };
    default:
      return state;
  }
};

export default shops;
