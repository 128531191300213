import "../scss/FooterEmployee.scss";

interface IFooterEmployee {
  id: number;
  avatar: string;
  name: string;
  position: string;
  mobile: string;
  email: string;
}

export default function FooterEmployee({
  id,
  avatar,
  name,
  position,
  mobile,
  email,
}: IFooterEmployee) {
  return (
    <div className="employee-card">
      <div className="employee-avatar">
        <img src={avatar} alt="employee avatar" />
      </div>
      <div className="employee-data">
        <h4>{name}</h4>
        <p>{position}</p>
        {mobile === "" ? "" : <a href={`tel:${mobile}`}>{mobile}</a>}
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
  );
}
