import "../scss/ShipmentDetails.scss";
import "../scss/customSelect.scss";

import {
  addParcel,
  getAllParcels,
  updateParcel,
  saveForm,
  clearForm,
} from "../reducers/parcels";
import {
  geShippingToCustomSelectOptions,
  getCountryText,
  getSelectOptions,
  getShippingFromCustomSelectOptions,
  getShippingFromSelectOptions,
} from "../helpers/countries";
import {
  permimeterValidation,
  postalCodeValidation,
  validationBasicRules,
  validationRules,
} from "../helpers/validationRules";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import APIConnector from "../apiConnector";
import Button from "./Button";
import Checkbox from "./Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormItem from "./FormItem";
import Input from "./Input";
import InputGroup from "./InputGroup";
import InputLabel from "@material-ui/core/InputLabel";
import MobileModal from "./MobileModal";
import { ReactComponent as QIcon } from "../img-svg/qMark.svg";
import Select from "@material-ui/core/Select";
import TextArea from "./TextArea";
import policy from "../docs/policy.pdf";
import {
  addShippingAddress,
  profileSelector,
  addPickupAddress,
} from "../reducers/profile";
import statute from "../docs/statute.pdf";
import trimCalcPayload from "../helpers/trimPayload";
import { useForm } from "react-hook-form";
import { errorMessage, successMessage } from "../helpers/notifications";
import { apiSuccess } from "../helpers/errors";
import ShippingAddress from "./ShippingAddress";
import { useAppDispatch } from "../hook/reduxHooks";
import { IParcel, IShippingDetails } from "../interfaces/interfaces";

export default function ShipmentForm() {
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = useForm({ mode: "all" });

  const [price, setPrice] = useState<number>();
  const [information, setInformation] = useState(false);
  const { isAuthenticated, address, shipping_addresses, pickup_addresses } =
      useSelector(profileSelector);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [selectedPickupAddress, setSelectedPickupAddress] = useState<
      string | null
      >(null);
  const connector = new APIConnector();

  type MatchTypes = {
    id: string;
  };
  const match = useRouteMatch<MatchTypes>();
  const editParcel = match.params.id != null || false;
  const fieldsRequiredForPriceCalc = ["width", "height", "depth", "weight"];
  const ignoreFieldsForPriceCals = [
    "description",
    "shipping_to.name",
    "shipping_to.company_name",
    "shipping_to.city",
    "shipping_to.email",
    "shipping_to.phone",
    "shipping_to.address",
    "shipping_from.name",
    "shipping_from.company_name",
    "shipping_from.city",
    "shipping_from.email",
    "shipping_from.phone",
    "shipping_from.address",
  ];
  const [countryFrom, setCountryFrom] = useState<string | null>("PL");
  const [openCountryFrom, setOpenCountryFrom] = useState(false);
  const [countryTo, setCountryTo] = useState<string | null>("DE");
  const [openCountryTo, setOpenCountryTo] = useState(false);
  const [manualShippingAddress, setManualShippingAddress] = useState(false);
  const [saveAddress, setSaveAddress] = useState(false);
  const [manualPickupAddress, setManualPickupAddress] = useState(false);
  const [savePickupAddress, setSavePickupAddress] = useState(false);
  const [pickupAddressFromProfile, setPickupAddressFromProfile] =
      useState(false);
  const { parcels, form } = useSelector(getAllParcels);
  const toggleManualShippingAddressInsertion = () => {
    if (manualShippingAddress) {
      setSelectedAddress(null);
      setManualShippingAddress(false);
      setCountryTo(null);
    } else {
      setSelectedAddress(null);
      setManualShippingAddress(true);
    }
  };

  const toggleManualPickupAddressInsertion = () => {
    if (manualPickupAddress) {
      setSelectedPickupAddress(null);
      setManualPickupAddress(false);
      setCountryFrom(null);
    } else {
      setSelectedPickupAddress(null);
      setManualPickupAddress(true);
    }
  };

  const toggleSaveAddress = () => {
    setSaveAddress(!saveAddress);
  };

  const toggleSavePickupAddress = () => {
    setSavePickupAddress(!savePickupAddress);
  };

  const selectShippingAddress = (addressId: string | null) => {
    setSelectedAddress(addressId);
    const address = shipping_addresses.find(
        (el: IShippingDetails) => el.id === addressId
    );
    address &&
    Object.keys(address).forEach((key) => {
      setValue(`shipping_to.${key}`, address[key as keyof typeof address]);
    });
    setCountryTo(address!.country_code);
  };

  const selectPickupAddress = (addressId: string) => {
    setPickupAddressFromProfile(false);
    setManualPickupAddress(false);
    clearSenderForm();
    setSelectedPickupAddress(addressId);
    const address = pickup_addresses.find((el) => el.id === addressId);
    address &&
    Object.keys(address).forEach((key) => {
      setValue(`shipping_from.${key}`, address[key as keyof typeof address]);
    });
    setCountryFrom(address!.country_code);
  };

  const dispatch = useAppDispatch();
  const checkUpdatePrice = (e: any) => {
    if (e.target.name.includes("country_code")) {
      setValue(e.target.name, e.target.value);
    }

    if (ignoreFieldsForPriceCals.includes(e.target.name)) {
      return;
    }

    let formValues = getValues();

    let requiredFulfilled = true;

    fieldsRequiredForPriceCalc.forEach((field) => {
      if (!(formValues[field] && formValues[field].length > 0)) {
        requiredFulfilled = false;
      }
    });

    if (
        requiredFulfilled &&
        formValues.shipping_from.country_code &&
        formValues.shipping_from.country_code.length > 0 &&
        formValues.shipping_to.country_code &&
        formValues.shipping_to.country_code.length > 0
    ) {
      let calcValues = trimCalcPayload(formValues);
      calculatePrice(calcValues);
    }
  };

  const onSubmit = () => {
    if (isAuthenticated) {
      if (!manualPickupAddress && !getValues().shipping_from.address) {
        errorMessage("Sprawdź, czy uzupełniłeś adres odbioru.")
      } else if (!manualShippingAddress && !getValues().shipping_to.address) {
        errorMessage("Sprawdź, czy uzupełniłeś adres doręczenia.")
      } else {
        sendParcel(getValues());
      }
    } else {
      history.push("/login");
      dispatch(saveForm(getValues()));
    }
  };

  const clearSenderForm = () => {
    setValue(`shipping_from`, {
      city: "",
      name: "",
      address: "",
      email: "",
      phone: "",
      company_name: "",
    });
  };

  const getFormDataFromState = () => {
    if (form && form.shipping_to && form.shipping_from) {
      Object.keys(form.shipping_from).forEach((key) => {
        setValue(`shipping_from.${key}`, form.shipping_from[key]);
      });
      Object.keys(form.shipping_to).forEach((key) => {
        setValue(`shipping_to.${key}`, form.shipping_to[key]);
      });
      setValue("description", form.description);
      setValue("weight", form.weight);
      setValue("depth", form.depth);
      setValue("width", form.width);
      setValue("height", form.height);
      setCountryFrom(form.shipping_from.country_code);
      setCountryTo(form.shipping_to.country_code);
    }
  };

  useEffect(() => {
    history.listen((loc, action) => {
      if (action === "POP") {
        if (form) {
          dispatch(clearForm())
          history.push("dashboard")
        }
      }
    });
    if (editParcel) {
      let parcel = parcels.find(
          (el: IParcel) => el.id == match.params.id
      ) as IParcel;
      setManualShippingAddress(true);
      setManualPickupAddress(true);
      if (parcel && parcel.shipping_to) {
        Object.keys(parcel.shipping_to).forEach((key) => {
          setValue(
              `shipping_to.${key}`,
              parcel.shipping_to[key as keyof typeof parcel.shipping_from]
          );
        });
        Object.keys(parcel.shipping_from).forEach((key) => {
          setValue(
              `shipping_from.${key}`,
              parcel.shipping_from[key as keyof typeof parcel.shipping_from]
          );
        });
        let fields = ["weight", "height", "width", "depth", "description"];
        fields.forEach((key) => {
          setValue(key, parcel[key as keyof typeof parcel]!.toString() || 30);
        });
        setCountryFrom(parcel.shipping_from.country_code);
        setCountryTo(parcel.shipping_to.country_code);
        let calcValues = trimCalcPayload(getValues());
        calculatePrice(calcValues);
      }
    } else {
      getFormDataFromState();
      if (!form) {
        setCountryFrom("PL");
        setCountryTo("DE");
        setValue("shipping_from.country_code", "PL");
        setValue("shipping_to.country_code", "DE");
      } else {
        let calcValues = trimCalcPayload(getValues());
        calculatePrice(calcValues);
      }
    }
  }, [parcels]);

  const toggleUserData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (address && address.shipping_details) {
        Object.keys(address.shipping_details).forEach((key) => {
          setValue(`shipping_from.${key}`, address.shipping_details[key]);
        });
      }
      setPickupAddressFromProfile(true);
      setManualPickupAddress(true);
      setSelectedPickupAddress(null);
    } else {
      clearSenderForm();
      setPickupAddressFromProfile(false);
    }
  };

  const calculatePrice = (formData: any) => {
    connector.calculatePrice(formData).then((res) =>
        res.json().then((data) => {
          return setPrice(parseFloat(parseFloat(data.price || 0).toFixed(2)));
        })
    );
  };

  const sendParcel = async (data: any) => {
    if (editParcel) {
      let updateData = { data: data, parcelId: match.params.id };
      const result = await dispatch(updateParcel(updateData));
      if (updateParcel.fulfilled.match(result)) {
        successMessage(
            `Pomyślnie zaktualizowano przesyłkę. ID: ${match.params.id}`
        );
        if (saveAddress) {
          await connector.saveAddress(data.shipping_to).then((res: any) => {
            if (res.ok) {
              res.json().then((jsonData: any) => {
                dispatch(addShippingAddress({ data: jsonData }));
              });
            }
          });
        }
        if (savePickupAddress) {
          connector.savePickupAddress(data.shipping_from).then((res: any) => {
            if (res.ok) {
              res.json().then((jsonData: any) => {
                dispatch(addPickupAddress({ data: jsonData }));
              });
            }
          });
        }
        dispatch(clearForm());
        history.goBack();
      }
    } else {
      const result = await dispatch(addParcel(data));
      if (addParcel.fulfilled.match(result)) {
        successMessage(apiSuccess.AddParcelSuccess);
        if (saveAddress) {
          connector.saveAddress(data.shipping_to).then((res: any) => {
            res.json().then((jsonData: any) => {
              dispatch(addShippingAddress({ data: jsonData }));
            });
          });
        }
        if (savePickupAddress) {
          connector.savePickupAddress(data.shipping_from).then((res: any) => {
            if (res.ok) {
              res.json().then((jsonData: any) => {
                dispatch(addPickupAddress({ data: jsonData }));
              });
            }
          });
        }
        dispatch(clearForm());
        history.push("/to_send");
      } else {
        errorMessage("Wystąpił problem z dodaniem przesyłki. Sprawdź, czy wprowadziłeś wszystkie dane poprawnie.")
      }
    }
  };

  const closeInfo = () => setInformation(false);

  const onClickOutsideListener = () => {
    if (information) {
      closeInfo();
      document.removeEventListener("click", onClickOutsideListener);
    }
  };

  const handleChangeCountryFrom = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string
  ) => {
    setCountryFrom(event.target.value);
    setValue(name, event.target.value);
    checkUpdatePrice(event);
  };

  const handleCloseCountryFrom = () => {
    setOpenCountryFrom(false);
  };

  const handleOpenCountryFrom = () => {
    setOpenCountryFrom(true);
  };

  const handleChangeCountryTo = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string
  ) => {
    setCountryTo(event.target.value);
    setValue(name, event.target.value);
    checkUpdatePrice(event);
  };

  const handleCloseCountryTo = () => {
    setOpenCountryTo(false);
  };

  const handleOpenCountryTo = () => {
    setOpenCountryTo(true);
  };

  return (
      <section className="order__data" onClick={onClickOutsideListener}>
        <div className="section__header">
          <h1>{editParcel ? "Edytuj przesyłkę" : "Nadaj przesyłkę"}</h1>
          {!editParcel && (
              <div className="buttons">
                <Button
                    type={"white"}
                    onClick={() => {
                      history.push("/multi-parcel");
                    }}
                >
                  Importuj wiele
                </Button>
              </div>
          )}

          <MobileModal />
        </div>
        <div className="content">
          <main>
            <form
                id="sendParcelForm"
                onChange={checkUpdatePrice}
                onSubmit={handleSubmit(onSubmit)}
            >
              <div className="parcel-select__container">
                <FormItem label={"Nadanie"} helperTxt={"(skąd)"}>
                  <div className="select">
                    <FormControl className="customSelect">
                      <InputLabel htmlFor="open-select" />
                      <Select
                          open={openCountryFrom}
                          onClose={handleCloseCountryFrom}
                          onOpen={handleOpenCountryFrom}
                          name="country"
                          id="shipping_from.country_code"
                          value={"PL"}
                          onChange={(e: any) =>
                              handleChangeCountryFrom(e, "shipping_from.country_code")
                          }
                          inputProps={{
                            id: "open-select",
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                      >
                        {getShippingFromCustomSelectOptions()}
                      </Select>
                    </FormControl>
                    <select
                        {...register("shipping_from.country_code", {
                          required: { value: true, message: "" },
                        })}
                        name="shipping_from.country_code"
                        onChange={(e) => checkUpdatePrice(e)}
                    >
                      {getShippingFromSelectOptions()}
                    </select>
                    <Input
                        name="shipping_from.postal_code"
                        register={register}
                        error={
                            errors.shipping_from &&
                            errors.shipping_from[
                                "postal_code" as keyof typeof errors.shipping_from
                                ]
                        }
                        validationRules={{
                          ...validationBasicRules.required,
                          validate: (value: string) =>
                              postalCodeValidation(
                                  value,
                                  watch("shipping_from.country_code")
                              ),
                        }}
                        placeholder={"Kod pocztowy"}
                    />
                  </div>
                </FormItem>
                <FormItem label={"Doręczenie"} helperTxt={"(dokąd)"}>
                  <div className="select">
                    <FormControl className="customSelect">
                      <InputLabel htmlFor="open-select" />
                      <Select
                          open={openCountryTo}
                          onClose={handleCloseCountryTo}
                          onOpen={handleOpenCountryTo}
                          value={countryTo}
                          name="shipping_to.country_code"
                          id="shipping_to.country_code"
                          onChange={(e: any) =>
                              handleChangeCountryTo(e, "shipping_to.country_code")
                          }
                          inputProps={{
                            id: "open-select",
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                      >
                        {geShippingToCustomSelectOptions(
                            watch("shipping_from.country_code")
                        )}
                      </Select>
                    </FormControl>
                    <select
                        {...register("shipping_to.country_code", {
                          required: { value: true, message: "" },
                        })}
                        name="shipping_to.country_code"
                        onChange={(e) => {
                          checkUpdatePrice(e);
                        }}
                    >
                      {getSelectOptions(watch("shipping_from.country_code"))}
                    </select>
                    <Input
                        name="shipping_to.postal_code"
                        register={register}
                        error={
                            errors.shipping_to &&
                            errors.shipping_to[
                                "postal_code" as keyof typeof errors.shipping_to
                                ]
                        }
                        validationRules={{
                          ...validationBasicRules.required,
                          validate: (value: string) =>
                              postalCodeValidation(
                                  value,
                                  watch("shipping_to.country_code")
                              ),
                        }}
                        placeholder={"Kod pocztowy"}
                    />
                  </div>
                </FormItem>
              </div>
              <div className="parcel-properties__container">
                <div className="size">
                  <FormItem label={"Wymiary"} helperTxt={"(cm)"}>
                    <InputGroup separator={"x"}>
                      <Input
                          register={register}
                          error={errors.width}
                          validationRules={{
                            ...validationRules.width,
                            validate: (value: string) =>
                                permimeterValidation(
                                    value,
                                    watch("depth"),
                                    watch("height")
                                ),
                          }}
                          name="width"
                          placeholder={"Szerokość"}
                          updateHandler={(e) => checkUpdatePrice(e)}
                          maxLength={3}
                      />
                      <Input
                          register={register}
                          error={errors.depth}
                          validationRules={{
                            ...validationRules.length,
                            validate: (value: string) =>
                                permimeterValidation(
                                    value,
                                    watch("height"),
                                    watch("width")
                                ),
                          }}
                          name="depth"
                          className={"small"}
                          placeholder={"Długość"}
                          updateHandler={(e) => checkUpdatePrice(e)}
                          maxLength={3}
                      />
                      <Input
                          register={register}
                          error={errors.height}
                          validationRules={{
                            ...validationRules.height,
                            validate: (value: string) =>
                                permimeterValidation(
                                    value,
                                    watch("depth"),
                                    watch("width")
                                ),
                          }}
                          name="height"
                          className={"small"}
                          placeholder={"Wysokość"}
                          updateHandler={(e) => checkUpdatePrice(e)}
                          maxLength={3}
                      />
                    </InputGroup>
                  </FormItem>
                </div>

                <div className="weight">
                  <FormItem label={"Waga"} helperTxt={"(kg)"}>
                    <Input
                        name="weight"
                        className={"small"}
                        placeholder={"Waga"}
                        register={register}
                        error={errors.weight}
                        validationRules={validationRules.weight}
                        updateHandler={(e) => checkUpdatePrice(e)}
                        maxLength={5}
                    />
                  </FormItem>
                </div>
              </div>
              <div
                  className="questionMark"
                  onClick={(e) => setInformation(!information)}
              >
                <QIcon className="qestionIcon" />
                <p>Sprawdź wymagania dotyczące wymiarów</p>
              </div>
              <FormItem label={"Opis zawartości"} className="fullWidth">
                <TextArea
                    name="description"
                    type="text"
                    placeholder="Opis zawartości"
                    register={register}
                    error={errors.description}
                    validationRules={validationRules.description}
                    maxLength={513}
                />
              </FormItem>
              <aside className="order__summary hidden-deskop">
                <div>
                  <p>
                    Do zapłaty <span>(cena brutto zawiera VAT 23%)</span>
                  </p>
                  <h1>{price}</h1>
                </div>
              </aside>
              <h2>Adres odbioru</h2>
              {isAuthenticated && (
                  <FormItem>
                    <div className="check-container">
                      <Checkbox
                          name=""
                          onClick={(e) => {
                            toggleUserData(e);
                          }}
                          selected={pickupAddressFromProfile}
                      ></Checkbox>
                      <p>Dane nadawcy takie same jak w koncie</p>
                    </div>
                  </FormItem>
              )}
              <FormItem>
                <div
                    className={`shippingAddressPicker ${
                        selectedPickupAddress ? "selected" : ""
                    }`}
                >
                  {pickup_addresses.length > 0
                      ? pickup_addresses.map((pa) => (
                          <ShippingAddress
                              {...pa}
                              onClick={() => selectPickupAddress(pa.id)}
                              selected={
                                pa.id === selectedPickupAddress ? true : false
                              }
                          />
                      ))
                      : ""}
                </div>
              </FormItem>
              <FormItem>
                <Checkbox
                    name="toggleManualShippingInsertion"
                    error={errors.acceptGLSRules}
                    onClick={() => {
                      toggleManualPickupAddressInsertion();
                    }}
                    selected={
                      manualPickupAddress || pickup_addresses.length === 0
                          ? true
                          : false
                    }
                >
                  Dodaj adres nadawcy ręcznie
                </Checkbox>
              </FormItem>
              {(manualPickupAddress ||
                  pickup_addresses.length === 0 ||
                  (form && form.shipping_from && !selectedPickupAddress)) && [
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_from &&
                          errors.shipping_from[
                              "name" as keyof typeof errors.shipping_from
                              ]
                      }
                      validationRules={validationRules.name}
                      name="shipping_from.name"
                      className={"sender__name wide"}
                      placeholder={"Imię i nazwisko"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      className={"sender__company wide"}
                      placeholder={"Nazwa firmy (opcjonalnie)"}
                      register={register}
                      error={
                          errors.shipping_from &&
                          errors.shipping_from[
                              "company_name" as keyof typeof errors.shipping_from
                              ]
                      }
                      name="shipping_from.company_name"
                      validationRules={validationRules.companyName}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_from &&
                          errors.shipping_from[
                              "city" as keyof typeof errors.shipping_from
                              ]
                      }
                      validationRules={validationRules.city}
                      name="shipping_from.city"
                      className={"sender__city wide"}
                      placeholder={"Miasto"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_from &&
                          errors.shipping_from[
                              "address" as keyof typeof errors.shipping_from
                              ]
                      }
                      validationRules={validationRules.address}
                      name="shipping_from.address"
                      className={"sender__adress wide"}
                      placeholder={"Ulica i numer domu/budynku"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_from &&
                          errors.shipping_from[
                              "email" as keyof typeof errors.shipping_from
                              ]
                      }
                      validationRules={validationRules.mail}
                      name="shipping_from.email"
                      placeholder="E-mail"
                      className={"sender__email wide"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_from &&
                          errors.shipping_from[
                              "phone" as keyof typeof errors.shipping_from
                              ]
                      }
                      validationRules={validationBasicRules.required}
                      name="shipping_from.phone"
                      className={"sender__phone wide"}
                      placeholder={"Telefon"}
                  />
                </FormItem>,
                <FormItem>
                  <Checkbox
                      name="toggleSavePickupAddress"
                      onClick={toggleSavePickupAddress}
                  >
                    Zapamiętaj dane nadawcy
                  </Checkbox>
                </FormItem>,
              ]}
              <h2>Adres Doręczenia</h2>
              <FormItem>
                <div
                    className={`shippingAddressPicker ${
                        selectedAddress ? "selected" : ""
                    }`}
                >
                  {shipping_addresses.length > 0
                      ? shipping_addresses.map((sa) => (
                          <ShippingAddress
                              {...sa}
                              onClick={() => selectShippingAddress(sa.id)}
                              selected={sa.id === selectedAddress ? true : false}
                          />
                      ))
                      : ""}
                </div>
              </FormItem>

              <FormItem>
                <Checkbox
                    name="toggleManualShippingInsertion"
                    error={errors.acceptGLSRules}
                    onClick={() => {
                      toggleManualShippingAddressInsertion();
                    }}
                    selected={
                      manualShippingAddress || shipping_addresses.length === 0
                          ? true
                          : false
                    }
                >
                  Dodaj adres odbiorcy ręcznie
                </Checkbox>
              </FormItem>
              {(manualShippingAddress ||
                  shipping_addresses.length === 0 ||
                  (form && form.shipping_to && !selectedAddress)) && [
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_to &&
                          errors.shipping_to[
                              "name" as keyof typeof errors.shipping_to
                              ]
                      }
                      validationRules={validationRules.name}
                      name="shipping_to.name"
                      className={"customer__name wide"}
                      placeholder={"Imię i nazwisko"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_to &&
                          errors.shipping_to[
                              "company_name" as keyof typeof errors.shipping_to
                              ]
                      }
                      name="shipping_to.company_name"
                      validationRules={validationRules.companyName}
                      className={"customer__company wide"}
                      placeholder={"Nazwa firmy (opcjonalnie)"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_to &&
                          errors.shipping_to[
                              "city" as keyof typeof errors.shipping_to
                              ]
                      }
                      validationRules={validationRules.city}
                      name="shipping_to.city"
                      className={"customer__city wide"}
                      placeholder={"Miasto"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_to &&
                          errors.shipping_to[
                              "address" as keyof typeof errors.shipping_to
                              ]
                      }
                      validationRules={validationRules.address}
                      name="shipping_to.address"
                      className={"customer__adress wide"}
                      placeholder={"Ulica i numer domu/budynku"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_to &&
                          errors.shipping_to[
                              "email" as keyof typeof errors.shipping_to
                              ]
                      }
                      validationRules={validationRules.mail}
                      name="shipping_to.email"
                      className={"customer__email wide"}
                      placeholder={"E-mail"}
                  />
                </FormItem>,
                <FormItem>
                  <Input
                      register={register}
                      error={
                          errors.shipping_to &&
                          errors.shipping_to[
                              "phone" as keyof typeof errors.shipping_to
                              ]
                      }
                      validationRules={validationBasicRules.required}
                      name="shipping_to.phone"
                      className={"customer__phone wide"}
                      placeholder={"Telefon"}
                  />
                </FormItem>,
                <FormItem>
                  <Checkbox name="toggleSaveAddress" onClick={toggleSaveAddress}>
                    Zapamiętaj dane odbiorcy
                  </Checkbox>
                </FormItem>,
              ]}

              <FormItem>
                <Checkbox
                    register={register}
                    name="acceptPolicy"
                    error={errors.acceptPolicy}
                >
                  Akceptuję{" "}
                  <a href={statute} target="_blank" rel="noreferrer">
                    regulamin{" "}
                  </a>
                  i
                  <a href={policy} target="_blank" rel="noreferrer">
                    politykę prywatności
                  </a>
                </Checkbox>
              </FormItem>
              <FormItem>
                <Checkbox
                    register={register}
                    name="acceptGLSRules"
                    error={errors.acceptGLSRules}
                >
                  Akceptuję{" "}
                  <a
                      href="https://gls-group.eu/PL/pl/regulamin"
                      target="_blank"
                      rel="noreferrer"
                  >
                    regulamin przewoźnika GLS
                  </a>
                </Checkbox>
              </FormItem>
              <FormItem>
                <div className="btn-container">
                  <Button
                      type={"btn"}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(clearForm());
                        history.goBack();
                      }}
                  >
                    Wróć
                  </Button>
                  <Button
                      type={"primary"}
                      onClick={(e) => {
                        handleSubmit(onSubmit);
                      }}
                  >
                    {editParcel ? "Zapisz" : "Dalej"}
                  </Button>
                </div>
              </FormItem>
            </form>
          </main>
          <aside className="order__summary hidden-mobile">
            <div
                className={
                  information
                      ? "informationActive informationHidden"
                      : "informationHidden"
                }
            >
              <div className="plusButton__icon" onClick={closeInfo}></div>
              <div className="parcelInfo">
                <p>Twoja paczka musi spełniać następujące wymagania:</p>
                <span>waga do 30 kg</span>
                <span>{"obwód + najdłuższy bok < 3m"}</span>
                <span>{"najdłuższy bok < 2m"}</span>
                <span>{"szerokość < 80cm"}</span>
                <span>{"wysokość < 60cm"}</span>
              </div>
            </div>

            <div>
              <p className="upper">
                Do zapłaty <span>(cena brutto zawiera VAT 23%)</span>
              </p>
              <h1>{price}</h1>
              <p>
                <span>GLS Parcel International </span>
              </p>
              <p>
              <span>
                z {getCountryText(watch("shipping_from.country_code"))} do{" "}
                {getCountryText(watch("shipping_to.country_code"))}
              </span>
              </p>
              <p>
              <span>
                przesyłka standardowa
                <br />
                {watch("width") ? watch("width") + "cm" : ""}
                {watch("depth") ? " x " + watch("depth") + "cm" : ""}
                {watch("height") ? " x " + watch("height") + "cm" : ""}
              </span>
              </p>
              <p>
                <span>waga {watch("weight") ? watch("weight") + " kg" : ""}</span>
              </p>
            </div>
          </aside>
        </div>
      </section>
  );
}
