const MAX_DIMENSIONS_SUM = 298;
export const validationBasicRules = {
  required: {
    required: {
      value: true,
      message: "To pole jest wymagane",
    },
    minLength: {
      value: "1",
      message: "To pole nie moze być puste",
    },
  },
  numberInput: {
    pattern: {
      value: /^[0-9]*$/,
      message: "Wprowadź wartość liczbową",
    },
  },
  floatInput: {
    pattern: {
      value: /(\d+(?:\.\d+)?)/,
      message: "Wprowadź wartość liczbową",
    },
  },
  longestSideValiation: {
    max: {
      value: 200,
      message: "Maksymalna wielkość boku to 200 cm",
    },
  },
};

const excludedPostalCodes = {
  FR: [
    ...Array(10000)
      .fill(20000)
      .map((x, y) => String(x + y)),
    ...Array(3000)
      .fill(97000)
      .map((x, y) => String(x + y)),
  ],
  ES: [
    ...Array(872)
      .fill(7000)
      .map((x, y) => String(x + y).padStart(5, "0")),
    ...Array(662)
      .fill(35000)
      .map((x, y) => String(x + y)),
    ...Array(972)
      .fill(38001)
      .map((x, y) => String(x + y)),
  ],
};

export const postalCodeValidation = (
  postalCode: string,
  countryCode: string
) => {
  if (excludedPostalCodes[countryCode as keyof typeof excludedPostalCodes]) {
    return excludedPostalCodes[
      countryCode as keyof typeof excludedPostalCodes
    ].includes(postalCode.replace(/\s+/g, ""))
      ? "Wysyłka na ten adres jest niemożliwa."
      : true;
  } else {
    return true;
  }
};

export const permimeterValidation = (
  sideA: string,
  sideB: string,
  sideC: string
) => {
  let sortedValues = [
    parseInt(sideA) || 0,
    parseInt(sideB) || 0,
    parseInt(sideC) || 0,
  ].sort((a, b) => a - b);
  let longestSide = sortedValues[2];
  let perimeter = 2 * sortedValues[0] + 2 * sortedValues[1];
  return perimeter + longestSide <= MAX_DIMENSIONS_SUM
    ? true
    : `Suma obwodu i najdłuższego boku nie może przekroczyć ${MAX_DIMENSIONS_SUM}cm`;
};

export const passwordsShouldBeExact = (
  confirmPassword: string,
  password: string
) => {
  return confirmPassword === password ? true : "Hasła nie pasują do siebie";
};

export const validationRules = {
  weight: {
    ...validationBasicRules.required,
    ...validationBasicRules.floatInput,
    max: {
      value: "30",
      message: "Maksymalna waga paczki to 30kg",
    },
    min: {
      value: "1",
      message: "Waga paczki musi być większa niz 0kg",
    },
  },
  width: {
    ...validationBasicRules.longestSideValiation,
    ...validationBasicRules.required,
    ...validationBasicRules.numberInput,
    min: {
      value: "1",
      message: "Szerokość paczki musi być większa niz 0cm",
    },
  },
  height: {
    ...validationBasicRules.longestSideValiation,
    ...validationBasicRules.required,
    ...validationBasicRules.numberInput,
    min: {
      value: "1",
      message: "Wysokość paczki musi być większa niz 0cm",
    },
  },
  depth: {
    ...validationBasicRules.required,
    ...validationBasicRules.numberInput,
    min: {
      value: "1",
      message: "Głębokość paczki musi być większa niz 0cm",
    },
  },
  length: {
    ...validationBasicRules.longestSideValiation,
    ...validationBasicRules.required,
    ...validationBasicRules.numberInput,
    min: {
      value: "1",
      message: "Długość paczki musi być większa niz 0cm",
    },
  },
  description: {
    ...validationBasicRules.required,
    maxLength: {
      value: "512",
      message: "Opis nie moze byc dluszszy niz 512 znaków",
    },
  },
  message: {
    ...validationBasicRules.required,
    maxLength: {
      value: "512",
      message: "Wiadomość nie może byc dluższa niz 512 znaków",
    },
  },
  name: {
    ...validationBasicRules.required,
    maxLength: {
      value: "32",
      message: "Imię i nazwisko nie moze byc dluzsze niz 32 znaki",
    },
  },
  city: {
    ...validationBasicRules.required,
  },
  address: {
    ...validationBasicRules.required,
  },
  mail: {
    ...validationBasicRules.required,
    minLength: {
      value: "6",
      message: "Wpisz przynajmniej 6 znaków",
    },
    maxLength: {
      value: "64",
      message: "Wpisz maksymalnie 64 znaki",
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Niepoprawny adres email",
    },
  },
  phone: {
    ...validationBasicRules.required,
    minLength: {
      value: "7",
      message: "Wpisz przynajmniej 7 znaków",
    },
    maxLength: {
      value: "16",
      message: "Wpisz maksymalnie 16 znaków",
    },
    pattern: {
      value: /^[0-9 ()+]+$/,
      message: "Niepoprawny format telefonu",
    },
  },
  password: {
    ...validationBasicRules.required,
    minLength: {
      value: "7",
      message: "Hasło powinno zawierać minimum 7 znaków",
    },
  },
  nip: {
    pattern: {
      value: /^[0-9]+$/,
      message: "Numer płatnika powinien składać się tylko z cyfr",
    },
  },
  companyName: {
    maxLength: {
      value: "64",
      message: "Nazwa firmy nie może być dłuższa niż 64 znaki",
    },
  },
};
