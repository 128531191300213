export const checkIfStringInItem = (item, value, excludeKeys = []) => {
  return Object.keys(item).some((key) => {
    if (excludeKeys.includes(key)) {
      return false;
    } else {
      if (item[key]) {
        if (typeof item[key] === typeof {}) {
          return checkIfStringInItem(item[key], value);
        } else {
          return item[key].toString().toLowerCase().includes(value);
        }
      }
    }
  });
};
