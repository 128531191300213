import { statuses, errorStatuses, successStatuses } from "../helpers/statuses";
import Chip from "./Chip";
import locationIcon from "../img-svg/location.svg";
import warningIcon from "../img-svg/warning1.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllParcels } from "../reducers/parcels";
import { getCountryFlagIcon } from "../helpers/countries";
import { IOrder, IParcel } from "../interfaces/interfaces";

export default function DashboardRow(data: { data: IParcel; id: string }) {
  let addressTo = `${data.data.shipping_to.address}, ${data.data.shipping_to.postal_code} ${data.data.shipping_to.city}`;
  const history = useHistory();
  const { orders } = useSelector(getAllParcels);
  const associatedOrder = orders.filter(
    (order: IOrder) => order.parcels[0] === parseInt(data.id)
  )[0];
  const trackingUrl = `https://gls-group.eu/PL/pl/sledzenie-paczek?match=${data.data.parcel_number}`;
  const error = errorStatuses.includes(data.data.status);
  const success = successStatuses.includes(data.data.status);

  return (
    <tr>
      <td className="col">
        <span>
          {data.data.parcel_number ? (
            <a href={trackingUrl} target="_blank" rel="noreferrer">
              {data.data.parcel_number}
            </a>
          ) : (
            "Brak numeru przesyłki"
          )}
        </span>
      </td>
      <td className="col">
        <span>{data.data.order_id}</span>
      </td>
      <td className="col">
        <div className="flex-center">
          {}
          <Chip type={`small ${error && "requires"} ${success && "received"}`}>
            {statuses.pl[data.data.status as keyof typeof statuses.pl]}
          </Chip>
          {error && <img src={warningIcon} className="warningIcon" alt="" />}
        </div>
      </td>
      <td className="col">
        <span>{data.data.shipping_to.name}</span>
      </td>
      <td className="col">
        <span>{data.data.weight} KG</span>
      </td>
      <td className="col">
        <img src={locationIcon} alt="" />
        <span className="delivery">{addressTo}</span>
      </td>
      <td className="col">
        <div className="flex-center">
          <img
            src={getCountryFlagIcon(data.data.shipping_to.country_code)}
            alt="country flag icon"
          />
          <span>{data.data.shipping_to.country_code}</span>
        </div>
      </td>
      <td className="col">
        <span>
          {associatedOrder && (
            <a onClick={() => history.push(`/order/${associatedOrder.id}/`)}>
              Pokaż
            </a>
          )}
        </span>
      </td>
    </tr>
  );
}
