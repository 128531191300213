import "../scss/login-base.scss";
import "../scss/MultiParcel.scss";

import APIConnector from "../apiConnector";
import Button from "../components/Button";
import MobileModal from "../components/MobileModal";
import { ReactComponent as QIcon } from "../img-svg/qMark.svg";
import drag from "../img-svg/drag&drop.svg";
import { useHistory } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import {
  fetchParcels,
  fetchParcelsToSend,
  getAllParcels,
} from "../reducers/parcels";
import { useSelector } from "react-redux";
import {
  errorMessage,
  infoMessage,
  successMessage,
} from "../helpers/notifications";
import { apiErrors, apiInfo } from "../helpers/errors";
import { useAppDispatch } from "../hook/reduxHooks";

export default function MultiParcel() {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [error] = useState("");
  const connector = new APIConnector();

  const [information, setInformation] = useState(false);
  const closeInfo = () => setInformation(false);
  const dispatch = useAppDispatch();
  const { parcelsToSend } = useSelector(getAllParcels);

  const handleFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      connector.uploadAmazonOutputFile(formData).then((res) => {
        if (res.ok) {
          dispatch(fetchParcelsToSend()).then((res) => {
            if (res.payload.count > parcelsToSend.length) {
              successMessage(
                `Pomyślnie zaimportowano przesyłki (${
                  res.payload.count - parcelsToSend.length
                }) z pliku`
              );
              dispatch(fetchParcels());
              history.push("/to_send");
            } else {
              infoMessage(apiInfo.AmazonFileUploadNoNewParcels);
            }
          });
        } else {
          setFile(null);
          e.target.value = "";
          errorMessage(apiErrors.AmazonFileUploadError);
        }
      });
    } else {
      setFile(null);
    }
  };

  return (
    <div id="multi-parcel">
      <div className={"sub-nav__wrapper"}>
        <header className="sub-nav">
          <h1>Dodaj wiele przesyłek</h1>
          <div className="btn-container">
            <Button
              type={"primary"}
              onClick={() => {
                history.push("/shipment");
              }}
            >
              Dodaj paczkę
            </Button>
          </div>
          <MobileModal />
        </header>
      </div>
      <main>
        <div className="dragNdrop__container">
          <span
            className="formItemError"
            onClick={() => history.push("/user-data")}
          >
            {error && error}
          </span>
          <div className="drop-area">
            <div
              className={
                information
                  ? "informationActive informationHidden"
                  : "informationHidden"
              }
            >
              <div className="info">
                <div className="info__title">
                  <p>Pobieranie raportu (txt) niewysłanych zamówień w Amazon</p>
                  <span onClick={closeInfo}>Zamknij</span>
                </div>
                <div className="info__content">
                  <span>
                    Aby zażądać raportu z niewysłanych zamówień, wykonaj
                    następujące kroki:
                  </span>
                  <span>
                    1. W programie Seller Central kliknij kartę Orders
                    (Zamówienia), a następnie kliknij opcję Order Reports
                    (Raporty zamówień).
                  </span>
                  <span>
                    2. Na stronie Raporty zamówień, kliknij na Unshipped Orders
                    (niewysłane zamówienia).
                  </span>
                  <span>
                    3. W sekcji Request an Unshipped Order Report (Żądanie
                    raportu o nie wysłanych zamówieniach) kliknij przycisk
                    Request Report (Żądanie raportu).
                  </span>
                  <span>
                    4. Zostanie wyświetlone okno stanu potwierdzające żądanie i
                    rozpocznie się przetwarzanie informacji o zamówieniu w celu
                    utworzenia raportu, możebyć konieczne odświeżenie strony.
                  </span>
                  <span>
                    5. Załaduj pobrany plik do Parcel Planet i zarządzaj swoimi
                    paczkami.
                  </span>
                  <span>
                    Film z instrukcją:{" "}
                    <a
                      href="https://www.youtube.com/watch?v=PBCkG2YNYdESource:
                      amazon.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=PBCkG2YNYdESource:
                      amazon.com
                    </a>
                  </span>
                </div>
              </div>
              <div className="plusButton" onClick={closeInfo}>
                <div className="plusButton__icon"></div>
              </div>
            </div>
            <div className="drop-area__content">
              <img src={drag} alt="drag and drop icon" />
              <p>Przeciągnij i upuść tutaj plik .CSV lub .TXT</p>
              <span>lub</span>
              <label className="file-upload">
                <input
                  type="file"
                  onChange={(e) => handleFileChanged(e)}
                  accept=".txt,.csv"
                />
                <span>
                  {file ? "Zmień wybrany plik" : "Wybierz z komputera"}
                </span>
              </label>
            </div>
          </div>
        </div>
        <footer>
          <div
            className="questionMark"
            onClick={(e) => setInformation(!information)}
          >
            <QIcon className="qestionIcon" />
            <p>Instrukcja pobierania listy paczek z Amazon</p>
          </div>
          <Button
            type="white"
            onClick={() => {
              history.push("/help");
            }}
          >
            Pomoc{" "}
          </Button>
        </footer>
      </main>
    </div>
  );
}
