import "../scss/Header.scss";
import "../style.scss";

import Button from "./Button";
import FormItem from "./FormItem";
import Logo from "./Logo";
import MenuItem from "./MenuItem";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export default function Header({ logoColor }: { logoColor?: string }) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const closeHandler = () => {
    setOpen(false);
  };
  const handleClick = () => setOpen(!open);
  return (
    <header className={open ? "preLoginHeader active" : "preLoginHeader"}>
      <Logo destination={""} color={logoColor} />
      <ul className={open ? "menu-options active" : "menu-options"}>
        <MenuItem
          linkTo="https://gls-group.eu/PL/pl/sledzenie-paczek"
          data-text="Śledzenie przesyłki"
          className="option"
          target="_blank"
          onClick={closeHandler}
          key={"manuItem-1"}
          routerLink={false}
        >
          Śledzenie przesyłki
        </MenuItem>
        <MenuItem
          linkTo="knowledge"
          data-text="Baza wiedzy"
          className="option"
          onClick={closeHandler}
          key={"manuItem-2"}
        >
          Baza wiedzy
        </MenuItem>
        <MenuItem
          linkTo="price"
          data-text="Cennik"
          className="option"
          onClick={closeHandler}
          key={"manuItem-3"}
        >
          Cennik
        </MenuItem>
        <MenuItem
          linkTo="contact"
          data-text="Kontakt"
          className="option"
          onClick={closeHandler}
          key={"manuItem-4"}
        >
          Kontakt
        </MenuItem>
        <div className="btn-container mobile-option">
          <FormItem>
            <Button
              onClick={() => {
                history.push("/login");
                closeHandler();
              }}
              type={"white"}
              key={"manuItem-5"}
            >
              Logowanie
            </Button>
          </FormItem>
          <FormItem>
            <Button
              onClick={() => {
                history.push("/register");
                closeHandler();
              }}
              type={"primary"}
            >
              Rejestracja
            </Button>
          </FormItem>
        </div>
      </ul>
      <div className="btn-container">
        <FormItem>
          <Button onClick={() => history.push("/login")} type={"btn"}>
            Logowanie
          </Button>
        </FormItem>
        <FormItem>
          <Button onClick={() => history.push("/register")} type={"primary"}>
            Rejestracja
          </Button>
        </FormItem>
      </div>
      <div
        className={`burger-menu ${open ? "open" : null}`}
        onClick={handleClick}
      >
        <div className="burger-menu__icon"></div>
      </div>
    </header>
  );
}
