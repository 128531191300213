import "../scss/ShipmentPay.scss";

import RadioBox from "./RadioBox";
import { useAppDispatch } from "../hook/reduxHooks";
import React from "react";
import { selectPaymentGate } from "../reducers/parcels";

interface IShipmentPay {
  index: string;
  name: string;
  logo: string;
}

export default function ShipmentPay({ index, name, logo }: IShipmentPay) {
  const dispatch = useAppDispatch();

  const handlePaymentGateClick = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(selectPaymentGate(index));
  };

  return (
    <div className="shipment-pay-card">
      <div className="shipment-pay">
        <span>{name}</span>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <RadioBox
          index={index}
          name="payMethod"
          onClick={handlePaymentGateClick}
          checked={true}
        />
      </div>
    </div>
  );
}
