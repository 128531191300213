import "../scss/Input.scss";

import { handleKeyPress } from "../helpers/form";

interface IInput {
  placeholder: string;
  type?: string;
  updateHandler?: (e: any) => void;
  value?: string | number;
  name?: string;
  register?: (...args: any) => any;
  error?: any;
  validationRules?: any;
  id?: string;
  maxLength?: number;
  className?: string;
  onChange?: (arg0?: any) => void;
  autoComplete?: string;
}

export default function Input({
  placeholder,
  type,
  updateHandler,
  value,
  name,
  register,
  error,
  validationRules,
  id,
  maxLength,
  className,
  onChange,
  autoComplete = "chrome-off",
}: IInput) {
  return (
    <div className="inputWrapper">
      <div className="inputBox">
        {register ? (
          <input
            className={error && "invalid"}
            type={type}
            name={name}
            {...register(name, { ...validationRules })}
            onKeyUp={updateHandler}
            autoComplete={autoComplete}
            id={id}
            maxLength={maxLength}
            onKeyPress={handleKeyPress}
            placeholder=" "
          />
        ) : (
          <input
            className={className}
            type={type}
            onKeyUp={updateHandler}
            value={value}
            name={name}
            autoComplete={autoComplete}
            id={id}
            maxLength={maxLength}
            onChange={onChange}
            placeholder=" "
          />
        )}
        <span>{placeholder}</span>
      </div>
      {error && <small className="errorMsg">{`${error.message}`}</small>}
    </div>
  );
}
