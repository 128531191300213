import "../scss/WhyUs.scss";

import blueImg from "../img-svg/Saly-10.png";
import greenImg from "../img-svg/Saly-11.png";
import orangeImg from "../img-svg/Saly-1.png";
import WhyUsCard from "./WhyUsCard";

const orangeHeader = "Integracja z Amazon, eBay, Etsy i innymi platformami";
const orangeText =
  "Automatyzacja to klucz do oszczędności czasu i nerwów z powodu pomyłek. Bezpłatna integracja pozwoli Ci zapomnieć o wielu zadaniach.";
const orangeFooterHeader = "127s";
const orangeFooterText = "Średni czas potrzebny na integrację z eBay";
const greenHeader = "Łatwość obsługi i wsparcie naszego zespołu 24/7";
const greenText =
  "Dodaj przesyłkę i nią zarządzaj z jednego miejsca. Poinformujemy Cie o ważniejszych rzeczach, więc o częsci rzeczy możesz zapomnieć.";
const greenFooterHeader = "32 min";
const greenFooterText = "Średni czas odpowiedzi naszego zespołu";
const blueHeader = "Realna oszczędność kosztów wysyłki";
const blueText =
  "Skupienie ParcelPlanet na wysyłce z polski za granicę zaowocowała lepszymi stawkami od partnerów. Dzięki czemu zyskasz realne pieniądze w kieszeni.";
const blueFooterHeader = "do -60%";
const blueFooterText = "Możesz zaoszczędzić na kosztach wysyłki";

export default function WhyUs() {
  return (
    <section id={"whyUs"}>
      <h1>Przedsiębiorco, rozwijaj swoją działalność</h1>
      <div className="whyUsCardsContainer">
        <WhyUsCard
          cardColor="orange"
          bgImg={orangeImg}
          type={"Advantage"}
          headerValue={orangeHeader}
          textValue={orangeText}
          footerHeader={orangeFooterHeader}
          footerText={orangeFooterText}
        ></WhyUsCard>
        <WhyUsCard
          cardColor="green"
          bgImg={greenImg}
          type={"Advantage"}
          headerValue={greenHeader}
          textValue={greenText}
          footerHeader={greenFooterHeader}
          footerText={greenFooterText}
        ></WhyUsCard>
        <WhyUsCard
          cardColor="blue"
          bgImg={blueImg}
          type={"Advantage"}
          headerValue={blueHeader}
          textValue={blueText}
          footerHeader={blueFooterHeader}
          footerText={blueFooterText}
        ></WhyUsCard>
      </div>
    </section>
  );
}
