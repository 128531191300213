import MenuItem from "@material-ui/core/MenuItem";
import austria from "../flags/austria.svg";
import belgium from "../flags/belgium.svg";
import france from "../flags/france.svg";
import germany from "../flags/germany.svg";
import italy from "../flags/italy.svg";
import netherlands from "../flags/netherlands.svg";
import poland from "../flags/poland.svg";
import spain from "../flags/spain.svg";
import sweden from "../flags/sweden.svg";

export const countries = [
  {
    code: "AT",
    flag: "🇦🇹",
    text: "Austrii",
    flagIcon: austria,
    label: "Austria",
  },
  {
    code: "BE",
    flag: "🇧🇪",
    text: "Belgii",
    flagIcon: belgium,
    label: "Belgia",
  },
  {
    code: "DE",
    flag: "🇩🇪",
    text: "Niemiec",
    flagIcon: germany,
    label: "Niemcy",
  },
  {
    code: "FR",
    flag: "🇫🇷",
    text: "Francji",
    flagIcon: france,
    label: "Francja",
  },
  {
    code: "IT",
    flag: "🇮🇹",
    text: "Włoch",
    flagIcon: italy,
    label: "Włochy",
  },
  {
    code: "NL",
    flag: "🇳🇱",
    text: "Holandii",
    flagIcon: netherlands,
    label: "Holandia",
  },
  {
    code: "SE",
    flag: "🇸🇪",
    text: "Szwecji",
    flagIcon: sweden,
    label: "Szwecja",
  },
  {
    code: "ES",
    flag: "🇪🇸",
    text: "Hiszpanii",
    flagIcon: spain,
    label: "Hiszpania",
  },
  {
    code: "PL",
    flag: "🇵🇱",
    text: "Polski",
    flagIcon: poland,
    label: "Polska",
  },
];

export const shippingFromCountries = [
  {
    code: "PL",
    flag: "🇵🇱",
    text: "Polski",
    flagIcon: poland,
    label: "Polska",
  },
];

const _getCountryByCode = (code: string) => {
  return countries.find((el) => el.code === code);
};

export const sortCountries = countries.sort(function (a, b) {
  if (a.code < b.code) {
    return -1;
  }
  if (a.code > b.code) {
    return 1;
  }
  return 0;
});

export const getCountryText = (code: string) => {
  let country = _getCountryByCode(code);
  return country && country.text;
};

export const getCountryFlagIcon = (code: string) => {
  let country = _getCountryByCode(code);
  return country && country.flagIcon;
};

export const getShippingFromSelectOptions = () => {
  return shippingFromCountries.map((country, key) => (
    <option value={country.code} key={key}>
      {country.code}
    </option>
  ));
};

export const getShippingFromCustomSelectOptions = () => {
  return shippingFromCountries.map((country, key) => (
    <MenuItem value={country.code} key={key}>
      <img src={country.flagIcon} alt={country.label} />{" "}
      <span> {country.code}</span>
    </MenuItem>
  ));
};

export const getSelectOptions = (secondSelectCountry: string) => {
  return sortCountries
    .filter((country) => country.code !== secondSelectCountry)
    .map((country, key) => (
      <option value={country.code} key={key}>
        {country.code}
      </option>
    ));
};

export const geShippingToCustomSelectOptions = (
  secondSelectCountry: string
) => {
  return sortCountries
    .filter((country) => country.code !== secondSelectCountry)
    .map((country, key) => (
      <MenuItem value={country.code} key={key}>
        <img src={country.flagIcon} alt={country.label} />{" "}
        <span> {country.code}</span>
      </MenuItem>
    ));
};
