import "../scss/PasswordRemind.scss";

import React, { useEffect, useState } from "react";

import Button from "../components/Button";
import FormItem from "../components/FormItem";
import Input from "../components/Input";
import { useForm } from "react-hook-form";
import {
  validationRules,
  passwordsShouldBeExact,
} from "../helpers/validationRules";

import greenBall from "../img-svg/shapes/greenBall.png";
import greenRect from "../img-svg/shapes/greenRect.png";
import pinkCircle from "../img-svg/shapes/pinkCircle.png";
import pinkShape2 from "../img-svg/shapes/pinkShape2.png";
import whiteCube from "../img-svg/shapes/whiteBox2.png";
import whiteFig from "../img-svg/shapes/whiteBox1.png";
import APIConnector from "../apiConnector";
import { useHistory, useLocation } from "react-router-dom";
import { activateUser, logout } from "../reducers/profile";
import { ReactComponent as EyeIcon } from "../img-svg/eye.svg";
import { errorMessage, successMessage } from "../helpers/notifications";
import {
  apiErrors,
  apiSuccess,
  getChangePasswordError,
} from "../helpers/errors";

export default function ChangePassword() {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordToken, setToken] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const apiConnector = new APIConnector();
  const { search } = useLocation();

  useEffect(() => {
    let token = new URLSearchParams(search).get("token");

    if (token) {
      setToken(token);
    } else {
      history.push("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useForm({ mode: "all", reValidateMode: "onBlur" });

  const changePasswordHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    password: string
  ) => {
    e.preventDefault();
    if (Object.keys(errors).length === 0) {
      apiConnector
        .confirmResetPassword(password, changePasswordToken)
        .then((res) => {
          successMessage(apiSuccess.ChangePasswordSuccess);
          history.push("/login");
        })
        .catch((err) => {
          errorMessage(getChangePasswordError(err));
        });
    }
  };

  return (
    <div id={"login"}>
      <section id={"loginForm"}>
        <h1>Ustaw nowe hasło</h1>
        <form id="passwordReminderForm">
          <FormItem>
            <div className="passwordWrapper">
              <Input
                updateHandler={(e) => {
                  setPassword(e.target.value);
                  if (confirmPassword.length > 0) {
                    trigger();
                  }
                }}
                register={register}
                error={errors.password}
                name="password"
                validationRules={{
                  ...validationRules.password,
                }}
                placeholder={"Hasło"}
                type={passwordVisibility ? "password" : "text"}
              />
              <EyeIcon
                className={
                  !passwordVisibility
                    ? "eyeIcon"
                    : "eyeIcon semi" + (errors.password ? " with-error" : "")
                }
                onClick={() => setPasswordVisibility(!passwordVisibility)}
              />
            </div>
          </FormItem>
          <FormItem>
            <div className="passwordWrapper">
              <Input
                updateHandler={(e) => setConfirmPassword(e.target.value)}
                register={register}
                error={errors.confirmPassword}
                name="confirmPassword"
                validationRules={{
                  ...validationRules.password,
                  validate: (value: string) =>
                    passwordsShouldBeExact(value, watch("password")),
                }}
                placeholder={"Powtórz hasło"}
                type={passwordVisibility ? "password" : "text"}
              />
              <EyeIcon
                className={
                  !passwordVisibility
                    ? "eyeIcon"
                    : "eyeIcon semi" +
                      (errors.confirmPassword ? " with-error" : "")
                }
                onClick={() => setPasswordVisibility(!passwordVisibility)}
              />
            </div>
          </FormItem>
          <FormItem>
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                changePasswordHandler(e, password)
              }
              type={"primary"}
            >
              Zmień hasło
            </Button>
          </FormItem>
        </form>
      </section>

      <div id={"passwordReminderBackground"}>
        <div className={"rect"} />
        <img src={greenRect} className={"greenRect"} alt="" />
        <img src={whiteFig} className={"whiteFig"} alt="" />
        <img src={pinkCircle} className={"pinkCircle"} alt="" />
        <img src={greenBall} className={"greenBall"} alt="" />
        <img src={whiteCube} className={"whiteCube"} alt="" />
        <img src={pinkShape2} className={"pinkShape2"} alt="" />
      </div>
      <div className="backgroundGradient" />
    </div>
  );
}
