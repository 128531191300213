export type ErrorsActions = SetError | DummyAction;

export enum ActionType {
  SetError,
  DummyAction,
}

type SetError = {
  type: ActionType.SetError;
  payload: ErrorType;
};

type DummyAction = {
  type: ActionType.DummyAction;
};

type ErrorType = {
  errorMsg: string;
  errorStatus: string;
};

type initialStateErrorsType = {
  errorMsg: string;
  errorStatus: string;
};

const initialState: initialStateErrorsType = {
  errorMsg: "",
  errorStatus: "",
};

const errors = (state = initialState, action: ErrorsActions) => {
  switch (action.type) {
    case ActionType.SetError:
      return {
        ...state,
        errorMsg: action.payload.errorMsg,
        errorStatus: action.payload.errorStatus,
      };
    case ActionType.DummyAction:
      return initialState;
    default:
      return initialState;
  }
};

export default errors;
