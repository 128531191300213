import "../scss/login-base.scss";
import "../scss/Orders.scss";

import {
  clearSelectedParcels,
  deleteOrder,
  fetchOrders,
  getAllParcels,
} from "../reducers/parcels";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Button from "../components/Button";
import Chip from "../components/Chip";
import DesktopModal from "../components/DesktopModal";
import Input from "../components/Input";
import LoginFooter from "../components/LoginFooter";
import MobileModal from "../components/MobileModal";
import OrdersTable from "../components/OrdersTable";
import { checkIfStringInItem } from "../helpers/search";
import { getBalance } from "../reducers/profile";
import { statuses } from "../helpers/statuses";
import { useHistory } from "react-router-dom";
import { errorMessage, successMessage } from "../helpers/notifications";
import { IOrder } from "../interfaces/interfaces";
import { useAppDispatch } from "../hook/reduxHooks";

export default function Orders() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { selectedParcels, orders } = useSelector(getAllParcels);
  const [activeFilter, setActiveFilter] = useState("PAYMENT_REQUIRED");
  let filters: Set<string> = new Set(
    orders.map((order: IOrder) => order.status.code)
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<IOrder[]>([]);

  let tmpFilters: string[] = [];
  [...filters].forEach((el) => {
    if (el === "PAYMENT_REQUIRED") {
      tmpFilters.unshift(el);
    } else {
      tmpFilters.push(el);
    }
  });

  filters = new Set(tmpFilters);

  const filterData = (value: string) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") {
    } else {
      const searchedOrders = orders.filter((item) =>
        checkIfStringInItem(item, lowercasedValue)
      );
      setData(searchedOrders);
    }
  };

  const searchHandler = (value: string) => {
    setSearchTerm(value);
    filterData(value);
  };

  useEffect(() => {
    dispatch(clearSelectedParcels());
    if (!orders) {
      dispatch(fetchOrders());
    }
    setData(orders);
  }, [orders]);

  const handleDeleteOrders = () => {
    selectedParcels &&
      selectedParcels.map(async (orderId) => {
        const result = await dispatch(deleteOrder(orderId));
        if (deleteOrder.rejected.match(result)) {
          errorMessage(`${result.error.message} ID: ${orderId}`);
        } else {
          successMessage(`Pomyślnie usunięto zamówienie. ID: ${orderId}`);

          dispatch(getBalance());
        }
      });
  };

  const handlePayOrders = () => {
    selectedParcels && history.push(`/payments/${selectedParcels[0]}`);
  };

  const selectFilter = (key: string) => {
    setActiveFilter(key);
  };

  const checkIfPaid = (ordersIds: string[]) => {
    let selectedOrders = orders.filter((order) =>
      ordersIds.includes(order.id.toString())
    );
    return selectedOrders.some((order) => order.is_paid === true);
  };

  return (
    <>
      <div id={"orders"}>
        <div className={"sub-nav__wrapper"}>
          <header className="sub-nav">
            <h1>Zamówienia</h1>
            <div className="search__container">
              <Input
                placeholder="Wyszukaj"
                value={searchTerm}
                onChange={(e) => searchHandler(e.target.value)}
              />
            </div>
            <div className="btn-container">
              <DesktopModal />
            </div>
            <MobileModal />
          </header>
        </div>
        <main className="table-main__container">
          <div className={"chips"}>
            {[...filters].map((filter: string, id) => {
              return (
                <li style={{ display: `${"inline-block"}` }} key={id}>
                  <Chip
                    id={filter}
                    active={activeFilter === filter}
                    onClick={() => {
                      selectFilter(filter);
                      dispatch(clearSelectedParcels());
                    }}
                  >
                    {statuses.pl[filter as keyof typeof statuses.pl] || filter}{" "}
                    (
                    {
                      data.filter(
                        (order: IOrder) => order.status.code === filter
                      ).length
                    }
                    )
                  </Chip>
                </li>
              );
            })}
            <Chip
              id={"all"}
              onClick={() => {
                selectFilter("ALL");
                dispatch(clearSelectedParcels());
              }}
              active={activeFilter === "ALL" && true}
            >
              {statuses.pl.ALL} ({data.length})
            </Chip>
          </div>
          <OrdersTable
            orders={
              data && activeFilter !== "ALL"
                ? data.filter((order) => order.status.code === activeFilter)
                : data
            }
          />
        </main>
      </div>
      <LoginFooter />

      {selectedParcels.length > 0 && (
        <div className="button-background">
          <div className="container">
            <Button
              type={"primary"}
              onClick={() => handlePayOrders()}
              disabled={checkIfPaid(selectedParcels)}
            >
              Opłać
            </Button>
            <Button type={"white"} onClick={() => handleDeleteOrders()}>
              Usuń
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
