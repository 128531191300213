import "../scss/ShipmentCollectionDate.scss";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import APIConnector from "../apiConnector";
import Button from "./Button";
import FormItem from "./FormItem";
import ShipmentPay from "./ShipmentPay";
import { getAllParcels } from "../reducers/parcels";
import card from "../img-svg/card.png";
import { errorMessage, successMessage } from "../helpers/notifications";
import { apiErrors, apiSuccess } from "../helpers/errors";
import { IOrder } from "../interfaces/interfaces";
import ShipmentData from "./ShipmentData";
import { getNextBusinessDates } from "../helpers/dates";
const payMethodes = [
  {
    index: "11",
    name: "Karta płatnicza",
    value: "stripe",
    logo: `${card}`,
  },
];

export default function ShipmentCollectionDate() {
  const possibleShipmentDays = getNextBusinessDates(7);
  type MatchTypes = {
    id: string;
  };
  const match = useRouteMatch<MatchTypes>();
  const { orders, selectedParcels, selectedPaymentGate } =
    useSelector(getAllParcels);
  const [order, setOrder] = useState<
    { ids: string[]; price: number } | undefined | IOrder
  >();
  const connector = new APIConnector();
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setError(null);
    let params = new URLSearchParams(window.location.search);
    let q = params.get("success");
    if (q === "false") {
      errorMessage(apiErrors.PaymentError);
      localStorage.removeItem("dispatch_date");
      history.push("/orders");
    } else {
      if (!q) {
        localStorage.setItem("dispatch_date", possibleShipmentDays[0].date);
      }
      let secret = params.get("payment_token");
      if (secret != null) {
        connector.confirmPayment(secret).then((res) => {
          if (res.status < 300) {
            successMessage(apiSuccess.PaymentSuccess);
            let dispatchDate = localStorage.getItem("dispatch_date");
            connector.dispatchOrder(secret!, dispatchDate!).then((res) => {
              if (res.status !== 200) {
                errorMessage(apiErrors.DispatchError);
              }
            });
          } else {
            errorMessage(apiErrors.PaymentError);
          }
          localStorage.removeItem("dispatch_date");
          history.push("/order-confirmed");
        });
      }
    }

    if (selectedParcels.length > 0) {
      let selectedOrders = orders.filter((order) =>
        selectedParcels.includes(order.id)
      );
      setOrder({
        ids: selectedParcels,
        price: selectedOrders.reduce((a, b) => a + b.price, 0),
      });
    } else {
      setOrder(orders.find((order) => order.id === match.params.id));
    }
  }, [orders]);

  const payOrder = (e: MouseEvent) => {
    e.preventDefault();
    if (order) {
      if (selectedPaymentGate === 22) {
        connector
          .payWithPaypal(selectedParcels)
          .then((data) => data.json())
          .then((res) => {
            window.location.replace(res.url);
          });
      } else {
        connector
          .payWithStripe(selectedParcels)
          .then((data) => data.json())
          .then((res) => {
            window.location.replace(res.url);
          });
      }
    }
  };

  return (
    <div id="shipment-setup">
      {error && (
        <div className="save-failure">
          <p>{error}</p>
        </div>
      )}
      <form>
        <div className="shipment-date__wrapper">
          <h2>Wybierz termin odbioru paczki przez kuriera:</h2>
          <div className={"date-option__container selected"}>
            {possibleShipmentDays.map((date) => (
              <FormItem>
                <ShipmentData
                  index={date.date}
                  day={date.day}
                  date={date.date}
                />
              </FormItem>
            ))}
          </div>
        </div>
        <div className="shipment-pay__wrapper">
          <h2>Wybierz sposób płatności:</h2>
          <div className={"pay-option__container selected"}>
            <FormItem>
              {payMethodes.map((payMethode) => {
                return <ShipmentPay {...payMethode} />;
              })}
            </FormItem>
          </div>
        </div>
        <div className="finalize-btn">
          <Button type={"primary"} onClick={(e) => payOrder(e)}>
            Zapłać {order ? order.price : ""} zł (w tym VAT)
          </Button>
        </div>
      </form>
    </div>
  );
}
