import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Chip from "../components/Chip";
import DashboardTable from "../components/DashboardTable";
import DesktopModal from "../components/DesktopModal";
import LoginFooter from "../components/LoginFooter";
import MobileModal from "../components/MobileModal";
import Skeleton from "react-loading-skeleton";
import { getAllParcels } from "../reducers/parcels";
import { fetchParcels } from "../reducers/parcels";
import { statuses } from "../helpers/statuses";
import { IParcel } from "../interfaces/interfaces";
import { useAppDispatch } from "../hook/reduxHooks";

export default function Dashboard() {
  const { parcels, loading } = useSelector(getAllParcels);
  const [activeFilter, setActiveFilter] = useState("ALL");
  const filters: Set<string> = new Set(
    parcels.map((parcel: IParcel) => parcel.status)
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchParcels());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectFilter = (key: string) => {
    setActiveFilter(key);
  };

  return (
    <div>
      <div id={"dashboard"}>
        <div className={"sub-nav__wrapper"}>
          <header>
            <h1>Miło Cię widzieć!</h1>
            <div className={"btn-container"}>
              <DesktopModal />
            </div>
            <MobileModal />
          </header>
        </div>
        {parcels.length > 0 ? (
          <main className={"dashboardTable"}>
            <h2>Ostatnie wydarzenia</h2>
            <div className={"chips horizontal-scroll-wrapper"}>
              <Chip
                id={"all"}
                onClick={() => selectFilter("ALL")}
                active={activeFilter === "ALL" && true}
              >
                {statuses.pl.ALL} ({parcels.length})
              </Chip>
              {[...filters].map((filter, id) => {
                return (
                  <li style={{ display: `${"inline-block"}` }} key={id}>
                    <Chip
                      id={filter}
                      active={activeFilter === filter}
                      onClick={() => selectFilter(filter)}
                    >
                      {statuses.pl[filter as keyof typeof statuses.pl]} (
                      {
                        parcels.filter(
                          (parcel: IParcel) => parcel.status === filter
                        ).length
                      }
                      )
                    </Chip>
                  </li>
                );
              })}
            </div>
            <DashboardTable
              parcels={
                parcels && activeFilter !== "ALL"
                  ? parcels.filter(
                      (parcel: IParcel) => parcel.status === activeFilter
                    )
                  : parcels
              }
            />
          </main>
        ) : loading === true ? (
          <Skeleton
            count={5}
            style={{ marginBottom: 10, marginTop: 10 }}
            height={"50px"}
          />
        ) : (
          <h3>Brak paczek</h3>
        )}
      </div>
      <LoginFooter />
      <div className="backgroundGradient" />
    </div>
  );
}
