import "../scss/Footer.scss";

import FooterEmployee from "./FooterEmployee";
import LoginHeader from "./LoginHeader";
import glsLogo from "../img-svg/gls.png";
import policy from "../docs/policy.pdf";
import statute from "../docs/statute.pdf";
import { workers } from "../helpers/pp_employees";

const partnerLogo = glsLogo;
const partnerDescribe = "Jesteśmy autoryzowanym partnerem GLS Parcel";

const adress =
  "Parcel Planet Spółka sp. z o.o.\nul. Kazimierza Deyny 6\n83-000 Pruszcz Gdański";

const copyright = "Parcel Planet © 2021";

export default function LoginFooter() {
  return (
    <div id="footer">
      <div className="footer__wrapper">
        <div className="footer-header">
          <LoginHeader logoColor="white" />
        </div>
        <section>
          <main>
            <p>
              Dostępność obsługi klienta: <br />
              <br />
              Pon - Pt: 8:30 - 16:00 <br />
              Sob - Nd: Nieczynne
            </p>
            <div className="partner-data">
              <img src={partnerLogo} alt="" />
              <p>{partnerDescribe}</p>
            </div>
            <p>{adress}</p>
          </main>
          <aside>
            {workers.map((worker, id) => {
              return <FooterEmployee {...worker} key={id} />;
            })}
          </aside>
        </section>
        <footer>
          <div>
            <a href={policy} target="_blank" rel="noreferrer">
              Polityka Prywatności
            </a>
            <a href={statute} target="_blank" rel="noreferrer">
              Regulamin portalu
            </a>
          </div>
          <div className="copyrights-container">
            <span>{copyright}</span>
          </div>
        </footer>
      </div>
    </div>
  );
}
