import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { getAllParcels } from "../reducers/parcels";
import { profileSelector } from "../reducers/profile";

interface PrivateRouteProps extends RouteProps {
  children: any;
}

const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const { address, isAuthenticated, isActive, isOnboarded } =
    useSelector(profileSelector);
  const { form } = useSelector(getAllParcels);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          props.location.pathname === "/help" ? (
            children
          ) : isActive ? (
            address.shipping_details != null ? (
              isOnboarded ? (
                form ? (
                  props.location.pathname === "/shipment" ? (
                    children
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/shipment",
                        state: { from: props.location },
                      }}
                    />
                  )
                ) : (
                  children
                )
              ) : props.location.pathname === "/onboarding" ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: "/onboarding",
                    state: { from: props.location },
                  }}
                />
              )
            ) : props.location.pathname === "/user-data" ||
              props.location.pathname.includes("user-activation") ? (
              children
            ) : (
              <Redirect
                to={{ pathname: "/user-data", state: { from: props.location } }}
              />
            )
          ) : props.location.pathname === "/register/correct" ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/register/correct",
                state: { from: props.location },
              }}
            />
          )
        ) : form ? (
          props.location.pathname === "/shipment" ? (
            children
          ) : (
            <Redirect
              to={{ pathname: "/shipment", state: { from: props.location } }}
            />
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
