import "../scss/customSelect.scss";

import {
  geShippingToCustomSelectOptions,
  getSelectOptions,
  getShippingFromCustomSelectOptions,
  getShippingFromSelectOptions,
} from "../helpers/countries";
import {
  permimeterValidation,
  postalCodeValidation,
  validationBasicRules,
  validationRules,
} from "../helpers/validationRules";
import { useEffect, useState } from "react";

import BottomSignUp from "../components/BottomSignUp";
import Button from "../components/Button";
import Card from "../components/Card";
import CardFooter from "../components/CardFooter";
import Footer from "../components/Footer";
import FormControl from "@material-ui/core/FormControl";
import FormItem from "../components/FormItem";
import Input from "../components/Input";
import InputGroup from "../components/InputGroup";
import InputLabel from "@material-ui/core/InputLabel";
import KnowledgeBase from "../components/Blog/KnowledgeBase";
import Select from "@material-ui/core/Select";
import WhyUs from "../components/WhyUs";
import { saveForm } from "../reducers/parcels";
import storyImg from "../img-svg/story1.svg";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { profileSelector } from "../reducers/profile";
import { useAppDispatch } from "../hook/reduxHooks";

const testimonialHeader = "Maria Złotoszyja - Fabryka zabawek";
const testimonialText =
  "Od 2 lat sprzedawca produktów dla dzieci na eBay oraz Amazon w Niemczech. Użytkowniczka ParcelPlanet";

export default function Landing() {
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = useForm({ mode: "all" });
  const { isAuthenticated } = useSelector(profileSelector);
  const [countryFrom, setCountryFrom] = useState("PL");
  const [openCountryFrom, setOpenCountryFrom] = useState(false);
  const [countryTo, setCountryTo] = useState("DE");
  const [openCountryTo, setOpenCountryTo] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    }
    setValue("shipping_to.country_code", countryTo);
    setValue("shipping_from.country_code", countryFrom);
  }, [isAuthenticated]);

  const dispatch = useAppDispatch();

  const onSubmit = () => {
    dispatch(saveForm(getValues()));
    history.push("/shipment");
  };

  const handleChangeCountryFrom = (event: any, name: string) => {
    setCountryFrom(event.target.value);
    setValue(name, event.target.value);
  };

  const handleCloseCountryFrom = () => {
    setOpenCountryFrom(false);
  };

  const handleOpenCountryFrom = () => {
    setOpenCountryFrom(true);
  };

  const handleChangeCountryTo = (event: any, name: string) => {
    setCountryTo(event.target.value);
    setValue(name, event.target.value);
  };

  const handleCloseCountryTo = () => {
    setOpenCountryTo(false);
  };

  const handleOpenCountryTo = () => {
    setOpenCountryTo(true);
  };

  return (
    <div id={"landing"}>
      <div className="hero-background">
        <div className="hero__wrapper">
          <section className="hero">
            <main>
              <h1>Oszczędzaj wysyłając paczki za granicę</h1>
              <form id="sendParcelForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="parcel-select__container">
                  <FormItem label={"Nadanie"} helperTxt={"(skąd)"}>
                    <div className="select">
                      <FormControl className="customSelect">
                        <InputLabel htmlFor="open-select" />
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          open={openCountryFrom}
                          onClose={handleCloseCountryFrom}
                          onOpen={handleOpenCountryFrom}
                          value={countryFrom}
                          name="country"
                          id="shipping_from.country_code"
                          onChange={(e) =>
                            handleChangeCountryFrom(
                              e,
                              "shipping_from.country_code"
                            )
                          }
                          inputProps={{
                            id: "open-select",
                          }}
                        >
                          {getShippingFromCustomSelectOptions()}
                        </Select>
                      </FormControl>
                      <select
                        {...register("shipping_from.country_code", {
                          required: { value: true, message: "" },
                        })}
                        name="shipping_from.country_code"
                      >
                        {getShippingFromSelectOptions()}
                      </select>
                      <Input
                        name="shipping_from.postal_code"
                        register={register}
                        error={
                          errors.shipping_from &&
                          errors.shipping_from[
                            "postal_code" as keyof typeof errors.shipping_from
                          ]
                        }
                        validationRules={{
                          ...validationBasicRules.required,
                          validate: (value: string) =>
                            postalCodeValidation(
                              value,
                              watch("shipping_from.country_code")
                            ),
                        }}
                        placeholder={"Kod pocztowy"}
                      />
                    </div>
                  </FormItem>
                  <FormItem label={"Doręczenie"} helperTxt={"(dokąd)"}>
                    <div className="select">
                      <FormControl className="customSelect">
                        <InputLabel htmlFor="open-select" />
                        <Select
                          open={openCountryTo}
                          onClose={handleCloseCountryTo}
                          onOpen={handleOpenCountryTo}
                          value={countryTo}
                          name="country"
                          id="shipping_to.country_code"
                          onChange={(e) =>
                            handleChangeCountryTo(e, "shipping_to.country_code")
                          }
                          inputProps={{
                            id: "open-select",
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {geShippingToCustomSelectOptions(
                            watch("shipping_from.country_code")
                          )}
                        </Select>
                      </FormControl>
                      <select
                        {...register("shipping_to.country_code", {
                          required: { value: true, message: "" },
                        })}
                        name="shipping_to.country_code"
                      >
                        {getSelectOptions(watch("shipping_from.country_code"))}
                      </select>
                      <Input
                        name="shipping_to.postal_code"
                        register={register}
                        error={
                          errors.shipping_to &&
                          errors.shipping_to[
                            "postal_code" as keyof typeof errors.shipping_to
                          ]
                        }
                        validationRules={{
                          ...validationBasicRules.required,
                          validate: (value: string) =>
                            postalCodeValidation(
                              value,
                              watch("shipping_to.country_code")
                            ),
                        }}
                        placeholder={"Kod pocztowy"}
                      />
                    </div>
                  </FormItem>
                </div>
                <div className="parcel-properties__container">
                  <div className="size">
                    <FormItem label={"Wymiary"} helperTxt={"cm"}>
                      <InputGroup separator={"x"}>
                        <Input
                          register={register}
                          error={errors.width}
                          validationRules={{
                            ...validationRules.width,
                            validate: (value: string) =>
                              permimeterValidation(
                                value,
                                watch("depth"),
                                watch("height")
                              ),
                          }}
                          name="width"
                          placeholder={"Szerokość"}
                          maxLength={3}
                        />
                        <Input
                          register={register}
                          error={errors.depth}
                          validationRules={{
                            ...validationRules.length,
                            validate: (value: string) =>
                              permimeterValidation(
                                value,
                                watch("height"),
                                watch("width")
                              ),
                          }}
                          name="depth"
                          placeholder={"Długość"}
                          maxLength={3}
                        />
                        <Input
                          register={register}
                          error={errors.height}
                          validationRules={{
                            ...validationRules.height,
                            validate: (value: string) =>
                              permimeterValidation(
                                value,
                                watch("depth"),
                                watch("width")
                              ),
                          }}
                          name="height"
                          placeholder={"Wysokość"}
                          maxLength={3}
                        />
                      </InputGroup>
                    </FormItem>
                  </div>
                  <div className="weight">
                    <FormItem label={"Waga"} helperTxt={"kg"}>
                      <Input
                        name="weight"
                        placeholder={"Waga"}
                        register={register}
                        error={errors.weight}
                        validationRules={validationRules.weight}
                        maxLength={5}
                      />
                    </FormItem>
                  </div>
                </div>

                <FormItem></FormItem>
                <FormItem>
                  <div className="btn-container">
                    <Button type={"primary"} onClick={handleSubmit(onSubmit)}>
                      Dalej
                    </Button>
                    <Button
                      type={"white"}
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Dodaj więcej paczek
                    </Button>
                  </div>
                </FormItem>
              </form>
            </main>
            <Card bgImg={storyImg} type={"testimonial"}>
              <CardFooter>
                <h4>{testimonialHeader}</h4>
                <p>{testimonialText}</p>
              </CardFooter>
            </Card>
          </section>
          <div className="landingBackground"></div>
        </div>
      </div>
      <WhyUs />
      <KnowledgeBase />
      <BottomSignUp />
      <Footer />
    </div>
  );
}
