export const apiErrors = {
  InvoiceDownloadError:
    "Nie możemy znaleźć pliku. Skontaktuj się z Administratorem",
  PaymentVerifyError:
    "Wystąpił nieoczekiwany błąd. Skontaktuj się z Administratorem",
  UserActivationError:
    "Aktywacja konta nie powiodła się. Skontaktuj się z Administratorem",
  OrderDeletionError: "Zamówienie zostało już opłacone i nie można go usunąć!",
  GetNipError: "Nie udało się pobrać danych o użytkowniku z KRS",
  EbayImportError: "Wystąpił błąd przy próbie importu paczek z Ebay",
  AmazonFileUploadError: "Wystąpił błąd przy próbie importu paczek z pliku",
  LoginError: "Nie udało się zalogować przy użyciu podanych informacji.",
  ChangePasswordTokenNotFoundError:
    "Link do zmiany hasła jest niepoprawny lub wygasł. Spróbuj powtórzyć proces przypomienia hasła.",
  ProfileUpdateError:
    "Wystąpił problem przy próbie edycji profilu. Upewnij się, czy wpisałeś poprawnie hasło.",
  WrongEmailUserRegistrationError:
    "Nie udało się zarejestrować użytkownika przy użyciu podanego adresu email.",
  UserRegistrationError:
    "Nie udało się zarejestrować użytkownika. Spróbuj ponownie lub skontaktuj się z administracją.",
  PaymentError:
    "Wystąpił problem z płatnością za zamówienie. Spróbuj ponownie.",
  DispatchError:
    "Wystąpił problem z nadaniem paczki. Skontaktuj się z obsługą klienta.",
};

export const apiSuccess = {
  ProfileUpdateSuccess: "Pomyślnie zaktualizowano dane do faktury.",
  AddParcelSuccess: "Pomyślnie dodano przesyłkę.",
  PaymentSuccess: "Pomyślnie opłacono zamówienie.",
  ChangePasswordSuccess:
    "Pomyślnie zmieniono hasło do konta, spróbuj zalogować się przy użyciu nowego hasła.",
};

export const apiInfo = {
  AmazonFileUploadNoNewParcels:
    "Pomyślnie przeprocesowano plik, jednak nie znaleziono żadnych nowych paczek.",
};

export const getChangePasswordError = (err: string) => {
  switch (err) {
    case "Not found.": {
      return "Link do zmiany hasła jest niepoprawny lub wygasł. Spróbuj powtórzyć proces zmiany hasła.";
    }
    default: {
      return "Zmiana hasła nie powiodła się. Spróbuj powtórzyć proces zmainy hasła.";
    }
  }
};
