import { Link, useHistory } from "react-router-dom";
import {
  fetchUserData,
  getBalance,
  login,
  logout,
  profileSelector,
} from "../reducers/profile";

import {
  fetchOrders,
  fetchParcels,
  fetchParcelsToSend,
  getAllParcels,
} from "../reducers/parcels";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Button from "../components/Button";
import { ReactComponent as EyeIcon } from "../img-svg/eye.svg";
import FormItem from "../components/FormItem";
import Input from "../components/Input";
import greenBall from "../img-svg/shapes/greenBall.png";
import greenRect from "../img-svg/shapes/greenRect.png";
import pinkCircle from "../img-svg/shapes/pinkCircle.png";
import pinkShape2 from "../img-svg/shapes/pinkShape2.png";
import { useForm } from "react-hook-form";
import {
  validationRules,
  validationBasicRules,
} from "../helpers/validationRules";
import whiteCube from "../img-svg/shapes/whiteBox2.png";
import whiteFig from "../img-svg/shapes/whiteBox1.png";
import { errorMessage } from "../helpers/notifications";
import { apiErrors } from "../helpers/errors";
import { useAppDispatch } from "../hook/reduxHooks";
const bottomText = "Nie masz konta? ";

export default function Login() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useSelector(profileSelector);
  const { form } = useSelector(getAllParcels);

  const [userEmail, setUserEmail] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [userPassword, setUserPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  const authenticate = async (
      e: MouseEvent,
  ) => {
    const formValues = getValues()
    const email = formValues.email
    const password = formValues.password
    e.preventDefault();
    if (
        Object.keys(errors).length === 0 &&
        email.length > 0 &&
        password.length > 0
    ) {
      const result = await dispatch(
          login({ email: email, password: password })
      );
      if (login.fulfilled.match(result)) {
        dispatch(fetchUserData()).then((res) => {
          dispatch(getBalance()).then(() => {
            dispatch(fetchParcels()).then(() => {
              dispatch(fetchParcelsToSend()).then(() => {
                dispatch(fetchOrders()).then(() => {
                  if (form && form.shipping_to && form.shipping_from) {
                    history.push("/shipment");
                  } else {
                    history.push("/dashboard");
                  }
                });
              });
            });
          });
        });
      } else {
        errorMessage(apiErrors.LoginError);
      }
    } else {
      if (email.length === 0) {
        errorMessage(
            "Aby się zalogować, użyj adresu email użytego przy rejestracji."
        );
      }
      if (password.length === 0) {
        errorMessage("Aby się zalogować, podaj hasło użyte przy rejestracji.");
      }
    }
  };

  const passwordVisibilityHandler = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(logout());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    formState: { errors },
    getValues
  } = useForm({ mode: "all", reValidateMode: "onBlur" });

  return (
      <div id={"login"}>
        <section id={"loginForm"}>
          <h1>Zaloguj się</h1>
          <form onChange={() => setErrorMsg(null)}>
            <FormItem>
              <Input
                  register={register}
                  error={errors.email}
                  name="email"
                  validationRules={{
                    ...validationRules.mail,
                    ...validationBasicRules.required,
                  }}
                  placeholder={"Email"}
              />
            </FormItem>
            <FormItem>
              <div className="passwordWrapper">
                <Input
                    name="password"
                    register={register}
                    placeholder={"Hasło"}
                    type={passwordVisibility ? "password" : "text"}
                />
                <EyeIcon
                    className={!passwordVisibility ? "eyeIcon" : "eyeIcon semi"}
                    onClick={passwordVisibilityHandler}
                />
                <p className="reminder">
                  <Link to="/remind">Przypomnij hasło</Link>
                </p>
              </div>
            </FormItem>

            <FormItem>
              <Button
                  onClick={(e: MouseEvent) =>
                      authenticate(e)
                  }
                  type={"primary"}
              >
                Zaloguj się
              </Button>
            </FormItem>
          </form>

          <p>
            {bottomText}
            <Link to="/register">Kliknij i zarejestruj się</Link>
          </p>
        </section>

        <div id={"loginBackground"}>
          <div className={"rect"} />
          <img src={greenRect} className={"greenRect"} alt="" />
          <img src={whiteFig} className={"whiteFig"} alt="" />
          <img src={pinkCircle} className={"pinkCircle"} alt="" />
          <img src={greenBall} className={"greenBall"} alt="" />
          <img src={whiteCube} className={"whiteCube"} alt="" />
          <img src={pinkShape2} className={"pinkShape2"} alt="" />
        </div>
        <div className="backgroundGradient" />
      </div>
  );
}
