import "../scss/GlsPdfInstruction.scss";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import React, { useState } from "react";

import Button from "./Button";

const GLSInstruction = () => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <div className="pdf-wrapper">
        <Document
          file="/doc/instruction.pdf"
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div className="pdf-control">
          <div className="pdf-control__buttons">
            <Button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Poprzednia
            </Button>
            <Button
              type="button"
              disabled={numPages ? pageNumber >= numPages : true}
              onClick={nextPage}
            >
              Następna
            </Button>
          </div>
          <p>
            Strona {pageNumber || (numPages ? 1 : "--")} z {numPages || "--"}
          </p>
        </div>
      </div>
      <div className="recomended-article__container">
        <div className="articles-background">
          <h2>Polecane artykuły</h2>
          <div className="articles-container">
          </div>
        </div>
      </div>
    </>
  );
};

export default GLSInstruction;
