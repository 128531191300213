import "../scss/BottomSignUp.scss";

import Button from "./Button";
import React from "react";
import device from "../img-svg/macbook1.png";
import { useHistory } from "react-router-dom";

const header = "Załóż konto i odblokuj dostęp do swojego panelu.";

export default function BottomSignUp() {
  const history = useHistory();

  return (
    <div id="bottomSignUp">
      <div className="bottomSignUp__wrapper">
        <div>
          <img src={device} alt="device" />
        </div>
        <div>
          <h2>{header}</h2>
          <Button onClick={() => history.push("/register")} type={"btn"}>
            Rozpocznij
          </Button>
        </div>
      </div>
    </div>
  );
}
