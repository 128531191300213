import "../scss/ShipmentCollectionDate.scss";
import "../scss/ParcelDetails.scss";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getAllParcels } from "../reducers/parcels";
import { IOrder, IParcel } from "../interfaces/interfaces";
import ParcelSender from "./ParcelSender";
import ParcelReceiver from "./ParcelReceiver";
import ParcelCarrier from "./ParcelCarrier";
import Button from "./Button";

type ParcelDataType = IOrder & { parcel: IParcel };

export default function OrderConfirmed() {
  const history = useHistory();
  type MatchTypes = {
    id: string;
  };
  const match = useRouteMatch<MatchTypes>();
  const [parcelData, setParcelData] = useState<ParcelDataType | null>();
  const { orders, parcels, ordersLoading } = useSelector(getAllParcels);

  useEffect(() => {
    let orderData: IOrder | null  = null;
    if(!ordersLoading) {
      orderData = orders.filter(
          (order: IOrder) => order.id == match.params.id
      )[0];
      if (!orderData) {
        history.push("/orders")
      } else {
        let parcel = parcels.filter(
            (parcel: IParcel) => parcel.id == orderData!.parcels[0].toString()
        )[0];
        setParcelData({ ...orderData!, parcel: parcel });
      }
    }

  }, [ordersLoading, orders]);


  return (
      <div id={"parcel-details"}>
        <div className={"sub-nav__wrapper"}>
          <header className="sub-nav">
            <h1>Potwierdzenie nadania przesyłki</h1>
          </header>

          <p>Dziękujemy za zamówienie i opłacenie przesyłki.</p>
          <h2>Szczegóły zamówienia</h2>

        </div>
        <main>

          <div className="content">
            <div className="content__section">
              <div className="data-company">
                <div className="sender">
                  {parcelData?.shipping_from ? <ParcelSender {...parcelData!.shipping_from} /> : ""}
                </div>
                <div className="receiver">
                  {parcelData?.shipping_to ? <ParcelReceiver {...parcelData!.shipping_to} /> : ""}
                </div>
              </div>
              <div className="data-shipment">
                <div>
                  {parcelData ? <ParcelCarrier {...parcelData.parcel} /> : ""}
                </div>
                <div className="content__docs">
                  <h4>Dokumenty przewozowe</h4>
                  <div className="documents__options">
                    Kurier przywiezie potrzebne etykiety. <br />
                    Zaoszczędzisz na drukowaniu!
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
              onClick={() => {
                history.push("/orders");
              }}
              type={"primary"}
          >
            Wróć do zamówień
          </Button>

        </main>
      </div>
  );
}
