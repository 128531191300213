import "../scss/ShopCompany.scss";

import RadioBox from "./RadioBox";

interface IShipmentPay {
  index: number;
  name: string;
  logo: string;
}

export default function ShipmentPay({ index, name, logo }: IShipmentPay) {
  return (
    <div className="integration-card">
      <div className="integration">
        <span className="company">{name}</span>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <RadioBox index={`${index}`} name="payMethode" />
      </div>
    </div>
  );
}
