import "../scss/login-base.scss";

import BalanceTable from "../components/BalanceTable";
import DesktopModal from "../components/DesktopModal";
import LoginFooter from "../components/LoginFooter";
import MobileModal from "../components/MobileModal";

export default function BalanceHistory() {
  return (
    <div>
      <div id={"balance-history"}>
        <div className={"sub-nav__wrapper"}>
          <header className="sub-nav">
            <h1>Historia salda</h1>
            <div className="btn-container">
              <DesktopModal />
            </div>
            <MobileModal />
          </header>
        </div>
        <main>
          <BalanceTable />
        </main>
      </div>
      <LoginFooter />
    </div>
  );
}
