import { getAllParcels, selectParcel } from "../reducers/parcels";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hook/reduxHooks";

import Checkbox from "./Checkbox";
import flagIcon from "../img-svg/flag.svg";
import { getCountryFlagIcon } from "../helpers/countries";
import locationIcon from "../img-svg/location.svg";
import { useHistory } from "react-router-dom";
import { IParcel } from "../interfaces/interfaces";

export default function ParcelRow({
  id,
  shipping_from,
  shipping_to,
  weight,
  description,
  price,
  sku,
}: IParcel) {
  const dispatch = useAppDispatch();
  const { selectedParcels } = useSelector(getAllParcels);
  const history = useHistory();
  let addressFrom = `${shipping_from.address}, ${shipping_from.postal_code} ${shipping_from.city}`;
  let addressTo = `${shipping_to.address}, ${shipping_to.postal_code} ${shipping_to.city}`;
  let countryCode = `${shipping_to.country_code}`;
  let parcelWeight = `${weight}`;
  let parcelSku = (sku && `${sku}`) || `${description}`;
  let receiverName = `${shipping_to.name}`;
  let parcelPrice = `${price}`;
  let parcelNumber = `${id}`;
  return (
    <div className="row-container">
      <div className="col-1">
        <Checkbox
          name=""
          selected={selectedParcels.includes(id) ? true : false}
          onClick={(e) => {
            dispatch(selectParcel(id));
          }}
          id={id}
        />
      </div>
      <div className="col-2">
        <span>{parcelNumber}</span>
      </div>
      <div className="col-3">
        <img src={flagIcon} alt="" />
        <span className="collection">{addressFrom}</span>
      </div>
      <div className="col-4">
        <span>{receiverName}</span>
      </div>
      <div className="col-5">
        <img src={locationIcon} alt="" />
        <span className="delivery">{addressTo}</span>
      </div>
      <div className="col-6">
        <img src={getCountryFlagIcon(countryCode)} alt="country flag icon" />
        <span>{countryCode}</span>
      </div>
      <div className="col-7">
        <span>{parcelSku}</span>
      </div>
      <div className="col-8">
        <span>{parcelPrice} zł</span>
      </div>
      <div className="col-9">
        <span>{parcelWeight} KG</span>
      </div>
      <div className="col-10">
        <span onClick={() => history.push(`/parcel/${id}/edit`)}>Edytuj</span>
      </div>
    </div>
  );
}
