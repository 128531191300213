import ShipmentDetails from "../components/ShipmentDetails";

export default function Shipment() {
  return (
    <div id="shipment-main__wrapper">
      <ShipmentDetails />
      {/* <ShipmentSuccess /> */}
    </div>
  );
}
