import React from "react";

interface ICardFooter {
  type?: string;
  children: any;
}

export default function CardFooter({ type, children }: ICardFooter) {
  return <div className={"cardFooter " + type}>{children}</div>;
}
