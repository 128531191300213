import "../scss/Contact.scss";

import React, { useState } from "react";

import ContactForm from "../components/ContactForm";
import ContactPerson from "../components/ContactPerson";
import ContactSendMsg from "../components/ContactSendMsg";
import Footer from "../components/Footer";
import { workers } from "../helpers/pp_employees";

export default function Contact() {
  const [regards, setRegards] = useState(false);
  const nextStep = () => {
    if (regards === false) {
      return <ContactForm setRegards={setRegards} />;
    } else {
      return <ContactSendMsg />;
    }
  };
  return (
    <div className="backgroundGradient">
      <div id="contact">
        {nextStep()}
        <div className="contact__people">
          <h1>Kontakt</h1>
          {workers.map((worker, id) => {
            return <ContactPerson {...worker} key={id} />;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
