import "../scss/login-base.scss";
import "../scss/Invoice.scss";

import InvoiceTable from "../components/InvoiceTable";
import LoginFooter from "../components/LoginFooter";

export default function Invoice() {
  return (
    <div>
      <div id={"invoice"}>
        <div className={"sub-nav__wrapper"}>
          <header className="sub-nav">
            <h1>Faktury</h1>
            <span>
              Znajdziesz tutaj faktury zbiorcze (miesięczne) najpóźniej
              pierwszego dnia miesiąca.
            </span>
          </header>
        </div>
        <main>
          <InvoiceTable />
        </main>
      </div>
      <LoginFooter />
    </div>
  );
}
