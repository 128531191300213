import "../scss/login-base.scss";
import "../scss/ToSend.scss";

import {
  clearSelectedParcels,
  deleteParcel,
  fetchParcelsToSend,
  fetchOrders,
  getAllParcels,
  sendParcels,
} from "../reducers/parcels";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Button from "../components/Button";
import DesktopModal from "../components/DesktopModal";
import Input from "../components/Input";
import LoginFooter from "../components/LoginFooter";
import MobileModal from "../components/MobileModal";
import ParcelTable from "../components/ParcelTable";
import { checkIfStringInItem } from "../helpers/search";
import { getBalance } from "../reducers/profile";
import { useHistory } from "react-router-dom";
import { successMessage } from "../helpers/notifications";
import { useAppDispatch } from "../hook/reduxHooks";

export default function ToSend() {
  const { parcelsToSend, selectedParcels, loading } =
    useSelector(getAllParcels);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(parcelsToSend);
  const filterData = (value: string) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(parcelsToSend);
    else {
      const searchedParselsToSend = parcelsToSend.filter((item) =>
        checkIfStringInItem(item, lowercasedValue)
      );
      setData(searchedParselsToSend);
    }
  };

  const searchHandler = (value: string) => {
    setSearchTerm(value);
    filterData(value);
  };

  useEffect(() => {
    dispatch(clearSelectedParcels());
    if (!parcelsToSend) {
      dispatch(fetchParcelsToSend());
    }
    setData(parcelsToSend);
  }, [parcelsToSend]);

  const handleSendParcels = async () => {
    const result = await dispatch(sendParcels(selectedParcels));
    if (sendParcels.fulfilled.match(result)) {
      dispatch(getBalance());
      const result = await dispatch(fetchOrders());
      if (fetchOrders.fulfilled.match(result)) {
        successMessage(
          `Pomyślnie zamówiono przesyłki. ID: ${selectedParcels.join(",")}`
        );
        history.push("/orders");
      }
    }
  };

  const deleteParcels = () => {
    selectedParcels.map(async (parcelId) => {
      const result = await dispatch(deleteParcel(parcelId));
      if (deleteParcel.fulfilled.match(result)) {
        successMessage(`Pomyślnie usunięto przesyłkę. ID: ${parcelId}`);
      }
    });
  };

  return (
    <>
      <div id={"to-send"}>
        <div className={"sub-nav__wrapper"}>
          <header className="sub-nav">
            <h1>Do wysłania</h1>
            <div className="search__container">
              <Input
                placeholder="Wyszukaj"
                value={searchTerm}
                onChange={(e) => searchHandler(e.target.value)}
              />
            </div>
            <div className="btn-container">
              <DesktopModal />
            </div>
            <MobileModal />
          </header>
        </div>
        <main>
          <ParcelTable parcels={data} loading={loading} />
        </main>
      </div>
      <LoginFooter />
      {selectedParcels && selectedParcels.length > 0 && (
        <div className="button-background">
          <div className="container">
            <Button
              type={"primary"}
              onClick={() => {
                handleSendParcels();
              }}
            >
              Zamów
            </Button>
            <Button
              type={"white"}
              onClick={() => {
                deleteParcels();
              }}
            >
              Usuń
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
