import ceo from "../img-svg/PP_Adrian@6x.png";
import office from "../img-svg/PP_Kacper@6x.png";

export const workers = [
  {
    avatar: `${office}`,
    name: "Kacper Janulewicz",
    position: "Customer Service Specialist",
    mobile: "+48 500 300 250",
    email: "kontakt@parcelplanet.pl",
    id: 1,
  },
  {
    avatar: `${ceo}`,
    name: "Adrian Kaszkur",
    position: "Manager",
    mobile: "+48 500 300 250",
    email: "manager@parcelplanet.pl",
    id: 1,
  },
];
