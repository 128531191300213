interface IWhyUsCardFooter {
  type: string;
  footerHeader: string;
  footerText: string;
}

export default function WhyUsCardFooter({
  type,
  footerHeader,
  footerText,
}: IWhyUsCardFooter) {
  return (
    <div className={"whyUsCardFooter" + type}>
      <h1>{footerHeader}</h1>
      <p>{footerText}</p>
    </div>
  );
}
