interface IParcelSender {
  address: string;
  city: string;
  company_name: string;
  email: string;
  phone: string;
  postal_code: string;
}

export default function ParcelSender({
  address,
  city,
  company_name,
  email,
  phone,
  postal_code,
}: IParcelSender) {
  let senderAddress = `${address}, ${postal_code} ${city}`;
  return (
    <div>
      <h4>Nadawca</h4>
      {/* <img src={senderFlag} alt="" /> */}
      <p className="collection">{senderAddress}</p>
      <p>{company_name}</p>
      <p>{phone}</p>
      <p>{email}</p>
    </div>
  );
}
