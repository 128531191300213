// const API_URL = process.env.REACT_BLOG_API_URL ||

const apiCall  = (
  method: string,
  endpoint: string,
  headers: HeadersInit,
  body: undefined | BodyInit = undefined
) => {
  process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = "0";
  return fetch(`${endpoint}`, {
    method: method,
    body: body,
    headers: headers,
  })
};

export default class BlogApiConnector {
  headers: HeadersInit;

  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  async getPosts()  {
    return apiCall(
      "GET",
      "https://blog.parcelplanet.pl/?rest_route=/wp/v2/posts",
      this.headers
    );
  }

  async get(postLinks: any) {
    if  (postLinks["wp:featuredmedia"]) {
      let link = postLinks["wp:featuredmedia"][0].href
      let res = await (await (apiCall("GET", link, this.headers))).json();
      return res.source_url
    }
  }
}
