import "../scss/ShipmentHeader.scss";

import Button from "./Button";
import FormItem from "./FormItem";
import Logo from "./Logo";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export default function ShipmentHeader() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const closeMobileMenu = () => setOpen(false);
  return (
    <header className={open ? "shipment__header active" : "shipment__header"}>
      <Logo destination={"dashboard"} />
      <div>
        <FormItem>
          <Button
            onClick={() => {
              history.push("/contact");
              closeMobileMenu();
            }}
            type={"blueBorder"}
          >
            Pomoc
          </Button>
        </FormItem>
      </div>
    </header>
  );
}
