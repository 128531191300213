import "../scss/UserDataForm.scss";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import APIConnector from "../apiConnector";
import Button from "./Button";
import FormItem from "./FormItem";
import Input from "./Input";
import { FieldValues, useForm } from "react-hook-form";
import { profileSelector, updateProfile } from "../reducers/profile";
import { useSelector } from "react-redux";
import { errorMessage, successMessage } from "../helpers/notifications";
import { apiErrors, apiSuccess } from "../helpers/errors";
import {
  validationRules,
  validationBasicRules,
} from "../helpers/validationRules";
import { useAppDispatch } from "../hook/reduxHooks";

export default function UserDataForm() {
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({ mode: "all" });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const connector = new APIConnector();
  const dispatch = useAppDispatch();
  const { address } = useSelector(profileSelector);

  useEffect(() => {
    if (address && address.shipping_details) {
      setValue("nip", address.nip);
      Object.keys(address.shipping_details).forEach((key) => {
        setValue(`shipping_details.${key}`, address.shipping_details[key]);
      });
    }
  }, [address]);

  const updateWithKRSData = () => {
    connector
      .getDataFromKRS(getValues()["nip"])
      .then((res) => res.json())
      .then((data) => {
        let hasData = false;
        Object.keys(data).forEach((k) => {
          if (data[k].length > 0) {
            hasData = true;
          }
        });
        if (hasData) {
          setValue("shipping_details.company_name", data.company_name);
          setValue("shipping_details.postal_code", data.postal_code);
          setValue("shipping_details.city", data.city);
          setValue("shipping_details.address", data.address);
        }
      })
      .catch(() => errorMessage(apiErrors.GetNipError));
  };

  const onSubmit = async (data: FieldValues) => {
    let result = await dispatch(updateProfile(data));
    if (updateProfile.fulfilled.match(result)) {
      successMessage(apiSuccess.ProfileUpdateSuccess);
    } else {
      errorMessage(apiErrors.ProfileUpdateError);
    }
  };

  return (
    <div id="user-data__form">
      {success && (
        <div className="save-correct">
          <p>Gotowe! Dane zostały zaktualizowane</p>
        </div>
      )}
      {error && (
        <div className="save-failure">
          <p>Nie udało się zaktualizować profilu. Sprawdź poprawność danych.</p>
        </div>
      )}

      <h1>Twoje dane</h1>
      <div className="form">
        <div className="main-form">
          <form
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              setError(false);
              setSuccess(false);
            }}
          >
            <FormItem>
              <Input
                className={"sender__number wide"}
                placeholder={"Numer płatnika"}
                name="nip"
                error={errors.nip}
                validationRules={validationRules.nip}
                register={register}
              />
            </FormItem>
            <div className="btn-container mobile">
              <FormItem>
                <Button
                  type={"btn btn-small"}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Pobierz z KRS
                </Button>
              </FormItem>
            </div>
            <FormItem>
              <Input
                placeholder={"Nazwa firmy (opcjonalnie)"}
                name="shipping_details.company_name"
                error={
                  errors.shipping_details &&
                  errors.shipping_details[
                    "company_name" as keyof typeof errors.shipping_details
                  ]
                }
                validationRules={validationRules.companyName}
                register={register}
              />
            </FormItem>
            <FormItem>
              <Input
                placeholder={"Imię i nazwisko"}
                name="shipping_details.name"
                error={
                  errors.shipping_details &&
                  errors.shipping_details[
                    "name" as keyof typeof errors.shipping_details
                  ]
                }
                validationRules={{
                  ...validationRules.companyName,
                  ...validationBasicRules.required,
                }}
                register={register}
              />
            </FormItem>
            <div className="parcel-select__container">
              <FormItem>
                <div className="select">
                  <select
                    {...register("shipping_details.country_code", {
                      required: { value: true, message: "" },
                    })}
                    name="shipping_details.country_code"
                  >
                    <option value="PL">PL</option>
                    <option value="DE">DE</option>
                    <option value="ES">ES</option>
                    <option value="IT">IT</option>
                    <option value="FR">FR</option>
                    <option value="BE">BE</option>
                    <option value="AT">AT</option>
                  </select>
                  <Input
                    placeholder={"Kod pocztowy"}
                    name="shipping_details.postal_code"
                    error={
                      errors.shipping_details &&
                      errors.shipping_details[
                        "postal_code" as keyof typeof errors.shipping_details
                      ]
                    }
                    validationRules={{
                      ...validationBasicRules.required,
                    }}
                    register={register}
                  />
                </div>
              </FormItem>
            </div>
            <FormItem>
              <Input
                placeholder={"Miasto"}
                name="shipping_details.city"
                error={
                  errors.shipping_details &&
                  errors.shipping_details[
                    "city" as keyof typeof errors.shipping_details
                  ]
                }
                validationRules={validationRules.city}
                register={register}
              />
            </FormItem>
            <FormItem>
              <Input
                placeholder={"Ulica i numer domu/mieszkania"}
                name="shipping_details.address"
                error={
                  errors.shipping_details &&
                  errors.shipping_details[
                    "address" as keyof typeof errors.shipping_details
                  ]
                }
                validationRules={validationRules.address}
                register={register}
              />
            </FormItem>
            <FormItem>
              <Input
                placeholder={"E-mail"}
                name="shipping_details.email"
                error={
                  errors.shipping_details &&
                  errors.shipping_details[
                    "email" as keyof typeof errors.shipping_details
                  ]
                }
                validationRules={{
                  ...validationRules.mail,
                  ...validationBasicRules.required,
                }}
                register={register}
              />
            </FormItem>
            <FormItem>
              <Input
                placeholder={"Telefon"}
                name="shipping_details.phone"
                error={
                  errors.shipping_details &&
                  errors.shipping_details[
                    "phone" as keyof typeof errors.shipping_details
                  ]
                }
                validationRules={validationBasicRules.required}
                register={register}
              />
            </FormItem>
            <FormItem>
              <Input
                placeholder={"Podaj hasło"}
                type={"password"}
                name="password"
                error={errors["password"]}
                validationRules={validationRules.password}
                register={register}
              />
            </FormItem>
            <div className="btn-container">
              <FormItem>
                <Button
                  type={"btn"}
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                  disabled={!(address && address.shipping_details)}
                >
                  Anuluj
                </Button>
                <Button type={"primary"} onClick={handleSubmit(onSubmit)}>
                  Zapisz
                </Button>
              </FormItem>
            </div>
          </form>
        </div>
        <div className="side-btn">
          <div className="btn-container">
            <FormItem>
              <Button type={"btn"} onClick={updateWithKRSData}>
                Pobierz z KRS
              </Button>
            </FormItem>
          </div>
        </div>
      </div>
    </div>
  );
}
