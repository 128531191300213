import "../style.scss";

import { useSelector } from "react-redux";
import { logout, profileSelector } from "../reducers/profile";
import { useState } from "react";

import AccountModal from "./AccountModal";
import Button from "./Button";
import FormItem from "./FormItem";
import Logo from "./Logo";
import MenuItem from "./MenuItem";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../hook/reduxHooks";

export default function LoginHeader({ logoColor }: { logoColor?: string }) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { balance, currency } = useSelector(profileSelector);
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);
  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <header className={open ? "loginHeader active" : "loginHeader"}>
      <Logo destination={"dashboard"} color={logoColor} />
      <ul className={open ? "menu-options active" : "menu-options"}>
        <MenuItem
          linkTo="dashboard"
          data-text="Dashboard"
          className="option"
          onClick={closeHandler}
        >
          Dashboard
        </MenuItem>
        <MenuItem
          linkTo="to_send"
          data-text="Do wysłania"
          className="option"
          onClick={closeHandler}
        >
          Do wysłania
        </MenuItem>
        <MenuItem
          linkTo="orders"
          data-text="Zamówienia"
          className="option"
          onClick={closeHandler}
        >
          Zamówienia
        </MenuItem>
        <MenuItem
          linkTo="settings"
          data-text="Ustawienia"
          className="option"
          onClick={closeHandler}
        >
          Ustawienia
        </MenuItem>
        <MenuItem
          linkTo="help"
          data-text="Pomoc"
          className="option"
          onClick={closeHandler}
        >
          Pomoc
        </MenuItem>
        <div className="buttons mobile-option">
          <FormItem
            className={
              balance < 0 ? "option balance negativeBalance" : "option balance"
            }
          >
            {`${balance.toFixed(2)} ${currency}`}
          </FormItem>
          <FormItem>
            <Button
              onClick={() => {
                history.push("/user-data");
                closeHandler();
              }}
              type={"white"}
            >
              Twoje dane
            </Button>
          </FormItem>
          <FormItem>
            <Button
              onClick={() => {
                history.push("/invoice");
                closeHandler();
              }}
              type={"white"}
            >
              Faktury
            </Button>
          </FormItem>
          <FormItem>
            <Button
              onClick={() => {
                history.push("/valuation");
                closeHandler();
              }}
              type={"white"}
            >
              Cennik
            </Button>
          </FormItem>
          <FormItem>
            <Button
              onClick={() => {
                dispatch(logout());
                closeHandler();
                history.push("/landing");
              }}
              type={"logout"}
            >
              Wyloguj
            </Button>
          </FormItem>
        </div>
      </ul>
      <ul className="nav-side">
        <MenuItem
          className={balance < 0 ? "balance negativeBalance" : "balance"}
          linkTo="balance"
          onClick={closeHandler}
        >
          {`${balance && balance.toFixed(2)} ${currency}`}
        </MenuItem>
        <li className="account">
          <AccountModal />
        </li>
      </ul>
      <div
        className={`burger-menu ${open ? "open" : null}`}
        onClick={handleClick}
      >
        <div className="burger-menu__icon"></div>
      </div>
    </header>
  );
}
