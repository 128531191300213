import "../scss/PriceList.scss";
import "../scss/customSelect.scss";

import {
  geShippingToCustomSelectOptions,
  getCountryText,
  getSelectOptions,
  getShippingFromCustomSelectOptions,
  getShippingFromSelectOptions,
} from "../helpers/countries";
import {
  permimeterValidation,
  postalCodeValidation,
  validationBasicRules,
  validationRules,
} from "../helpers/validationRules";
import { useEffect, useState } from "react";

import APIConnector from "../apiConnector";
import Button from "../components/Button";
import Footer from "../components/Footer";
import FormControl from "@material-ui/core/FormControl";
import FormItem from "../components/FormItem";
import Input from "../components/Input";
import InputGroup from "../components/InputGroup";
import InputLabel from "@material-ui/core/InputLabel";
import LoginFooter from "../components/LoginFooter";
import { ReactComponent as QIcon } from "../img-svg/qMark.svg";
import Select from "@material-ui/core/Select";
import { SelectChangeEvent } from "@mui/material";
import math from "../img-svg/weightFormula.png";
import { profileSelector } from "../reducers/profile";
import { saveForm } from "../reducers/parcels";
import trimCalcPayload from "../helpers/trimPayload";
import { FieldValues, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hook/reduxHooks";

export default function PriceList() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const fieldsRequiredForPriceCalc = ["width", "height", "depth", "weight"];
  const connector = new APIConnector();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = useForm({ mode: "all" });
  const [price, setPrice] = useState<number>(0);

  const checkUpdatePrice = (e: SelectChangeEvent<unknown>) => {
    if (e.target.name.includes("country_code")) {
      setValue(e.target.name, e.target.value);
    }
    let formValues = getValues();
    fieldsRequiredForPriceCalc.forEach((field) => {
      if (!(formValues[field] && formValues[field].length > 0)) {
        return;
      }
    });
    if (
      !(
        formValues.shipping_to.country_code &&
        formValues.shipping_to.country_code.length > 0
      )
    ) {
      return;
    }

    if (
      !(
        formValues.shipping_from.country_code &&
        formValues.shipping_from.country_code.length > 0
      )
    ) {
      return;
    }
    let calcValues = trimCalcPayload(formValues);
    calculatePrice(calcValues);
  };

  const calculatePrice = (formData: FieldValues) => {
    connector.calculatePrice(formData).then((res) =>
      res.json().then((data) => {
        setPrice(parseFloat(parseFloat(data.price || 0).toFixed(2)));
      })
    );
  };

  useEffect(() => {
    setValue("shipping_to.country_code", "DE");
    setValue("shipping_from.country_code", "PL");
    let calcValues = trimCalcPayload(getValues());
    calculatePrice(calcValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    dispatch(saveForm(getValues()));
    history.push("/shipment");
  };

  const [information, setInformation] = useState(false);
  const { isAuthenticated } = useSelector(profileSelector);

  const closeInfo = () => setInformation(false);

  const onClickOutsideListener = () => {
    if (information) {
      closeInfo();
      document.removeEventListener("click", onClickOutsideListener);
    }
  };

  // custom select logic start

  const [countryFrom, setCountryFrom] = useState("PL");
  const [openCountryFrom, setOpenCountryFrom] = useState(false);
  const [countryTo, setCountryTo] = useState("DE");
  const [openCountryTo, setOpenCountryTo] = useState(false);

  const handleChangeCountryFrom = (
    event: SelectChangeEvent<unknown>,
    name: string
  ) => {
    setCountryFrom(event.target.value as string);
    setValue(name, event.target.value);
    checkUpdatePrice(event);
  };

  const handleCloseCountryFrom = () => {
    setOpenCountryFrom(false);
  };

  const handleOpenCountryFrom = () => {
    setOpenCountryFrom(true);
  };

  const handleChangeCountryTo = (
    event: SelectChangeEvent<unknown>,
    name: string
  ) => {
    setCountryTo(event.target.value as string);
    setValue(name, event.target.value);
    checkUpdatePrice(event);
  };

  const handleCloseCountryTo = () => {
    setOpenCountryTo(false);
  };

  const handleOpenCountryTo = () => {
    setOpenCountryTo(true);
  };

  // custom select logic end

  return (
    <div id={"price-list"} onClick={onClickOutsideListener}>
      <div className="hero__wrapper">
        <div className="content">
          <main>
            <h1>Cennik</h1>
            <form
              id="sendParcelForm"
              onSubmit={handleSubmit(onSubmit)}
              onChange={(e) =>
                checkUpdatePrice(e as unknown as SelectChangeEvent<unknown>)
              }
            >
              <div className="parcel-select__container">
                <FormItem label={"Nadanie"} helperTxt={"(skąd)"}>
                  <div className="select">
                    <FormControl className="customSelect">
                      <InputLabel htmlFor="open-select" />
                      <Select
                        open={openCountryFrom}
                        onClose={handleCloseCountryFrom}
                        onOpen={handleOpenCountryFrom}
                        value={countryFrom}
                        name="country"
                        id="shipping_from.country_code"
                        onChange={(e) =>
                          handleChangeCountryFrom(
                            e as SelectChangeEvent<unknown>,
                            "shipping_from.country_code"
                          )
                        }
                        inputProps={{
                          id: "open-select",
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {getShippingFromCustomSelectOptions()}
                      </Select>
                    </FormControl>
                    <select
                      {...register("shipping_from.country_code", {
                        required: { value: true, message: "" },
                      })}
                      name="shipping_from.country_code"
                      onChange={(e) =>
                        checkUpdatePrice(
                          e as unknown as SelectChangeEvent<unknown>
                        )
                      }
                    >
                      {getShippingFromSelectOptions()}
                    </select>
                    <Input
                      name="shipping_from.postal_code"
                      register={register}
                      error={errors.shipping_from && errors.shipping_from}
                      validationRules={{
                        ...validationBasicRules.required,
                        validate: (value: string) =>
                          postalCodeValidation(
                            value,
                            watch("shipping_from.country_code")
                          ),
                      }}
                      placeholder={"Kod pocztowy"}
                    />
                  </div>
                </FormItem>
                <FormItem label={"Doręczenie"} helperTxt={"(dokąd)"}>
                  <div className="select">
                    <FormControl className="customSelect">
                      <InputLabel htmlFor="open-select" />
                      <Select
                        open={openCountryTo}
                        onClose={handleCloseCountryTo}
                        onOpen={handleOpenCountryTo}
                        value={countryTo}
                        name="country"
                        id="shipping_to.country_code"
                        onChange={(e) =>
                          handleChangeCountryTo(
                            e as SelectChangeEvent<unknown>,
                            "shipping_to.country_code"
                          )
                        }
                        inputProps={{
                          id: "open-select",
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {geShippingToCustomSelectOptions(
                          watch("shipping_from.country_code")
                        )}
                      </Select>
                    </FormControl>
                    <select
                      {...register("shipping_to.country_code", {
                        required: { value: true, message: "" },
                      })}
                      name="shipping_to.country_code"
                      onChange={(e) =>
                        checkUpdatePrice(
                          e as unknown as SelectChangeEvent<unknown>
                        )
                      }
                    >
                      {getSelectOptions(watch("shipping_from.country_code"))}
                    </select>
                    <Input
                      name="shipping_to.postal_code"
                      register={register}
                      error={errors.shipping_to && errors.shipping_to}
                      validationRules={{
                        ...validationBasicRules.required,
                        validate: (value: string) =>
                          postalCodeValidation(
                            value,
                            watch("shipping_to.country_code")
                          ),
                      }}
                      placeholder={"Kod pocztowy"}
                    />
                  </div>
                </FormItem>
              </div>
              <div className="parcel-properties__container">
                <div className="size">
                  <FormItem label={"Wymiary"} helperTxt={"cm"}>
                    <InputGroup separator={"x"}>
                      <Input
                        register={register}
                        error={errors.width}
                        validationRules={{
                          ...validationRules.width,
                          validate: (value: string) =>
                            permimeterValidation(
                              value,
                              watch("depth"),
                              watch("height")
                            ),
                        }}
                        name="width"
                        placeholder={"Szerokość"}
                        onChange={(e) => checkUpdatePrice(e)}
                        maxLength={3}
                      />
                      <Input
                        register={register}
                        error={errors.depth}
                        validationRules={{
                          ...validationRules.length,
                          validate: (value: string) =>
                            permimeterValidation(
                              value,
                              watch("height"),
                              watch("width")
                            ),
                        }}
                        name="depth"
                        placeholder={"Długość"}
                        onChange={(e) => checkUpdatePrice(e)}
                        maxLength={3}
                      />
                      <Input
                        register={register}
                        error={errors.height}
                        validationRules={{
                          ...validationRules.height,
                          validate: (value: string) =>
                            permimeterValidation(
                              value,
                              watch("depth"),
                              watch("width")
                            ),
                        }}
                        name="height"
                        placeholder={"Wysokość"}
                        onChange={(e) => checkUpdatePrice(e)}
                        maxLength={3}
                      />
                    </InputGroup>
                  </FormItem>
                </div>
                <div className="weight">
                  <FormItem label={"Waga"} helperTxt={"kg"}>
                    <Input
                      name="weight"
                      placeholder={"Waga"}
                      register={register}
                      error={errors.weight}
                      validationRules={validationRules.weight}
                      onChange={(e) => checkUpdatePrice(e)}
                      maxLength={2}
                    />
                  </FormItem>
                </div>
              </div>
              <div
                className="questionMark"
                onClick={(e) => setInformation(!information)}
              >
                <QIcon className="qestionIcon" />
                <p>Sprawdź wymagania dotyczące wymiarów</p>
              </div>
              <FormItem></FormItem>
              <FormItem>
                <div className="btn-container">
                  <Button type={"primary"} onClick={handleSubmit(onSubmit)}>
                    Dalej
                  </Button>
                  <Button
                    type={"white"}
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Dodaj więcej paczek
                  </Button>
                </div>
              </FormItem>
            </form>
          </main>
          <aside className="order__summary hidden-mobile">
            <div
              className={
                information
                  ? "informationActive informationHidden"
                  : "informationHidden"
              }
            >
              <div className="plusButton__icon" onClick={closeInfo}></div>
              <div className="parcelInfo">
                <p>Twoja paczka musi spełniać następujące wymagania:</p>
                <span>waga do 30 kg</span>
                <span>{"obwód + najdłuższy bok < 3m"}</span>
                <span>{"najdłuższy bok < 2m"}</span>
                <span>{"szerokość < 80cm"}</span>
                <span>{"wysokość < 60cm"}</span>
              </div>
            </div>
            <div>
              <p className="upper">
                Do zapłaty <span>(cena brutto zawiera VAT 23%)</span>
              </p>
              <h1>{price}</h1>
              <p>
                <span>GLS Parcel International </span>
              </p>
              <p>
                <span>
                  z {getCountryText(watch("shipping_from.country_code"))} do{" "}
                  {getCountryText(watch("shipping_to.country_code"))}
                </span>
              </p>
              <p>
                <span>
                  przesyłka standardowa
                  <br />
                  {watch("width") ? watch("width") + "cm" : ""}
                  {watch("depth") ? " x " + watch("depth") + "cm" : ""}
                  {watch("height") ? " x " + watch("height") + "cm" : ""}
                </span>
              </p>
              <p>
                <span>
                  waga {watch("weight") ? watch("weight") + " kg" : ""}
                </span>
              </p>
            </div>
          </aside>
        </div>
        <footer className="regulations">
          <div>
            <h4>Waga objętościowa</h4>
            <p>
              Uwaga! Cenę za paczkę ustala się zależnie od wagi brutto paczki
              lub jej wagi objętościowej. <br />
              Waga objętościowa przeliczana jest wg wzoru:
            </p>
            <img src={math} alt="weight formula" />
          </div>
          <div>
            <h4>Przykład użycia</h4>
            <p>
              Jeśli wartość tego współczynnika jest wyższa od wagi brutto paczki
              wyrażonej w kg to stosowana jest cena za wagę objętościową
              <br />
              Przykład Paczka o wymiarach 100 cm x 60 cm x 30 cm waży 4 kg
              <br />
              Waga brutto = 4kg
              <br />
              Waga objętościowa (100x60x30)/6000 = 30 kg
              <br />
              Cena za paczkę zostanie pobrana zgodnie z wagą objętościową.
            </p>
          </div>
        </footer>
      </div>
      {isAuthenticated ? <LoginFooter /> : <Footer />}
    </div>
  );
}
