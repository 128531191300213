import WhyUsCardfooter from "./WhyUsCardFooter";

interface IWhyUsCard {
  type: string;
  bgImg: string;
  headerValue: string;
  textValue: string;
  footerHeader: string;
  footerText: string;
  cardColor: string;
}

export default function WhyUsCard({
  type,
  bgImg,
  headerValue,
  textValue,
  footerHeader,
  footerText,
  cardColor,
}: IWhyUsCard) {
  return (
    <div className={"WhyUsCard" + type}>
      <div className={cardColor + type}>
        <h3>{headerValue}</h3>
        <img
          className={cardColor + "WhyUsCardBgImg"}
          src={bgImg}
          alt="advantage"
        />
        <p>{textValue}</p>
      </div>
      <WhyUsCardfooter
        type="Advantage"
        footerHeader={footerHeader}
        footerText={footerText}
      />
    </div>
  );
}
