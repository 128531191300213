interface IBalance {
  ammount: number;
  balance: number;
  description: string;
  date: string;
}

export default function BalanceRow({
  ammount,
  balance,
  description,
  date,
}: IBalance) {
  return (
    <tr>
      <td>{ammount} PLN</td>
      <td>{balance} PLN</td>
      <td>{description}</td>
      <td className="invoiceData">{date}</td>
    </tr>
  );
}
