import "../../scss/Article.scss";
import { ShareButtons } from "../ShareButtons";
import BlogApiConnector from "../../blogApiConnector";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ISingleArticle } from "./Interfaces";
import SingleArticle from "./SingleArticle";
import TopBarProgress from "react-topbar-progress-indicator";

export default function Article() {
  const blogApiConnector = new BlogApiConnector();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const history = useHistory();
  let [article, setArticle] = useState<ISingleArticle>({
    readingTime: "",
    articleContent: "",
    articleAuthor: "",
    articleImage: "",
    articleTitle: "",
    path: "",
    articleExcerpt: "",
  });
  let [recommendedArticles, setRecommendedArticles] = useState<Array<ISingleArticle>>();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      let res = await blogApiConnector.getPosts()
      let posts = await res.json()
      let mappedPosts = [];
      for await (const el of posts) {
        let image = el._links
        image = await blogApiConnector.get(image);
        mappedPosts.push({
          articleImage: image,
          readingTime: "6 minutes",
          articleTitle: (el as any).title.rendered,
          articleContent: (el as any).content.rendered,
          articleExcerpt: (el as any).excerpt.rendered,
          articleAuthor: "ParcelPlanet",
          path: (el as any).slug,
        })
      }
      let post = mappedPosts.find(
          (el: object) => (el as any).path === (params as any).slug
      );
      if (post) {
        setArticle(post);
      }
      setRecommendedArticles(mappedPosts);
      setIsLoading(false);
    }

    fetchData().catch(console.error);
  }, [params]);

  return (
      <div>
        {isLoading ? <TopBarProgress /> : (
            <div>

              <div className="article__container">
                <article className="main-article">
                  <div className="article-data">
                    <h1 className="article-title">{article && article.articleTitle}</h1>
                    <p className="article-author">
                      autor {article && article.articleAuthor}
                    </p>
                    <p className="article-content">
                      <section
                          dangerouslySetInnerHTML={{
                            __html: article && article.articleContent,
                          }}
                      />
                    </p>
                  </div>
                </article>
              </div>
              <ShareButtons />
              <div className="recomended-article__container">
                <div className="articles-background">
                  <h2>Polecane artykuły</h2>
                  <div className="articles-container">
                    {recommendedArticles?.map((el) => (
                        <SingleArticle {...el} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
        )}
      </div>
  );
}
