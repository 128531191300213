import "../scss/Settings.scss";

import { useSelector } from "react-redux";

import APIConnector from "../apiConnector";
import Button from "../components/Button";
import DesktopModal from "../components/DesktopModal";
import FormItem from "../components/FormItem";
import LoginFooter from "../components/LoginFooter";
import MobileModal from "../components/MobileModal";
import ShopCompany from "../components/ShopCompany";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getAllParcels } from "../reducers/parcels";
import { errorMessage } from "../helpers/notifications";
import { apiErrors } from "../helpers/errors";
import { RootState } from "../hook/reduxHooks";

const ebay_redirect =
  "https://auth.ebay.com/oauth2/authorize?client_id=GETITDON-ParcelPl-PRD-7583977f5-1c841c57&response_type=code&redirect_uri=GET_IT_DONE-GETITDON-Parcel-fnhgxoyc&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly";

const shops = [
  {
    index: 11,
    name: "eBay",
    value: "ebay",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/EBay_logo.svg/1200px-EBay_logo.svg.png",
  },
  {
    index: 22,
    name: "Amazon",
    value: "amazon",

    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/1280px-Amazon_logo.svg.png",
  },
];

export default function Settings() {
  const history = useHistory();
  const errors = useSelector((state: RootState) => state.errors);
  const connector = new APIConnector();
  const { selectedPaymentGate } = useSelector(getAllParcels);

  const integrate = () => {
    if (selectedPaymentGate === 22) {
      history.push("/multi-parcel");
    } else if (selectedPaymentGate === 11) {
      window.location.replace(ebay_redirect);
    }
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let q = params.get("code");
    if (q) {
      connector.sendAccessCodeToEbay(q).then((res) => {
        if (res.status < 300) {
          history.push("/dashboard");
        } else {
          errorMessage(apiErrors.EbayImportError);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div id={"settings"}>
        <div className={"sub-nav__wrapper"}>
          <header className="sub-nav">
            <h1>Ustawienia</h1>
            <div className="btn-container">
              <DesktopModal />
            </div>
            <MobileModal />
          </header>
        </div>
        <main>
          <div className="text-content">
            <h3>Integracje z twoim sklepem</h3>
            <p>
              Ustaw integracje i automatycznie dodawaj przesyłki do wysłania
              (bez przenoszenia danych).
              <br />
              Dodatkowo nie musisz martwić się dodawaniem numerów do śledzenia
              przesyłek - dzieje się to automatycznie.
            </p>
          </div>
          <div className="settings__wrapper">
            <div className="settings-option__container">
              <FormItem>
                {shops.map((shop, id) => {
                  return <ShopCompany {...shop} key={id} />;
                })}
              </FormItem>
            </div>
          </div>
          <div className="finalize-btn">
            <Button type={"primary"} onClick={() => integrate()}>
              Zacznij integrację
            </Button>
          </div>
          {errors.errorMsg && (
            <span className="formItemError">{errors.errorMsg}</span>
          )}
        </main>
        <div className="background" />
      </div>
      <LoginFooter />
    </div>
  );
}
