interface ICard {
  type: string;
  bgImg: string;
  children: any;
}

export default function Card({ type, bgImg, children }: ICard) {
  return (
    <div className={"card " + type}>
      <img className={"cardBgImg"} src={bgImg} alt="customer" />
      {children}
    </div>
  );
}
