import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { FieldValues } from "react-hook-form";

import APIConnector from "../apiConnector";
import { RootState } from "../hook/reduxHooks";
import { IShippingDetails } from "../interfaces/interfaces";

const connector = new APIConnector();

export const login = createAsyncThunk(
  "profile/login",
  async (params: { email: string; password: string }) => {
    const response: any = await connector.authenticate(
      params.email,
      params.password
    );
    return response.json();
  }
);

export const fetchUserData = createAsyncThunk("profile/userData", async () => {
  const response: any = await connector.getUserData();
  return response.json();
});

export const getBalance = createAsyncThunk("profile/getBalance", async () => {
  const response = await connector.getBalance();
  return response.json();
});

export const onboardUser = createAsyncThunk("profile/onboard", async () => {
  const response = await connector.onboardUser();
  return response;
});

export const updateProfile = createAsyncThunk(
  "profile/update",
  async (data: FieldValues) => {
    const response: any = await connector.updateUser(data);
    return response.json();
  }
);

export const profile = createSlice({
  name: "profile",
  initialState: {
    isAuthenticated: localStorage.getItem("at") ? true : false,
    loading: false,
    balance: 0,
    currency: "ZŁ",
    address: JSON.parse(localStorage.getItem("address") || "{}") || {},
    isOnboarded: localStorage.getItem("isOnboarded") ? true : false,
    isActive: localStorage.getItem("isActive") ? true : false,
    isError: {} as SerializedError,
    shipping_addresses: [] as IShippingDetails[],
    pickup_addresses: [] as IShippingDetails[],
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("at");
      localStorage.removeItem("rt");
      localStorage.removeItem("address");
      localStorage.removeItem("form");
      localStorage.removeItem("isOnboarded");
      localStorage.removeItem("isActive");

      state.isAuthenticated = false;
      state.balance = 0;
      state.address = {};
      state.currency = "ZŁ";
      state.isActive = false;
      state.isOnboarded = localStorage.getItem("isOnboarded") ? true : false;
      state.address = {};
    },
    activateUser: (state) => {
      state.isActive = true;
    },
    addShippingAddress: (state, action) => {
      state.shipping_addresses = [
        ...state.shipping_addresses,
        {
          ...action.payload.data,
          user_shipping_address: null,
          user_pickup_address: null,
        },
      ];
    },
    addPickupAddress: (state, action) => {
      state.pickup_addresses = [
        ...state.pickup_addresses,
        {
          ...action.payload.data,
          user_shipping_address: null,
          user_pickup_address: null,
        },
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        localStorage.setItem("at", action.payload.access);
        localStorage.setItem("rt", action.payload.refreshToken);
        state.isAuthenticated = true;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isError = action.error;
        localStorage.removeItem("at");
        localStorage.removeItem("rt");
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        let address = {
          shipping_details: action.payload.shipping_details,
          nip: action.payload.nip,
        };
        state.address = address;
        state.isOnboarded = action.payload.is_onboarded;
        state.isActive = action.payload.is_active;
        state.shipping_addresses = action.payload.shipping_addresses;
        state.pickup_addresses = action.payload.pickup_addresses;
        localStorage.setItem("isOnboarded", action.payload.is_onboarded);
        localStorage.setItem("isActive", action.payload.is_active);

        localStorage.setItem("address", JSON.stringify(address));
      })
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isError = action.error;
        localStorage.removeItem("at");
        localStorage.removeItem("rt");
        localStorage.removeItem("address");
        localStorage.removeItem("isOnboarded");
        localStorage.removeItem("isActive");
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.balance = action.payload.balance;
      })
      .addCase(getBalance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isError = action.error;
        localStorage.removeItem("at");
        localStorage.removeItem("rt");
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.address = action.payload;
        localStorage.setItem("address", JSON.stringify(action.payload));
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.isError = action.error;
        localStorage.removeItem("address");
      })
      .addCase(onboardUser.fulfilled, (state) => {
        state.loading = false;
        state.isOnboarded = true;
        localStorage.setItem("isOnboarded", "true");
      })
      .addCase(onboardUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(onboardUser.rejected, (state, action) => {
        state.loading = false;
        state.isError = action.error;
      });
  },
});
export const { logout, activateUser, addShippingAddress, addPickupAddress } =
  profile.actions;
export const profileSelector = (state: RootState) => state.profile;
export default profile.reducer;
