import "../scss/ContactPerson.scss";

interface IContactPerson {
  id: number;
  avatar: string;
  name: string;
  position: string;
  mobile: string;
  email: string;
}

export default function ContactPerson({
  id,
  avatar,
  name,
  position,
  mobile,
  email,
}: IContactPerson) {
  return (
    <div className="contact-card" key={id}>
      <div className="contact-avatar">
        <img src={avatar} alt="contact avatar" />
      </div>
      <div className="contact-data">
        <h4>{name}</h4>
        <p>{position}</p>
        {mobile === "" ? "" : <a href={`tel:${mobile}`}>{mobile}</a>}
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
  );
}
