import "../scss/Checkbox.scss";
import { UseFormReturn } from "react-hook-form";

import { useState } from "react";
import { handleKeyPress } from "../helpers/form";

interface ICheckbox {
  children?: any;
  selected?: boolean;
  onClick?: (arg0: any) => void;
  id?: string;
  register?: UseFormReturn["register"] | null;
  name: string;
  error?: any;
}

export default function Checkbox({
  children,
  selected,
  onClick,
  id,
  register = null,
  name,
  error,
}: ICheckbox) {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <label className={"container"}>
        {register ? (
          <input
            id={id}
            className={`${checked ? "selected" : ""}`}
            type={"checkbox"}
            checked={selected}
            onClick={onClick}
            {...register(name, {
              required: {
                value: true,
                message: "Musisz zaakceptować regulamin",
              },
            })}
            onKeyPress={handleKeyPress}
          />
        ) : (
          <input
            id={id}
            className={selected ? "selected" : ""}
            type={"checkbox"}
            checked={selected}
            onClick={onClick}
            onChange={() => {}}
            onKeyPress={handleKeyPress}
          />
        )}
        {children}
        <span
          className={`checkmark ${error ? "acceptCheckboxError" : ""}`}
        ></span>
      </label>
    </>
  );
}
