import "../../scss/KnowledgeBase.scss";

import Footer from "../Footer";
import KnowledgeMainArticle from "./KnowledgeMainArticle";
import { articles } from "../../helpers/articles";
import { Link } from "react-router-dom";
import RecommendedArticle from "./KnowledgeRecommendedArticle";
import BlogApiConnector from "../../blogApiConnector";
import { useEffect, useState } from "react";
import articlePic from "../../img-svg/fbaMoviesWide.png";
import thumbail1 from "../../img-svg/fbaMoviesThumbnail.png";
import { ISingleArticle } from "./Interfaces";
import TopBarProgress from "react-topbar-progress-indicator";

export default function KnowledgeBase() {
  const blogApiConnector = new BlogApiConnector();
  const [isLoading, setIsLoading] = useState(true);
  const [featuredArticle, setFeaturedArticle] = useState({} as any);
  const [posts, setPosts] = useState<Array<ISingleArticle>>();

  useEffect(() => {

    const fetchData = async() => {
      let posts = await (await blogApiConnector.getPosts()).json();
      let featured = posts.find((el: object) => (el as any).tags.includes(6));
      let featuredImgLink = await blogApiConnector.get(featured._links);
      setFeaturedArticle({
        articleImage: featuredImgLink,
        thumbnail: `${thumbail1}`,
        readingTime: "6 minut czytania",
        articleTitle: featured.title.rendered,
        articleContent: featured.excerpt.rendered,
        articleAuthor: "ParcelPlanet",
        path: featured.slug,
      });
      let mappedPosts = [];
      for await (const el of posts) {
        let image = el._links
        image = await blogApiConnector.get(image);
        mappedPosts.push({
          articleImage: image,
          readingTime: "6 minutes",
          articleTitle: (el as any).title.rendered,
          articleContent: (el as any).content.rendered,
          articleExcerpt: (el as any).excerpt.rendered,
          articleAuthor: "ParcelPlanet",
          path: (el as any).slug,
        })
      }
      if (mappedPosts) {
        setPosts(mappedPosts)
      setIsLoading(false);
      }

    }
    fetchData().catch(console.error)
  }, []);

  return (
    <div>
 { isLoading ? <TopBarProgress /> : 
    (
      <div id="knowledge-base">
      <div className="knowledge__wrapper">
        <div className="knowledge__header">
          <h1>Baza wiedzy </h1>
          <p>
            W naszej misji dzielenie się wiedzą się kluczowym elementem.
            <br />
            Wierzymy, że wspólny rozwój przynosi zysk dla obu stron.
          </p>
        </div>
        <div className="knowledge__container">
          <main>
            <KnowledgeMainArticle {...featuredArticle} />
          </main>
          <aside>
            <div className="articles-container">
              {posts!.length > 0
                ? posts?.map((post) => (
                    <Link to={`/baza-wiedzy/${(post as ISingleArticle).path}`}>
                      <RecommendedArticle {...(post as ISingleArticle)} />
                    </Link>
                  ))
                : ""}
            </div>
          </aside>
        </div>
      </div>
      <Footer />
    </div>
    )}
    </div>
   
      
    );
}
