import "../../scss/KnowledgeRecommendedArticle.scss";
import { ISingleArticle } from "./Interfaces";
import { useEffect, useState } from "react";
import thumbail1 from "../img-svg/fbaMoviesThumbnail.png";
import BlogApiConnector from "../../blogApiConnector";

const RecommendedArticle = ({
                              articleImage,
                              readingTime,
                              articleTitle,
                              articleAuthor,
                              _links,
                            }: ISingleArticle) => {
  return (
      <article className="recommended-article">
        <div className="article-picture">
          <img src={articleImage} alt="article thumbail" />
        </div>
        <div className="article-data">
          <p className="article-time">{readingTime}</p>
          <a href="/">
            <h1>{articleTitle}</h1>
          </a>
          <p className="article-author">autor {articleAuthor}</p>
        </div>
      </article>
  );
};

export default RecommendedArticle;