import { ShareButtons } from "./ShareButtons";
import { articles } from "../helpers/articles";
import globalSellersThumbnail from "../img-svg/global.png";

export default function GlobalSellers() {
  const article = articles[3];

  return (
    <div id="fbaMovie">
      <div className="article__container">
        <article className="main-article">
          <div className="article-data">
            <p className="article-time">{article.readingTime}</p>
            <h1 className="article-title">{article.articleTitle}</h1>
            <p className="article-author">autor {article.articleAuthor}</p>
            <p className="article-content">
              <section>
                <img
                  src={globalSellersThumbnail}
                  className="normal-size"
                  alt="thumbnail"
                />
                <p>
                  Platforma sprzedażowa e-commerce o nazwie Amazon jest już
                  powszechnie znana od dłuższego czasu, jednak jego działanie
                  potrafi przysporzyć sporo rozterek. Poznając jego tajniki
                  możemy natknąć się na wiele niejasności na tle fizycznego
                  pakowania przesyłek jak i technicznego podejścia do platformy
                  i obsługi konta. Na szczęście stawiając pierwsze kroki na
                  wyżej wspomnianym hegemonie sprzedażowym możemy liczyć na
                  wsparcie doświadczonych już przedsiębiorców którzy parę
                  dobrych lat temu byli w tym samym miejscu co Wy. Dziś te osoby
                  zajęły się szkoleniami w zakresie wiedzy o Amazon, właśnie oni
                  są najlepszym wyborem by nie gubić się po drodze do
                  najlepszych wyników sprzedażowych. Mowa o naszym partnerze{" "}
                  <strong>Global Sellers</strong> których wyróżnia rzeczowe i
                  praktyczne podejście do klienta. Polecamy zapoznać się z
                  ogromem wiedzy zamieszczonym na ich stronie w postaci wpisów i
                  bezpłatnych webinarów. W razie potrzeby indywidualnej pomocy z
                  prowadzeniem konta oferują również konsultacje, szkolenia i
                  kursy w tym zakresie. Dodatkowo dowiesz się jak:
                  <ul>
                    <li>wysyłać paczki na FBA i FBM</li>
                    <li>jak przygotować się z wysyłką do Niemiec</li>
                    <li>
                      na co uważać przy wysyłaniu przesyłki do Francji czy
                      Hiszpanii
                    </li>
                    <li>czy warto wysyłać przesyłki do Włoch</li>
                  </ul>
                  <br />
                  Już dziś poznaj Amazon i buduj swoją niezależność finansową z
                  pomocą ekspertów z{" "}
                  <a href="https://globalsellers.pro">
                    https://globalsellers.pro
                  </a>
                </p>
              </section>
            </p>
          </div>
        </article>
      </div>
      <ShareButtons />
      <div className="recomended-article__container">
        <div className="articles-background">
          <h2>Polecane artykuły</h2>
          <div className="articles-container">
          </div>
        </div>
      </div>
    </div>
  );
}
