import { useEffect, useState } from "react";
import "../scss/BalanceTable.scss";

import BalanceRow from "./BalanceRow";
import APIConnector from "../apiConnector";

import { BalanceDataType, BalanceHistoryType } from "../interfaces/interfaces";

export default function BalanceTable() {
  const connector = new APIConnector();
  const [balanceData, setBalanceData] = useState(
    [] as unknown as BalanceDataType[]
  );

  useEffect(() => {
    connector
      .getBalanceHistory()
      .then((res) => res.json())
      .then((data) => {
        let tmp = [] as unknown as BalanceDataType[];
        let prevBalance = 0;
        let sortedData: BalanceHistoryType[] = data.results
          .sort(function (a: BalanceHistoryType, b: BalanceHistoryType) {
            return (
              new Date(b.created).valueOf() - new Date(a.created).valueOf()
            );
          })
          .reverse();

        for (var i = 0; i < sortedData.length; i++) {
          let description =
            (sortedData[i].message === "Payment complete"
              ? "Płatność za zamówienie"
              : "Stworzono zamówienie") + ` ${sortedData[i].id}`;
          let diff = sortedData[i].diff;
          let balance = prevBalance + diff;

          prevBalance = balance;
          tmp.push({
            ammount: diff,
            balance,
            description,
            date: new Date(sortedData[i].created).toLocaleDateString(),
            invoicePath: sortedData[i].invoice_storage_path,
          });
        }

        setBalanceData(tmp.reverse());
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <table>
      <tr>
        <th>ILE</th>
        <th>SALDO PO OPERACJI</th>
        <th>ZA CO</th>
        <th>KIEDY</th>
      </tr>
      {balanceData.map((balance) => {
        return <BalanceRow {...balance} />;
      })}
    </table>
  );
}
