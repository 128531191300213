import { FieldValues } from "react-hook-form";

export default function trimCalcPayload(formValues: FieldValues) {
  let calcPayload = JSON.parse(JSON.stringify(formValues));
  calcPayload["shipping_to_cc"] = formValues.shipping_to["country_code"];
  calcPayload["shipping_from_cc"] = formValues.shipping_from["country_code"];
  delete calcPayload["shipping_to"];
  delete calcPayload["shipping_from"];
  return calcPayload;
}
