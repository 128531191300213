import "../scss/ShareButtons.scss";

import Button from "./Button";
import { useState } from "react";

export const ShareButtons = () => {
  const [copied, setCopied] = useState(false);
  const url = window.location.href;

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          setCopied(true);
        })
        .catch(() => {
          alert("Nie udało się skopiować");
        });
    }
  };

  function shareFB() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url),
      "facebook-share-dialog",
      "width=626,height=436"
    );
  }
  function shareTwit() {
    window.open(
      "https://twitter.com/intent/tweet?url=" +
        encodeURIComponent(url) +
        "&text=",
      "twitter-share-dialog",
      "width=626,height=436"
    );
  }
  return (
    <div className="share__container">
      <p>Podobał Ci się artykuł? Uważasz, że daje wartość? - Udostępnij:</p>
      <div className="share-buttons">
        <Button onClick={(url) => shareFB()} type={"facebook-dark"}>
          Facebook
        </Button>
        <Button onClick={(url) => shareTwit()} type={"twitter"}>
          Twitter
        </Button>
        <Button onClick={copyToClipboard} type="copy">
          {!copied ? "Skopiuj link" : "Skopiowany!"}
        </Button>
      </div>
    </div>
  );
};
