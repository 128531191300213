import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const errorMessage = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
    className: "toast",
  });
};

export const successMessage = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
    className: "toast",
  });
};

export const infoMessage = (message: string) => {
  toast.info(message, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
    className: "toast",
  });
};
