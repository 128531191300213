export const scrollHandler = (
  currentLocation: string,
  destination: string | undefined
) => {
  if (currentLocation === destination) {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  } else {
    window.scroll({ top: 0, left: 0 });
  }
};
