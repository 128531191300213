import "../scss/ShipmentData.scss";
import RadioBox from "./RadioBox";
import React, { useState } from "react";

interface IShipmentData {
  index: string;
  day: string;
  date: string;
}

export default function ShipmentData({ index, day, date }: IShipmentData) {
  const handleDateClick = (e: React.MouseEvent<HTMLElement>) => {
    localStorage.setItem("dispatch_date", date);
  };

  return (
    <div className={"shipment-card"}>
      <div className="shipment-data">
        <span>{day}</span>
        <span> ({date})</span>
        <RadioBox
          index={index}
          name="collectionPickUp"
          onClick={handleDateClick}
        />
      </div>
    </div>
  );
}
