import "./style.scss";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import {
  fetchOrders,
  fetchParcels,
  fetchParcelsToSend,
} from "./reducers/parcels";
import { fetchUserData, getBalance, profileSelector } from "./reducers/profile";
import { useSelector } from "react-redux";

import Article from "./components/Blog/Article";
import BalanceHistory from "./views/BalanceHistory";
import Carousel from "./views/Carousel";
import Contact from "./views/Contact";
import Dashboard from "./views/Dashboard";
import Footer from "./components/Footer";
import GLSInstruction from "./components/GLSInstruction";
import Header from "./components/Header";
import Invoice from "./views/Invoice";
import KnowledgeBase from "./components/Blog/KnowledgeBase";
import Landing from "./views/Landing";
import Login from "./views/Login";
import LoginHeader from "./components/LoginHeader";
import LoginHelp from "./views/LoginHelp";
import MultiParcel from "./views/MultiParcel";
import Orders from "./views/Orders";
import ParcelDetails from "./views/ParcelDetails";
import PasswordReminder from "./views/PasswordReminder";
import PriceList from "./views/PriceList";
import PrivateRoute from "./components/PrivateRoute";
import Register from "./views/Register";
import RegisterCorrect from "./components/RegisterCorrect";
import ScrollToTop from "./hook/ScrollToTop";
import Settings from "./views/Settings";
import Shipment from "./views/Shipment";
import ShipmentFinalization from "./components/ShipmentFinalization";
import ToSend from "./views/ToSend";
import TopBarProgress from "react-topbar-progress-indicator";
import UserData from "./views/UserData";
import RegisterCorrectActivated from "./components/RegisterCorrectActivated";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import GlobalSellers from "./components/GlobalSellers";
import { RootState, useAppDispatch } from "./hook/reduxHooks";
import ChangePassword from "./views/ChangePassword";
import OrderConfirmed from "./components/OrderConfirmed";

const App = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useSelector(profileSelector);
  const profileLoading = useSelector(
      (state: RootState) => state.parcels.loading
  );
  const parcelsLoading = useSelector(
      (state: RootState) => state.parcels.loading
  );

  TopBarProgress.config({
    barColors: {
      0: "#7974ff",
      1: "#7974ff",
    },
    shadowBlur: 5,
  });

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserData()).then(() => {
        dispatch(getBalance()).then(() => {
          dispatch(fetchParcels()).then(() => {
            dispatch(fetchParcelsToSend()).then(() => {
              dispatch(fetchOrders());
            });
          });
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
      <Router>
        <div className="app">
          {(parcelsLoading || profileLoading) && <TopBarProgress />}
          <ToastContainer autoClose={3000} />
          <Switch>
            <PrivateRoute path="/order/:id">
              <LoginHeader />
              <ParcelDetails />
            </PrivateRoute>
            <PrivateRoute path="/parcel/:id/edit">
              <Shipment />
            </PrivateRoute>
            <PrivateRoute path="/shipment">
              <Shipment />
            </PrivateRoute>
            <PrivateRoute path="/user-data">
              <UserData />
            </PrivateRoute>
            <Route path="/contact">
              <Header />
              <Contact />
            </Route>
            <PrivateRoute path="/onboarding">
              <LoginHeader />
              <Carousel />
            </PrivateRoute>
            <Route path="/register/correct">
              <LoginHeader />
              <RegisterCorrect />
            </Route>
            <Route path="/user-activation">
              <LoginHeader />
              <RegisterCorrectActivated />
            </Route>
            <Route path="/register">
              <Header />
              <Register />
            </Route>
            <Route path="/remind">
              <Header />
              <PasswordReminder />
            </Route>
            <Route path="/password-reset">
              <Header />
              <ChangePassword />
            </Route>
            <PrivateRoute path="/multi-parcel">
              <LoginHeader />
              <MultiParcel />
            </PrivateRoute>
            <PrivateRoute path="/balance">
              <LoginHeader />
              <BalanceHistory />
            </PrivateRoute>
            <PrivateRoute path="/invoice">
              <LoginHeader />
              <Invoice />
            </PrivateRoute>
            <Route path="/login">
              <Header />
              <Login />
            </Route>
            <PrivateRoute path="/valuation">
              <LoginHeader />
              <PriceList />
            </PrivateRoute>
            <PrivateRoute path="/help">
              <LoginHeader />
              <LoginHelp />
            </PrivateRoute>
            <PrivateRoute path="/settings">
              <LoginHeader />
              <Settings />
            </PrivateRoute>
            <PrivateRoute path="/orders">
              <LoginHeader />
              <Orders />
            </PrivateRoute>
            <Route path="/order-confirmed/:id" exact={true}>
              <LoginHeader />
              <OrderConfirmed />
            </Route>
            <PrivateRoute path="/payments" exact={true}>
              <ShipmentFinalization />
            </PrivateRoute>
            <PrivateRoute path="/payments/:id" exact={true}>
              <ShipmentFinalization />
            </PrivateRoute>
            <PrivateRoute path="/to_send">
              <ScrollToTop />
              <LoginHeader />
              <ToSend />
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
              <LoginHeader />
              <Dashboard />
            </PrivateRoute>
            <Route path="/baza-wiedzy/:slug">
              <Header />
              <Article />
              <Footer />
            </Route>
            <Route path="/knowledge/gls-instruction">
              <ScrollToTop />
              <Header />
              <GLSInstruction />
              <Footer />
            </Route>

            <Route path="/knowledge/global-sellers">
              <ScrollToTop />
              <Header />
              <GlobalSellers />
              <Footer />
            </Route>
            <Route path="/knowledge">
              <Header />
              <KnowledgeBase />
            </Route>
            <Route path="/price">
              <Header />
              <PriceList />
            </Route>
            <Route path="/">
              <Header />
              <Landing />
            </Route>
          </Switch>
        </div>
      </Router>
  );
};

export default App;
