export const statuses = {
  pl: {
    ALL: "Wszystkie",
    UNKNOWN: "Nieznany",
    PLANNEDPICKUP: "Zaplanowany odbiór",
    INPICKUP: "W odbiorze",
    INWAREHOUSE: "W magazynie",
    INDELIVERY: "W dostawie",
    DELIVERED: "Dostarczona",
    ACTION_REQUIRED: "Wymaga działania",
    WAITING_FOR_PICKUP: "Oczekuje nadania",
    IN_PROGRESS: "W trakcie",
    PAYMENT_REQUIRED: "Do opłacenia",
    CANCELED: "Anulowana",
    INTRANSIT: "W tranzycie",
    PREADVICE: "Oczekuje nadania",
  },
};

export const errorStatuses = [
  "UNKNOWN",
  "PAYMENT_REQUIRED",
  "ACTION_REQUIRED",
  "CANCELED",
];
export const successStatuses = ["DELIVERED"];
