import "../scss/Logo.scss";

import { Link } from "react-router-dom";
import logo from "../img-svg/Logo.svg";
import logoWhite from "../img-svg/Logo_white.svg";
import { scrollHandler } from "../hook/scrollHandler";
import smoothscroll from "smoothscroll-polyfill";
import { useLocation } from "react-router-dom";

interface ILogo {
  destination: string;
  color?: string;
}

export default function Logo({ destination, color }: ILogo) {
  smoothscroll.polyfill();
  const location = useLocation();
  const currentLocation = location.pathname.split("/");

  const smoothScrollHandler = () => {
    scrollHandler(currentLocation[1], destination);
  };
  return (
    <Link to={`/${destination}`} onClick={smoothScrollHandler}>
      <div className="logos">
        <img
          src={color === "white" ? logoWhite : logo}
          id="appLogo"
          alt="logo"
        />
      </div>
    </Link>
  );
}
