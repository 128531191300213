import articlePic from "../img-svg/fbaMoviesWide.png";
import articlePic2 from "../img-svg/fbaInstWide.png";
import glsInstructionPhoto from "../img-svg/instructionPhoto.png";
import thumbail1 from "../img-svg/fbaMoviesThumbnail.png";
import thumbail2 from "../img-svg/fbaInstThumbnail.png";
import globalSellersThumbnail from "../img-svg/global.png";
import invoicingThumbnail from "../img-svg/invoicing.png";
import outsourcingThumbnail from "../img-svg/outsourcing.png";
import packingThumbnail from "../img-svg/scissors.png";
import deliveryThumbnail from "../img-svg/delivery.jpg";

export const articles = [
  {
    articleImage: `${articlePic}`,
    thumbnail: `${thumbail1}`,
    readingTime: "6 minut czytania",
    articleTitle: "Filmy instruktażowe dotyczące pakowania FBA",
    articleContent:
      "Podczas wysyłania zapasów do centrów logistycznych Amazon ważne jest, aby przestrzegaćwytycznych dotyczących pakowania. Obejrzyj wideo, aby dowiedziećsię, jak pakować w celu zapewnienia bezpieczeństwa i wydajności.",
    articleAuthor: "amazon.com",
    path: "fba-movies",
  },
  {
    articleImage: `${articlePic2}`,
    thumbnail: `${thumbail2}`,
    readingTime: "5 minut czytania",
    articleTitle: "Jak spakować pudełko do Amazon FBA??",
    articleContent:
      "Wszystko co powinniście wiedzieć o tym jak prawidłowo spakować swoją paczkę zgodnie z wytycznymi, aby uniknąć wszelkich problemów i nieprzyjemności!",
    articleAuthor: "amazon.com",
    path: "how-to-pack",
  },
  {
    articleImage: `${outsourcingThumbnail}`,
    thumbnail: `${outsourcingThumbnail}`,
    readingTime: "6 minut czytania",
    articleTitle: "Outsourcing - opcja czy konieczność?",
    articleContent:
      "Outsourcing czyli z angielskiego - outside-resource-using. Czy to pojęcie jest wam znane? A może kompletnie nowe ? Jest też duża szansa że korzystacie z usługi jaką jest wyżej wspomniany outsourcing nawet o tym nie wiedząc...",
    articleAuthor: "ParcelPlanet",
    path: "outsourcing-opcja-czy-koniecznosc",
  },
  {
    articleImage: `${articlePic2}`,
    thumbnail: `${globalSellersThumbnail}`,
    readingTime: "3 minuty czytania",
    articleTitle:
      "Jak zacząć przygodę z Amazon? Stań się przedsiębiorcą z krwi i kości!",
    articleContent:
      "Platforma sprzedażowa e-commerce o nazwie Amazon jest już powszechnie znana od dłuższego czasu, jednak jego działanie potrafi przysporzyć sporo rozterek...",
    articleAuthor: "ParcelPlanet",
    path: "global-sellers",
  },
  {
    articleImage: `${invoicingThumbnail}`,
    thumbnail: `${invoicingThumbnail}`,
    readingTime: "3 minuty czytania",
    articleTitle: "Inny adres do faktury, a do odbioru przez kuriera",
    articleContent:
      "Posiadacie siedzibę firmy w innym miejscu niż magazyn? Korzystacie z usług prepcentre? Czy może wysyłacie paczki z różnych miejsc?...",
    articleAuthor: "ParcelPlanet",
    path: "inny-adres-faktury-odbioru",
  },

  {
    articleImage: `${glsInstructionPhoto}`,
    thumbnail: `${thumbail2}`,
    readingTime: "3 minuty czytania",
    articleTitle: "Instrukcja przygotowania paczki GLS",
    articleContent:
      "Dzięki tej instrukcji zapakujesz swoją paczkę tak, aby dotarła bezpiecznie do odbiorcy. Oczywiście każda paczka jest bardzo ważna i każdą szanujemy, jednak pomóż nam właściwie o nią zadbać, abyś mógł czerpać radość z szybkiej i sprawnej obsługi.",
    articleAuthor: "GLS",
    path: "gls-instruction",
  },
  {
    articleImage: `${packingThumbnail}`,
    thumbnail: `${packingThumbnail}`,
    readingTime: "8 minut czytania",
    articleTitle:
      "Czy wiesz co Ci się przyda w pakowaniu przesyłek kurierskich?",
    articleContent:
      "Raczej oczywistą rzeczą jest to że odpowiednie zapakowanie paczki przekazanej w kolejnym etapie kurierowi jest niezwykle ważne. W końcu w grę wchodzi stan przesyłanej zawartości paczki, a chyba każdemu powinno zależeć by przesyłany...",
    articleAuthor: "GLS",
    path: "czy-wiesz-co-przyda-sie-przy-pakowaniu",
  },
  {
    articleImage: `${deliveryThumbnail}`,
    thumbnail: `${deliveryThumbnail}`,
    readingTime: "3 minuty czytania",
    articleTitle: "Broker, czy firma kurierska?",
    articleContent:
      "Nadając paczki w kraju oraz za granicą stajemy przez pytaniem jak zorganizować wysyłkę danego towaru? Z jakich usług skorzystać by Twoja przesyłka była bezpieczna oraz by jej cena była atrakcyjna.",
    articleAuthor: "ParcelPlanet",
    path: "broker-czy-firma-kurierska",
  },
];
