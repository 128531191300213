import "../scss/Carousel.scss";

import React, { useEffect, useState } from "react";

import Button from "../components/Button";
import Slide from "../components/Slide";
import macbook from "../img-svg/macbook.png";
import saly1 from "../img-svg/Saly-1.png";
import saly2 from "../img-svg/Saly-2.png";
import { useHistory } from "react-router-dom";
import { onboardUser } from "../reducers/profile";
import { useAppDispatch } from "../hook/reduxHooks";
const slides = [
  {
    index: 1,
    intro: "WPROWADZENIE",
    header: "Nadaj jedną lub wiele przesyłek",
    text: "Uzupłnij formularz i nadaj przesyłkę. Uzupełnij dane swojej firmy i zacznij nadawać wysyłki. Wyslaliśmy do Ciebie mail - prosze potwierdź rejestrację.",
    img: `${macbook}`,
  },
  {
    index: 2,
    intro: "WPROWADZENIE",
    header: "Integracja z Etsy, eBay, Amazon i inne",
    text: "Uzupłnij formularz i nadaj przesyłkę. Uzupełnij dane swojej firmy i zacznij nadawać wysyłki. Wyslaliśmy do Ciebie mail - prosze potwierdź rejestrację.",
    img: `${saly1}`,
  },
  {
    index: 3,
    intro: "WPROWADZENIE",
    header: "Ustaw powiadomienia i bądź na bieżąco",
    text: "Uzupłnij formularz i nadaj przesyłkę. Uzupełnij dane swojej firmy i zacznij nadawać wysyłki. Wyslaliśmy do Ciebie mail - prosze potwierdź rejestrację.",
    img: `${saly2}`,
  },
];

export default function Carousel() {
  const history = useHistory();
  const [slideIndex, setSlideIndex] = useState(0);
  const dispatch = useAppDispatch();

  const nextSlide = () => {
    setSlideIndex((old) => (old + 1 + slides.length) % slides.length);
  };
  const newSlide = () => {
    setSlideIndex((old) => old + 1);
  };

  useEffect(() => {
    let intervalId = setInterval(nextSlide, 7000);

    return () => {
      clearInterval(intervalId);
    };
  });

  const slide = slides.find((slide, index) => index === slideIndex);
  const handleOnboardUser = async (e: MouseEvent) => {
    e.preventDefault();

    history.push("/");
    const result = await dispatch(onboardUser());
    if (onboardUser.fulfilled.match(result)) {
      history.push("/");
    } else {
      if (result.error.code === "400") {
        history.push("/");
      }
    }
  };
  const nextStep = () => {
    if (slide && slide.index !== 3) {
      return (
        <div className="btn-container">
          <Button onClick={handleOnboardUser} type={"btn"}>
            Pomiń
          </Button>
          <Button onClick={() => newSlide()} type={"primary"}>
            Dalej
          </Button>
        </div>
      );
    } else {
      return (
        <div className="btn-container">
          <Button onClick={handleOnboardUser} type={"primary"}>
            Zacznij
          </Button>
        </div>
      );
    }
  };

  return (
    <div id="carousel">
      <div className="carousel__container">
        <div className="carousel__container">
          {slide && <Slide key={slideIndex.toString()} {...slide} />}
        </div>
      </div>
      <div className="carousel__control">
        {slides.map((slide, index) => {
          const className = `dot ${
            index === slideIndex ? "dot-active" : ""
          }`.trim();
          return (
            <span
              key={index}
              className={className}
              onClick={() => setSlideIndex(index)}
            ></span>
          );
        })}
      </div>
      {nextStep()}
      <div className="backgroundGradient" />
    </div>
  );
}
