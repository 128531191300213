import "../scss/textarea.scss";

import { handleKeyPress } from "../helpers/form";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormReturn,
} from "react-hook-form";

interface ITextArea {
  placeholder: string;
  type: string;
  updateHandler?: () => void;
  value?: string;
  name: string;
  register: UseFormReturn["register"];
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  validationRules: any;
  id?: string;
  maxLength: number;
  onChange?: () => void;
}

export default function TextArea({
  placeholder,
  type,
  updateHandler,
  value,
  name,
  register,
  error,
  validationRules,
  id,
  maxLength,
  onChange,
}: ITextArea) {
  return (
    <div className="textAreaWrapper">
      <div className="textAreaBox">
        {register ? (
          <textarea
            className={error && "invalid"}
            {...register(name, { ...validationRules })}
            onKeyUp={updateHandler}
            id={id}
            maxLength={maxLength}
            onKeyPress={handleKeyPress}
            placeholder=" "
            rows={8}
            wrap="soft"
          />
        ) : (
          <textarea
            onKeyUp={updateHandler}
            value={value}
            name={name}
            id={id}
            maxLength={maxLength}
            onChange={onChange}
            placeholder=" "
            rows={8}
            wrap="soft"
          />
        )}
        <span>{placeholder}</span>
      </div>
      {error && <small className="errorMsg">{`${error.message}`}</small>}
    </div>
  );
}
