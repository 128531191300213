import { FieldValues } from "react-hook-form";

const API_URL = process.env.REACT_APP_API_URL;

const apiCall = (
  method: string,
  endpoint: string,
  headers: HeadersInit,
  body: undefined | BodyInit = undefined
) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/${endpoint}`, {
      method: method,
      body: body,
      headers: headers,
    })
      .then((res) => {
        if (res.status < 300) {
          resolve(res);
        } else {
          return res.json();
        }
      })
      .then((body) => {
        reject(body.detail);
      })
      .catch((res) => {
        reject(res);
      });
  });
};

export default class APIConnector {
  headers: HeadersInit;

  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  getHeaders() {
    return {
      ...this.headers,
      Authorization: "Bearer " + localStorage.getItem("at"),
    };
  }

  setAuthHeader(token: string) {
    this.headers = {
      ...this.headers,
      Authorization: "Bearer " + token,
    };
  }
  authenticate(email: string, password: string) {
    return apiCall(
      "POST",
      "auth/token/",
      this.headers,
      JSON.stringify({ email: email, password: password })
    );
  }

  registerUser(email: string, password: string) {
    return fetch(`${API_URL}/users/register/`, {
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
      headers: this.headers,
    });
  }

  sendContactMessage(data: any) {
    return fetch(`${API_URL}/mails/contact/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: this.getHeaders(),
    });
  }

  uploadAmazonOutputFile(data: any) {
    return fetch(`${API_URL}/parcels/upload-file/`, {
      method: "POST",
      mode: "cors",
      body: data,
      headers: { Authorization: "Bearer " + localStorage.getItem("at") },
    });
  }

  getParcels(toSend = false) {
    return apiCall(
      "GET",
      `parcels${toSend ? "/?to_send=true&limit=1000" : "?limit=1000"}`,
      this.getHeaders()
    );
  }

  sendParcelsToGLS(parcelsIds: string[]) {
    return fetch(`${API_URL}/parcels/send-to-gls/`, {
      method: "POST",
      body: JSON.stringify({ ids: parcelsIds }),
      headers: this.getHeaders(),
    });
  }

  deleteParcel(id: string) {
    return apiCall("DELETE", `parcels/${id}/`, this.getHeaders());
  }

  sendAccessCodeToEbay(code: string) {
    return fetch(`${API_URL}/ebay/`, {
      method: "POST",
      body: JSON.stringify({ code: code }),
      headers: this.getHeaders(),
    });
  }

  createOrder(parcelsIds: string[]) {
    return fetch(`${API_URL}/orders/create-order/`, {
      method: "POST",
      body: JSON.stringify({ ids: parcelsIds }),
      headers: this.getHeaders(),
    });
  }

  getOrders() {
    return apiCall("GET", `orders/?limit=1000`, this.getHeaders());
  }

  getOrder(id: string) {
    return fetch(`${API_URL}/orders/${id}/`, {
      method: "GET",
      headers: this.getHeaders(),
    });
  }

  deleteOrder(id: string) {
    return apiCall("DELETE", `orders/${id}/`, this.getHeaders());
  }

  getParcelDetails(parcelId: string) {
    return fetch(`${API_URL}/parcels/${parcelId}/`, {
      method: "GET",
      headers: this.getHeaders(),
    });
  }

  calculatePrice(shipmentForm: FieldValues) {
    return fetch(`${API_URL}/price/calculate/`, {
      method: "POST",
      body: JSON.stringify(shipmentForm),
      headers: this.getHeaders(),
    });
  }

  getUserData() {
    return apiCall("GET", "users/me/", this.getHeaders());
  }

  updateUser(data: any) {
    return apiCall(
      "PATCH",
      "users/me/",
      this.getHeaders(),
      JSON.stringify(data)
    );
  }

  updateParcel(data: any, parcelId: string) {
    return fetch(`${API_URL}/parcels/${parcelId}/`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: this.getHeaders(),
    });
  }

  sendParcel(data: any) {
    return apiCall("POST", `parcels/`, this.getHeaders(), JSON.stringify(data));
  }

  confirmPayment(secret: string) {
    return fetch(`${API_URL}/payments/verify-payment/`, {
      method: "POST",
      body: JSON.stringify({ secret: secret }),
      headers: this.getHeaders(),
    });
  }

  getBalance() {
    return fetch(`${API_URL}/balance/`, {
      method: "GET",
      headers: this.getHeaders(),
    });
  }

  getBalanceHistory() {
    return fetch(`${API_URL}/balance/history/`, {
      method: "GET",
      headers: this.getHeaders(),
    });
  }

  activateUser(token: string) {
    return fetch(`${API_URL}/users/activate-user/`, {
      method: "POST",
      body: JSON.stringify({ activation_token: token }),
      headers: this.headers,
    });
  }

  getDataFromKRS(nip: string) {
    return fetch(`${API_URL}/users/nip-data/${nip}/`, {
      method: "GET",
      headers: this.getHeaders(),
    });
  }

  payWithStripe(orders: any) {
    return fetch(`${API_URL}/payments/stripe/`, {
      method: "POST",
      body: JSON.stringify({ ids: orders }),
      headers: this.getHeaders(),
    });
  }

  payWithPaypal(orders: any) {
    return fetch(`${API_URL}/payments/paypal/`, {
      method: "POST",
      body: JSON.stringify({ ids: orders }),
      headers: this.getHeaders(),
    });
  }

  getAllInvoices() {
    return fetch(`${API_URL}/invoices/`, {
      method: "GET",
      headers: this.getHeaders(),
    });
  }

  downloadInvoice(invoicePath: string) {
    return fetch(`${API_URL}/invoices/user/${invoicePath}`, {
      headers: this.getHeaders(),
    });
  }

  onboardUser() {
    return apiCall("POST", `users/onboard-user/`, this.getHeaders());
  }

  saveAddress(data: any) {
    return apiCall(
      "POST",
      "parcels/save-address/",
      this.getHeaders(),
      JSON.stringify(data)
    );
  }

  savePickupAddress(data: any) {
    return apiCall(
      "POST",
      "parcels/save-pickup-address/",
      this.getHeaders(),
      JSON.stringify(data)
    );
  }

  requestResetPasswordMail(email: string) {
    return apiCall(
      "POST",
      "users/password-reset/",
      this.headers,
      JSON.stringify({ email: email })
    );
  }

  confirmResetPassword(password: string, token: string) {
    return apiCall(
      "POST",
      "users/password-reset/confirm/",
      this.headers,
      JSON.stringify({ password: password, token: token })
    );
  }

  dispatchOrder(secret: string, dispatchDate?: string) {
    let body = dispatchDate
      ? { secret: secret, pick_up_date: dispatchDate }
      : { secret: secret };
    return fetch(`${API_URL}/orders/dispatch-order/`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: this.getHeaders(),
    });
  }
}
