import "../scss/Register.scss";

import { Link, useHistory } from "react-router-dom";

import APIConnector from "../apiConnector";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import { ReactComponent as EyeIcon } from "../img-svg/eye.svg";
import FormItem from "../components/FormItem";
import Input from "../components/Input";
import blueDrop from "../img-svg/shapes/blueDrop.png";
import greenBall from "../img-svg/shapes/greenBall.png";
import greenRect from "../img-svg/shapes/greenRect.png";
import { login } from "../reducers/profile";
import pinkCircle from "../img-svg/shapes/pinkCircle.png";
import pinkShape1 from "../img-svg/shapes/pinkShape1.png";
import pinkShape2 from "../img-svg/shapes/pinkShape2.png";
import policy from "../docs/policy.pdf";
import statute from "../docs/statute.pdf";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { validationRules } from "../helpers/validationRules";
import whiteCube from "../img-svg/shapes/whiteBox2.png";
import whiteFig from "../img-svg/shapes/whiteBox1.png";
import { errorMessage } from "../helpers/notifications";
import { apiErrors } from "../helpers/errors";
import { useAppDispatch } from "../hook/reduxHooks";

export default function Register() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const connector = new APIConnector();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({ mode: "all" });

  const [passwordVisibility, setPasswordVisibility] = useState(true);

  const passwordVisibilityHandler = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const onSubmit = () => {
    let email: string = getValues().email;
    let password: string = getValues().password;
    connector.registerUser(email, password).then((res) => {
      if (res.status === 201) {
        dispatch(login({ email: email, password: password })).then(() => {
          history.push("/register/correct");
        });
      } else {
        res.json().then((body) => {
          if (body.email[0]) {
            errorMessage(apiErrors.WrongEmailUserRegistrationError);
          } else {
            errorMessage(apiErrors.UserRegistrationError);
          }
        });
      }
    });
  };

  return (
    <div id={"register__wrapper"}>
      <section className={"register"}>
        <h1>Założ konto</h1>
        <form id="register-form" onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Input
              register={register}
              error={errors.email}
              name="email"
              validationRules={validationRules.mail}
              placeholder={"Email"}
            />
          </FormItem>
          <FormItem>
            <div className="passwordWrapper">
              <Input
                placeholder={"Hasło"}
                type={passwordVisibility ? "password" : "text"}
                register={register}
                error={errors.password}
                name="password"
                validationRules={validationRules.password}
              />
              <EyeIcon
                className={
                  !passwordVisibility
                    ? "eyeIcon"
                    : "eyeIcon semi" + (errors.password ? " with-error" : "")
                }
                onClick={passwordVisibilityHandler}
              />
            </div>
          </FormItem>
          <FormItem>
            <Checkbox
              register={register}
              name="acceptPolicy"
              error={errors.acceptPolicy}
            />
            <p>
              Akceptuję{" "}
              <a href={statute} target="_blank" rel="noreferrer">
                regulamin
              </a>{" "}
              oraz{" "}
              <a href={policy} target="_blank" rel="noreferrer">
                politykę prywatności
              </a>
            </p>
          </FormItem>
          <FormItem>
            <Button onClick={handleSubmit(onSubmit)} type={"primary"}>
              Zacznij
            </Button>
          </FormItem>
        </form>
        <p>
          Masz już konto?
          <Link to="/login"> kliknij i zaloguj się</Link>
        </p>
      </section>

      <div id={"registerBackground"}>
        <div className={"rect"} />
        <img src={greenRect} className={"greenRect"} alt="" />
        <img src={whiteFig} className={"whiteFig"} alt="" />
        <img src={pinkCircle} className={"pinkCircle"} alt="" />
        <img src={greenBall} className={"greenBall"} alt="" />
        <img src={whiteCube} className={"whiteCube"} alt="" />
        <img src={pinkShape2} className={"pinkShape2"} alt="" />
        <img src={pinkShape1} className={"pinkShape1"} alt="" />
        <img src={blueDrop} className={"blueDrop"} alt="" />
      </div>
      <div className="backgroundGradient" />
    </div>
  );
}
