import { getAllParcels, selectParcel } from "../reducers/parcels";
import { useSelector } from "react-redux";

import Checkbox from "./Checkbox";
import Chip from "./Chip";
import { getCountryFlagIcon } from "../helpers/countries";
import locationIcon from "../img-svg/location.svg";
import warningIcon from "../img-svg/warning1.svg";
import { statuses, errorStatuses, successStatuses } from "../helpers/statuses";
import { IShippingDetails } from "../interfaces/interfaces";
import { useAppDispatch } from "../hook/reduxHooks";

interface IOrderRow {
  id: string;
  status: { code: string; message: string };
  shipping_to: IShippingDetails;
  is_paid: boolean;
  sku: string;
  external_order_id: string | null;
  price: number;
  description?: string;
  parcelNumber: string;
}

export default function OrderRow({
  id,
  status,
  shipping_to,
  is_paid,
  sku,
  external_order_id,
  price,
  description,
  parcelNumber,
}: IOrderRow) {
  const dispatch = useAppDispatch();
  let addressTo =
    shipping_to &&
    `${shipping_to.address}, ${shipping_to.postal_code} ${shipping_to.city}`;
  let countryCode = shipping_to && `${shipping_to.country_code}`;
  let parcelSku = (sku && `${sku}`) || (description && `${description}`);
  let receiverName = shipping_to && `${shipping_to.name}`;
  let receiverPhone = shipping_to && `${shipping_to.phone}`;
  let orderId = external_order_id && `${external_order_id}`;
  let parcelPrice = price && `${price}`;
  const { selectedParcels, parcels } = useSelector(getAllParcels);
  let parcelData = parcels.find((parcel) => parcel.id === parcelNumber);
  const trackingUrl =
    parcelData && parcelData.parcel_number
      ? `https://gls-group.eu/PL/pl/sledzenie-paczek?match=${parcelData.parcel_number}`
      : "";
  const error = errorStatuses.includes(status.code);
  const success = successStatuses.includes(status.code);

  return (
    <tr>
      <td className="col">
        <Checkbox
          name=""
          selected={selectedParcels.includes(id) ? true : false}
          onClick={() => dispatch(selectParcel(id))}
        />
      </td>
      <td className="col">
        <span>{id}</span>
      </td>
      <td className="col">
        {parcelData && parcelData.parcel_number ? (
          <span>
            <a href={trackingUrl} target="_blank" rel="noreferrer">
              {parcelData.parcel_number}
            </a>
          </span>
        ) : (
          "Brak numeru przesyłki"
        )}
      </td>
      <td>
        {!is_paid ? (
          <div className="flex-center">
            <Chip type={`small requires`}>NIEOPŁACONE</Chip>
            <img src={warningIcon} className="warningIcon" alt="" />
          </div>
        ) : (
          <div className="flex-center">
            <Chip
              type={`small ${error && "requires"} ${success && "received"}`}
            >
              {statuses.pl[status.code as keyof typeof statuses.pl]}
            </Chip>
            {error && <img src={warningIcon} className="warningIcon" alt="" />}
          </div>
        )}
      </td>
      <td className="col">
        <span>{receiverName}</span>
      </td>
      <td className="col">
        <img src={locationIcon} alt="" />
        <span>{addressTo}</span>
      </td>
      <td className="col">
        <span>{orderId}</span>
      </td>
      <td className="col">
        <span>{receiverPhone}</span>
      </td>
      <td className="col">
        <div className="flex-center">
          <img src={getCountryFlagIcon(countryCode)} alt="country flag icon" />
          <span>{countryCode}</span>
        </div>
      </td>
      <td className="col">
        <span>{parcelSku}</span>
      </td>
      <td className="col">
        <span>{parcelPrice}</span>
      </td>
    </tr>
  );
}
