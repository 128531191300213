// import React, { useEffect } from 'react';

interface IFormItem {
  label?: string | null;
  helperTxt?: string | null;
  children?: any;
  className?: string;
}

export default function FormItem({
  label = null,
  helperTxt = null,
  children,
  className = "",
}: IFormItem) {
  const labelElement = (
    <label>
      {label} {helperTxt ? <small>{helperTxt}</small> : ""}
    </label>
  );

  return (
    <div
      className={
        "formItem " +
        (typeof label === "string" ? " " : "formItemAlert") +
        ` ${className}`
      }
    >
      {typeof label === "string" ? labelElement : ""}
      {children}
    </div>
  );
}
