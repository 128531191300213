import ShipmentHeader from "../components/ShipmentHeader";
import UserDataForm from "../components/UserDataForm";

export default function UserData() {
  return (
    <div id="user-data">
      <ShipmentHeader />
      <UserDataForm />
      <div className="shipmentBackground"></div>
    </div>
  );
}
